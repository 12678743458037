// Dashboard.js
import React, { useMemo } from "react";
import { ConfigProvider, Layout, Menu, Tabs, theme } from "antd";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import {
  faAddressBook,
  faLandmarkDome,
  faMobileScreen,
  faNewspaper,
  faUserPlus,
  faCalendarDays,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import live from "../images/Screenshot from 2023-08-30 15-15-21.png";
import { IoIosPeople } from "react-icons/io";
import { GiJourney } from "react-icons/gi";
import { AiTwotoneMessage } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaUserLock, FaRegHandPointUp } from "react-icons/fa";
import { CgMediaLive } from "react-icons/cg";
import { TbReport } from "react-icons/tb";
import { MdList } from "react-icons/md";
import { Navbar } from "./Navbar";
import { NotificationOutlined } from "@ant-design/icons";
import axios from "axios";
// import "./dashboard.css";

const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    key: "",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faLandmarkDome} className="dash-icon" />
        Our Ideals
      </span>
    ),
  },
  {
    key: "event",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faCalendarDays} className="dash-icon" />
        Events
      </span>
    ),
  },
  {
    key: "highlights",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faMobileScreen} className="dash-icon" />
        BSP Highlights
      </span>
    ),
  },
  {
    key: "news",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faNewspaper} className="dash-icon" />
        News
      </span>
    ),
  },
  {
    key: "joinmemeber",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faUserPlus} className="dash-icon" />
        User Management
      </span>
    ),
  },
  {
    key: "userlist",
    label: (
      <span className="dash-span">
        <MdList className="dash-icon" />
        User Listing
      </span>
    ),
  },
  {
    key: "pressnote",
    label: (
      <span className="dash-span">
        <CgMediaLive className="dash-icon" />
        Press Note
      </span>
    ),
  },
  {
    key: "voterdata",
    label: (
      <span className="dash-span">
        <IoIosPeople className="dash-icon" />
        Voter Data
      </span>
    ),
  },
  {
    key: "ourjounery",
    label: (
      <span className="dash-span">
        <GiJourney className="dash-icon" />
        Our Journey
      </span>
    ),
  },
  {
    key: "workersmessage",
    label: (
      <span className="dash-span">
        <AiTwotoneMessage className="dash-icon" />
        Feedback
      </span>
    ),
  },
  {
    key: "broadcastmessage",
    label: (
      <span className="dash-span">
        <NotificationOutlined className="dash-icon" />
        BroadCast Message
      </span>
    ),
  },
  {
    key: "authorization",
    label: (
      <span className="dash-span">
        <FaUserLock className="dash-icon" />
        Authorization
      </span>
    ),
  },
  {
    key: "gallery",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faImage} className="dash-icon" />
        Gallery
      </span>
    ),
  },
  {
    key: "contact_us",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faAddressBook} className="dash-icon" />
        Contact Us Data
      </span>
    ),
  },
  {
    key: "",
    label: (
      <span className="dash-span">
        <TbReport className="dash-icon" />
        Report
      </span>
    ),
    children: [
      {
        key: "report",
        label: (
          <span className="dash-span">
            <MdList className="dash-icon" />
            Booth
          </span>
        ),
      },
      {
        key: "Locksabha Report",
        label: (
          <span className="dash-span">
            <MdList className="dash-icon" />
            Locksabha 
          </span>
        ),
      },
    ],
  },
  {
    key: "volunteerdata",
    label: (
      <span className="dash-span">
        <FaRegHandPointUp className="dash-icon" />
        Volunteer Data
      </span>
    ),
  },
  {
    key: "twitter",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faXTwitter} className="dash-icon" />
        Twitter
      </span>
    ),
  },
  {
    key: "facebook",
    label: (
      <span className="dash-span">
        <FontAwesomeIcon icon={faFacebook} className="dash-icon" />
        Facebook
      </span>
    ),
  },
  {
    key: "live",
    label: (
      <span className="dash-span">
        <img src={live} alt="live" />
        BSP Live
      </span>
    ),
  },
];

function useSelectedKey() {
  const { pathname } = useLocation();
  return useMemo(() => {
    const path = pathname.split("/")[2];
    return path || "ideals";
  }, [pathname]);
}

const Dashboard = () => {
  const navigate = useNavigate();
  const selectedKey = useSelectedKey();

  const handleUrl = (key) => {
    navigate(`/${key}`);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    axios
      .post("/bsp/authentication/logout")
      .then((resp) => {
        // console.log(resp);
        if (resp?.data?.success) {
          Cookies.remove("sessions");
          localStorage.removeItem("user");
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const isLoggedIn = Cookies.get("sessions") ? true : false;

  if (!isLoggedIn) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1d3e9b",
          },
        }}
      >
        <Layout style={{ maxHeight: "100vh", overflow: "hidden" }}>
          <Header style={{ padding: 0 }}>
            <Navbar handleClick={handleLogout} />
          </Header>
          <Layout>
            <Sider
              className=" hidden lg:block"
              width={240}
              style={{
                background: "#1d3e9b",
                overflowY: "auto",

                maxHeight: "calc(100vh - 64px)",
              }}
            >
              <Menu
                mode="inline"
                theme="#1d3e9b"
                defaultSelectedKeys={["/"]}
                onClick={(e) => handleUrl(e.key)}
                style={{
                  height: "100%",
                  borderRight: 0,
                }}
                items={items}
              />
            </Sider>
            <Layout
              style={{
                padding: " 24px ",
                overflowY: "auto",
                maxBlockSize: "calc(100vh - 64px)",
              }}
            >
              <Content
                style={{
                  margin: 0,
                  minHeight: "calc(100vh - 64px)",
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </ConfigProvider>
      <Toaster />
    </div>
  );
};

export default Dashboard;
