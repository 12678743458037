import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TbUpload } from "react-icons/tb";
import moment from "moment";


const PushAddNotification = ({ stateData, getAllNotificationFunc,selectedData,closeForm }) => {
  const [form] = Form.useForm();
  const [districtListing, setDistrictListing] = useState();
  const [uploadedMultiImage, setUploadMultiImage] = useState(null);
  const [startDate,setStartDate]=useState();
  const [endDate,setEndDate]=useState();
  const [frequency,setFrequency]=useState();
  const [dropDownData,setDropDownData]=useState();


  console.log(selectedData);

  // const onclose=()=>{

  // }

//   Multipart:
// 	"title": "Noti1",
// 	"message": "This is first notification",
// 	"state": "Uttar Pradesh",
// 	"district": "Gautam Budh Nagar",
// "start_date": "2023-12-21 18:30:43-05:00",
// "end_date": "2023-12-21 18:25:43-05:00",
// "frequency":10
// "image":

  const onFinish = (values) => {
    console.log(values,"testtttttttttttttttttt");
    console.log(startDate);
    console.log(endDate);

    let formData = new FormData();
    formData.append("title", values["title"]);
    formData.append("message",values["message"]);
    {values?.state?.value !== undefined &&  formData.append("state",values?.state?.value)};
    {values?.district?.value !== undefined && formData.append("district",values?.district?.value)};
    {uploadedMultiImage !== null && formData.append("image",uploadedMultiImage)};
    formData.append("start_date",moment(values["start_date"].$d).format("YYYY-MM-DD HH:mm:ss.00"));
    formData.append("end_date",moment(values["end_date"].$d).format("YYYY-MM-DD HH:mm:ss.00"));
    formData.append("frequency",values?.frequency["label"]);
    {values?.profession !== undefined && formData.append("profession",values?.profession["label"])}; 
    axios
      .post(
        "/bsp/admin/create-notification",
        formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        if (response?.data?.success) {
          // console.log(values);
          //   getLeaders();
          getAllNotificationFunc();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          // onclose();
          closeForm();
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getDistrictFunc = (data) => {
    axios
      .get(`/bsp/state_districts?state=${data}`)
      .then((response) => {
        console.log(response);
        setDistrictListing(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(uploadedMultiImage);

  // useEffect(()=>{
  //     getDistrictFunc();
  // },[selectedState])

  // const getAllNotificationFunc=()=>{
  //     axios.get("/get-notification/all")
  //     .then((response)=>{
  //         console.log(response?.data);
  //         setListingData(response?.data?.data?.data)
  //     })
  //     .catch((err)=>{
  //         console.log(err);
  //     });
  //     };

  // /create-notification

  // const addNotificationFunc=()=>{

  // }


  // const customRequest = async ({ file, onSuccess, onError }) => {
  //   setTimeout(() => {
  //     onSuccess(200);
  //   }, 1000);
  // };

  const onChange = (info) => {
    if (info?.file?.status === "done") {
      // console.log(info.file);
      message.success(`${info?.file?.name} file uploaded successfully`);
      // setImage(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    // Implement your custom upload logic here
    // You can use Axios, Fetch, or any other library to send the file to the server

    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
    console.log(file);
    setUploadMultiImage(file);
  };
  

  // const onChange = (info) => {
  //   if (info.file.status === "done") {
  //     message.success(`${info.file.name} file uploaded successfully`);
  //   } else if (info.file.status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  //   console.log(info.fileList);
  // };

  // const uploadImage = useRef(null);
  // const handleUpload = (e) => {
  //   console.log("1111");
  //   uploadImage.current.click();
  // };

  // const handleImageUploadChange = async (e) => {
  //   console.log("2222");
  //   const maxAllowedSize = 2 * 1024 * 1024;

  //   if (e.target.files[0].size < maxAllowedSize) {
  //     const uploadedImage = e.target?.files[0];
  //     console.log("3333");
  //     console.log(uploadedImage);
  //     setUploadMultiImage(uploadedImage);


  //     if (uploadedImage?.type.includes("image")) {
      
  //     }
  //   } else {
  //     // errorNotify("File size is more than 1 mb !");
  //   }
  // };


  const getAllDropdowns=()=>{
    axios.get("/bsp/drop-downs")
    .then((response)=>{
        console.log(response?.data);
        // setListingData(response?.data?.data?.data)
        response?.data?.data?.map((item,index)=>{
          if(item?.field === "PROFESSIONS"){
            setDropDownData(item?.value);
          }
        })
        // setDropDownData(response?.data?.data);
    })
    .catch((err)=>{
        console.log(err);
    });
    };

    useEffect(()=>{
      getAllDropdowns();
    },[])

    console.log(districtListing);


  console.log(uploadedMultiImage);

  return (
    <>
      <div className="leaderform">
      {/* <input
        type="file"
        style={{ display: "none" }}
        ref={uploadImage}
        onChange={(e) => handleImageUploadChange(e)}
        accept="image/png, image/jpeg, image/jpg"
      /> */}
        <Form
          name="leaderform"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row justify={"space-between"}>
            <Form.Item
              name={"state"}
              label={<p className="label-text">State</p>}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select state",
              //   },
              // ]}
              style={{ width: "48%" }}
              // initialValue={editData?.position}
              initialValue={selectedData?.state}
            >
              <Select
                labelInValue
                placeholder="Select State"
                onChange={(e) => getDistrictFunc(e.label)}
              >
                {stateData?.data?.map((value, index) => {
                  return (
                    <>
                      <Select.Option value={value}>{value}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>


            <Form.Item
              name={"district"}
              label={<p className="label-text">District</p>}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select district",
              //   },
              // ]}
              style={{ width: "48%" }}
              // initialValue={editData?.name}
              initialValue={selectedData?.district}
            >
              <Select
                labelInValue
                placeholder="Select District"
                // onChange={(e) => setEventType(e.label)}
              >
                {districtListing?.data?.map((value, index) => {
                  return (
                    <>
                      <Select.Option value={value}>{value}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </Row>

          <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"start_date"}
                    label={<p className="label-text">Start Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the start date",
                      },
                    ]}
                    // initialValue={event?.}
                  >

                    <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the Start Date"
                      onChange={(date, dateString) =>
                        setStartDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                      // defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
                      // initialValues={moment('2015-01-01', 'YYYY-MM-DD')}
                      // defaultValue={
                      //   event?.start_date ? dayjs(`${event?.start_date}`) : undefined
                      // }
                      // defaultValue={
                      //   editData?.year ? dayjs(`${editData?.year}`) : undefined
                      // }
                    />
                  </Form.Item>


                  
                </Col>
                
                <Col flex={9}>
                  <Form.Item
                    name={"end_date"}
                    label={<p className="label-text">End Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end date",
                      },
                    ]}
                  >
                   <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Date"
                      onChange={(date, dateString) =>
                        setEndDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                    />
                  </Form.Item>
                </Col>
                
              </Row>

          {/* <Row gutter={[32]}>
          <Col flex={9}>
                  <Form.Item
                    name={"start_time"}
                    label={<p className="label-text">Start Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Time for the Event"
                      // onChange={handleEndTimeChange}
                    />
                  </Form.Item>
                </Col>

                <Col flex={9}>
                  <Form.Item
                    name={"end_time"}
                    label={<p className="label-text">End Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Time for the Event"
                      // onChange={handleEndTimeChange}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"frequency"}
                    label={<p className="label-text">Frequency</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please Select the Frequency",
                      },
                    ]}
                    // initialValue={event?.}
                  >

                  <Select
                    labelInValue
                    placeholder="Frequency"
                    onChange={(e) => setFrequency(e.label)}
                  >
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                    <Select.Option value="6">6</Select.Option>
                    <Select.Option value="7">7</Select.Option>
                    <Select.Option value="8">8</Select.Option>
                    <Select.Option value="9">9</Select.Option>
                    <Select.Option value="10">10</Select.Option>
                  </Select>
                  </Form.Item>
                </Col>
                <Col flex={8}>
                  <Form.Item
                    name={"profession"}
                    label={<p className="label-text">Profession</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select the Profession",
                    //   },
                    // ]}
                    // initialValue={event?.}
                  >

                  <Select
                    labelInValue
                    placeholder="Profession"
                    onChange={(e) => setFrequency(e.label)}
                  >
                    {dropDownData?.map((item,index)=>{
                      return (
                        <>
                    <Select.Option value={item}>{item}</Select.Option>
                        </>
                      )
                    })}
                  </Select>
                  </Form.Item>
                </Col>

             
                
              </Row>

          <Form.Item
            name={"title"}
            label={<p className="label-text">Title</p>}
            rules={[
              {
                required: true,
                message: "Please enter Title",
              },
            ]}
            //   initialValue={editData?.quote}
            initialValue={selectedData?.title}
          >
            <TextArea
              style={{
                height: "75px",
                resize: "none",
                fontFamily: "Poppins, sans-serif",
                borderRadius: "14px",
              }}
              placeholder="Enter the title"
            />
          </Form.Item>

          <Form.Item
            name={"message"}
            label={<p className="label-text">Message</p>}
            rules={[
              {
                required: true,
                message: "Please enter message",
              },
              // {
              //   min: 1000,
              //   message: "Must be at least 1000 characters",
              // },
            ]}
            //   initialValue={editData?.description}
            initialValue={selectedData?.message}
          >
            <TextArea
              style={{
                height: "180px",
                resize: "none",
                fontFamily: "Poppins, sans-serif",
                borderRadius: "14px",
              }}
              placeholder="Enter Message"
            />
          </Form.Item>

          {/* <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Notification Image</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e?.fileList}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        const file = value && value[0];

                        if (file && !acceptedFormats.includes(file.type)) {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, GIF)."
                          );
                        }

                        return Promise.resolve();
                      },
                      message: "Please upload a valid image file",
                    },
                  ]}
                >
                   <Button
                        color="info"
                        size="sm"
                        onClick={handleUpload}
                        className="mr-0"
                      >
                        <TbUpload></TbUpload>
                        Upload Image
                      </Button>
                </Form.Item> */}

<Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 30px",
                  }}
                >

                <Form.Item
                name="file"
                label={<p className="label-text">Upload Cover Image</p>}
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                
                rules={[
                  {
                    required: false,
                    message: "Please upload Image",
                  },
                ]}
                style={{ textAlign: "center" }}
              >
                <Upload
                  name="file"
                  customRequest={customRequest}
                  onChange={onChange}
                  maxCount={1}
                  // multiple
                  listType="picture-card"
                  showUploadList={{
                    showDownloadIcon: false,
                  }}
                  accept="image/*" // Specify accepted file types
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ background: "transparent" }}
                    />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              </Row>
        
              <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    right: 0,
                    left: 0,
                    marginTop: "24px",
                  }}
                >
          <Form.Item>
            <Button htmlType="submit" size="large">
              Submit
            </Button>
          </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default PushAddNotification;
