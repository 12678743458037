import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Pagination,
  Popconfirm,
  Radio,
  Spin,
  Table,
  Tabs,
  message,
  Drawer,
  Form,
  Row,
  Col,
  Input,
  ConfigProvider,
  DatePicker,
  Select,
} from "antd";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import { TbUpload } from "react-icons/tb";
import moment from "moment";
import { Toaster, toast } from "react-hot-toast";
import { render } from "@testing-library/react";

const columns = [
  {
    title: "Image",
    key: "news_headline_title_img",
    dataIndex: "news_headline_title_img",

    render: (text, record) => (
      <a
      style={{ linkStyle: "none" }}
      href={record.news_headline_desc_link}
      target="_blank"
    >
      <img
        src={record.news_headline_title_img}
        alt="User"
        style={{ width: "100px", height: "100px", objectFit: "cover" }}
      />
      </a>
    ),
  },
  {
    title: "HeadLine",
    key: "news_headline_title",
    dataIndex: "news_headline_title",
    render: (text, record) => (
      
      <>
  <a
          style={{ linkStyle: "none", color:"black" }}
          href={record.news_headline_desc_link}
          target="_blank"
        >
      <p style={{ fontSize: "17px", fontWeight: "500" }}>
        {record.news_headline_title}
      </p>
      </a>
      <div>{record?.news_headline_channel_name}</div>
      <div>{record?.news_headline_published_date}</div>

      </>
    ),
  },
];

const App = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [form] = Form.useForm();
  const [editNews, setEditNews] = useState(null);
  const [uploadedMultiImage, setUploadMultiImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [NewsLoading, setNewsLoading] = useState(false);
  const [channelNameFilter,setChannelNameFilter]=useState();
  const [startDateFilter,setStartDateFilter]=useState();
  const [endDateFilter,setEndDateFilter]=useState();
  const [channelName,setChannelName]=useState();

  // news table column row
  const columnsNews = [
    {
      title: "",
      key: "news_headline_title_img",
      dataIndex: "news_headline_title_img",

      render: (text, record) => (
        <a
          style={{ linkStyle: "none" }}
          href={record.news_headline_desc_link}
          target="_blank"
        >
          <img
            src={record.news_headline_title_img}
            alt="User"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      title: "",
      key: "news_headline_title",
      dataIndex: "news_headline_title",
      render: (text, record) => (
        <>
          <div
            style={{
              marginBottom: "0.5rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="color-primary">
              {" "}
              {record.news_headline_channel_name}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ alignSelf: "center" }} className=" news-para">
                {record.news_headline_published_date}
              </div>
              <div
                style={{ alignSelf: "center", marginLeft: "0.6rem" }}
                className="news-para "
              >
                {/* <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                  onConfirm={() => handleDeleteConfrim(record)}
                > */}
                <Button
                  shape="circle"
                  // danger
                  onClick={() => openDrawer(record)}
                  icon={<FontAwesomeIcon icon={faPenToSquare} />}
                ></Button>
                {/* </Popconfirm> */}
              </div>
              <div
                style={{ alignSelf: "center", marginLeft: "0.6rem" }}
                className="news-para "
              >
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                  onConfirm={() => handleDeleteConfrim(record)}
                >
                  <Button
                    shape="circle"
                    danger
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  ></Button>
                </Popconfirm>
              </div>
            </div>
          </div>
          <a
            style={{ linkStyle: "none" }}
            href={record.news_headline_desc_link}
            target="_blank"
          >
            <h3 className="small-heading"> {record.news_headline_title}</h3>
            <p className="news-para">{record.news_headline_small_text}</p>
          </a>
        </>
      ),
    },
  ];

  const handleDeleteConfrim = async (item) => {
    const { data } = await axios.delete(`/bsp/admin/delete-news/${item?._id}`);
    if (data.success) {
      message.success(data.message);
      fetchAllNews(10, page);
    } else {
      message.error(data.message);
    }
  };

  const [selectedItemNews, setSelectedItemNews] = useState();

  const onSelectChange = (newSelectedRowKeys, label) => {
    console.log("selectedRowKeys changed: ", label, newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedItemNews(label);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

console.log(newsData);

  const openDrawer = (record) => {
    console.log(record);
    setEditNews(record);
    form.setFieldValue("channel_name", record?.news_headline_channel_name);
    form.setFieldValue("title", record?.news_headline_title);
    form.setFieldValue("description", record?.news_headline_small_text);
    form.setFieldValue(
      "published_date",
      moment(editNews?.published_date).format("DD/MM/YYYY")
    );
    setIsFormOpen(true);
  };

  // export const shippingAddressApi = async (addressId)=> {
  //   const data = await axios.post("/api/cart/address/believe", {
  //     address_id: addressId,
  //     new_address:false
  //   });
  //   return data;
  // }

  // initialValue={moment(editNews?.published_date).format(
  //   "DD/MM/YYYY"
  // )}
  // /bsp/web_scrap/news_headlines'

  console.log(startDateFilter);
  console.log(endDateFilter);
  console.log(channelNameFilter);
  const fetchNewsHeading = async () => {
    setLoading(true);
    const { data } = await axios.post("/bsp/web_scrap/news_headlines", {
      news_channel_name: channelNameFilter,
      starting_date: startDateFilter,
      ending_date: endDateFilter,
    });

    if (data.success) {
      const updatedData = data?.data?.map((item, index) => {
        return {
          ...item,
          key: index, // Adding the 'isNew' key with a default value
        };
      });

      // Update the state with the modified data
      setNewsData(updatedData);
      //   setNewsData(data.data);

      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  };

  const channelNameFunc = () => {
    axios
      .get("/bsp/web_scrap/get_news_channels")
      .then((response) => {
        setChannelName(response?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

console.log(channelName);

  const handleCreateNews = async () => {
    // console.log(selectedRowKeys, selectedItem, "chekcing");

    const { data } = await axios.post(
      "/bsp/admin/create-news",
      selectedItemNews
    );
    if (data.success) {
      setSelectedRowKeys([]);
      fetchAllNews();
      fetchNewsHeading();
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const fetchAllNews = async (pageNumber, pageSize = 10) => {
    // console.log(selectedRowKeys, selectedItem, "chekcing");
    setNewsLoading(true);
    const { data } = await axios.get("/bsp/admin/get-news/all", {
      params: {
        page_size: pageSize,
        page_num: pageNumber,
      },
    });
    if (data.success) {
      console.log(data, "cheking data");
      setNewsLoading(false);

      //   message.success(data.message);
      setAllNews(data?.data?.data);
      setTotalPage(data.data.page_count * 10);
    } else {
      message.error(data.message);
    }
  };

  const onChangePagination = (pageNumber, pageSize) => {
    fetchAllNews(pageNumber, pageSize);
    setPage(pageNumber);
  };

  useEffect(() => {
    channelNameFunc();
    // fetchNewsHeading();
    fetchAllNews(1);
  }, [0]);

  const closeForm = () => {
    setIsFormOpen(false);
  };

  console.log(editNews);

  const parsedDate = moment(editNews?.published_date, "YYYY-MM-DD HH:mm:ss");

  // Format the date in the desired format
  const formattedDateString = parsedDate.format("dddd MMMM DD, YYYY");

  // Multipart:
  // _id: 6595533ed4169ff749db3a98
  // news_headline_desc_link: https://www.ndtv.com/india-news/who-is-pratap-simha-bjp-mp-who-issued-passes-to-men-who-breached-parliament-4666143
  // news_headline_title: Parliament Intruders Got Passes From BJP MP Prathap Simha's Office
  // news_headline_small_text: After the major security breach in the Lok Sabha, BSP MP Danish Ali told NDTV that at least one of the passes recovered after the attack had been issued by BJP MP Prathap Simha's office.
  // news_headline_channel_name:NDTV
  // news_headline_published_date: Tuesday January 01, 2024
  // news_headline_title_img: file

  const onFinish = (values) => {
    console.log(values);
    console.log(uploadedMultiImage);
    let formData = new FormData();
    // formData.append("event_type", values["event_type"].label);
    formData.append("_id", editNews?._id);
    formData.append(
      "news_headline_desc_link",
      editNews?.news_headline_desc_link
    );
    formData.append("news_headline_title", values["title"]);
    formData.append("news_headline_small_text", values["description"]);
    formData.append(
      "news_headline_channel_name",
      editNews?.news_headline_channel_name
    );
    formData.append("news_headline_published_date", formattedDateString);
    {
      uploadedMultiImage !== null &&
        formData.append("news_headline_title_img", uploadedMultiImage);
    }

    axios
      .put("/bsp/admin/update-news", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response?.data?.success) {
          // getEvents();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          onClose();
          fetchAllNews();
          setImagePreview(null);
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClose = () => {
    setIsFormOpen(false);
    form.resetFields();
    setEditNews(null);
  };

  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    uploadImage.current.click();
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       // Set the image preview URL to the reader result
  //       setImagePreview(reader.result);
  //     };

  //     // Read the file as a data URL
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUploadChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image preview URL to the reader result
        setImagePreview(reader.result);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }

    console.log(e);
    console.log("jhfvbrv");
    const maxAllowedSize = 2 * 1024 * 1024;

    if (e.target.files[0].size < maxAllowedSize) {
      const uploadedImage = e.target?.files[0];
      // const qual = 0.8;
      console.log(uploadedImage);
      setUploadMultiImage(uploadedImage);

      // let formData = new FormData();
      // formData.append("upload_file", uploadedImage);
      // formData.append("loan_id", loanId);
      // formData.append("type", type);
      // formData.append("position", values["position"]);
      // formData.append("name", values["name"]);
      // formData.append("quote",values["quote"]);
      // formData.append("description",values["description"]);

      if (uploadedImage?.type.includes("image")) {
        // setUploadingLoader(true);
        // const { data } = await updateLoanAttachment(formData);
        // // setUploadingLoader(false);
        // if (data?.sucess) {
        //   // successNotify(data.message);
        // } else {
        //   // errorNotify(data.message);
        // }
      }
    } else {
      // errorNotify("File size is more than 1 mb !");
    }
  };

  console.log(editNews);

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div className="gallery news-tab" style={{ width: "95%", margin: "auto" }}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={uploadImage}
        onChange={(e) => handleImageUploadChange(e)}
        accept="image/png, image/jpeg, image/jpg"
      />

      <Tabs
        className=""
        style={{
          height: "inherit",
          marginTop: "-10px",
        }}
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Scrapped NEWS",
            children: (
              <div>
                <h2>Scrapped News</h2>

                <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">Channel Name</label>

                  <Select
                    placeholder="Please Select Channel Name"
                    className="select-box-width"
                    onChange={(e)=>setChannelNameFilter(e)}
                    allowClear
                    // value={selectedState}
                  >
                    {channelName?.data?.map((name) => (
                      <Select.Option key={name._id} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">Start Date</label>
                  <DatePicker
                  style={{ width: "100%", borderRadius: "10px" }}
                  placeholder="select start Date"
                  onChange={(date, dateString) =>
                    setStartDateFilter(moment(dateString).format("YYYY-MM-DD"))
                  }
               
                />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">End Date</label>

                  <DatePicker
                  style={{ width: "100%", borderRadius: "10px" }}
                  placeholder="Select End Date"
                  onChange={(date, dateString) =>
                    setEndDateFilter(moment(dateString).format("YYYY-MM-DD"))
                  }
                
                />
                </Col>
              </Row>

              <div className="filter-button-div">
                <Button className="filter-button-data" onClick={fetchNewsHeading}>
                  Filter
                </Button>
              </div>

              

                <Divider />
                {selectedRowKeys.length > 0 && (
                  <div className="flex">
                    <Button
                      onClick={handleCreateNews}
                      className="primary"
                      style={{ marginBottom: "1rem" }}
                    >
                      Approve
                    </Button>
                  </div>
                )}

                

                <Table
                  loading={loading}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={newsData}
                />
              </div>
            ),
          },
          {
            key: "2",
            label: "Approved News",
            children: (
              <div>
                <h2>Approved News</h2>
                <Divider />

                <Table
                  loading={NewsLoading}
                  columns={columnsNews}
                  dataSource={allNews}
                  pagination={false}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Pagination
                    current={page}
                    total={totalPage}
                    showSizeChanger={false}
                    onChange={(pageNumber, pageSize) =>
                      onChangePagination(pageNumber, pageSize)
                    }
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
      <Drawer
        title={<p className="icon-p">Update News</p>}
        width={550}
        onClose={onClose}
        open={isFormOpen}
        bodyStyle={{
          background: "#ffff",
        }}
        destroyOnClose
      >
        {/* <div>kdhnemdn</div> */}

        {/* <div className="form"> */}
        <div className="form-container">
          <Form
            className="formSubmit"
            name="submit"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={[32]}>
              <Col flex={8}>
                <Form.Item
                  name={"channel_name"}
                  label={<p className="label-text">Channel Name</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the Channel Name",
                    },
                  ]}
                  // initialValue={editNews?.news_headline_channel_name}
                >
                  <Input
                    placeholder="Name of the Event"
                    // onChange={(e) => setName(e.target.value)}
                    style={{ borderRadius: "10px" }}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col flex={9}>
                <Form.Item
                  name={"published_date"}
                  label={<p className="label-text">Published Date</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the Published Date",
                    },
                  ]}
                  // initialValue={editNews?.published_date}
                  // initialValue={moment(editNews?.published_date).format(
                  //   "DD/MM/YYYY"
                  // )}
                >
                  <Input
                    placeholder="Name of the Event"
                    // onChange={(e) => setName(e.target.value)}
                    style={{ borderRadius: "10px" }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[32]}>
              <Col flex={8}>
                <Form.Item
                  name={"title"}
                  label={<p className="label-text">Title</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Title",
                    },
                  ]}
                  // initialValue={editNews?.news_headline_title}
                >
                  <TextArea
                    style={{
                      height: "75px",
                      resize: "none",
                      fontFamily: "Poppins, sans-serif",
                      borderRadius: "14px",
                    }}
                    placeholder="News Title"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name={"description"}
                label={<p className="label-text">Small Text</p>}
                // rules={[
                //   {
                //     required: true,
                //     message: "Briefly Describe the Journey",
                //   },
                // ]}
                style={{ width: "100%" }}
                // initialValue={editNews?.news_headline_small_text}
              >
                <TextArea
                  placeholder="Describe the Small Text"
                  showCount
                  maxLength={50}
                  style={{
                    height: "15rem",
                    resize: "none",
                    fontFamily: "Poppins, sans-serif",
                    borderRadius: "14px",
                  }}
                />
              </Form.Item>
            </Row>

            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <p>Old Image</p>
              <p>New Image</p>
            </div>
            <div style={{ display: "flex", "justify-content": "center" }}>
              {/* <p>Old Image</p> */}
              <img
                src={editNews?.news_headline_title_img}
                style={{ marginRight: "10px", height: "180px", width: "240px" }}
              />
              {/* <p>Old Image</p> */}
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ height: "180px", width: "240px" }}
                />
              ) : (
                <p>No New Image Selcted</p>
              )}
            </div>

            {/* {imagePreview && (
        <div>
          <p>Image Preview:</p>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%' }} />
        </div>
      )} */}

            <Button
              color="info"
              size="sm"
              onClick={handleUpload}
              className="mr-0"
              style={{ "margin-top": "13px", "margin-bottom": "16px" }}
            >
              {" "}
              Upload New Image
              <TbUpload></TbUpload>
            </Button>

            <ConfigProvider
              theme={{
                token: {
                  borderRadius: "12px",
                },
              }}
            >
              <Form.Item>
                <Button size="large" htmlType="submit">
                  Update News
                </Button>
              </Form.Item>
            </ConfigProvider>
          </Form>
        </div>
        {/* </div> */}
      </Drawer>
      <Toaster />
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
    </div>
  );
};
export default App;
