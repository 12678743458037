import { PlusOutlined } from "@ant-design/icons";

import {
  Button,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Card,
  Select,
  Image,
  Tooltip,
  Typography,
  Upload,
  Col,
  Modal,
  DatePicker,
  Pagination,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";

import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import dayjs from "dayjs";

const PressNote = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [uploadedImage, setUploadedImage] = useState();
  const [data, setData] = useState("ACHIEVEMENT");
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [items, setItems] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteDataIndex, setDeleteDataIndex] = useState();
  const [fileType, setFileType] = useState();
  const [updatedData, setUpdatedData] = useState(null);
  const [totalPage, setTotalPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);

  const showDrawer = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const onClose = () => {
    setSelectedEvent(null);
    setIsEdit(false);
    setOpen(false);
    form.resetFields();
  };

  const getAllPressData = () => {
    axios
      .get(
        `/bsp/admin/get-documentation/all?type=${data}&page_num=${pageNum}&page_size=${pageSize}`
      )
      .then((response) => {
        if (response?.data?.success) {
          console.log(response?.data?.data);
          setItems(response?.data?.data?.data);
          setTotalPage(response?.data?.data?.page_count * 10);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPressData();
  }, [data, pageNum]);

  console.log(items);

  const handleUpdate = (values) => {
    // console.log("datetdt",);
    let formData = new FormData();
    values?.file.forEach((data) => {
      formData.append("file", data?.originFileObj);
    });
    formData.append("type", values["file_name"]);
    formData.append("description", values["description"]);
    formData.append("title", values["title"]);
    formData.append("file_type", values["file_type"]);
    formData.append(
      "release_date",
      dayjs(values?.release_date).format("YYYY-MM-DD")
    );

    let url;
    let method;

    if (isEdit) {
      url = `/bsp/admin/update-documentation`;
      method = "PUT"; // Use PUT method for updating
      formData.append("_id", updatedData?._id);
    } else {
      url = `/bsp/admin/create-documentation`;
      method = "POST"; // Use POST method for creating
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
      },
    })
      .then((resp) => {
        if (resp.data.success) {
          toast.success(resp.data.message, {
            style: {
              border: "2px solid #005bea",
              padding: "8px 16px",
              color: "#005bea",
              fontWeight: "500",
            },
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          onClose();
          getAllPressData();
        } else {
          toast.error(resp.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      // setUploadedImageFile(info.file.originFileObj);
      // setUploadedImageUrl(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
  };

  const showUpdateModal = (item) => {
    setUpdatedData(item);
    form.setFieldValue("file_type", item?.file_type);
    form.setFieldValue("title", item?.title);
    form.setFieldValue("description", item?.description);
    setOpen(true);
    console.log(item);
  };

  const showDeleteModal = (item) => {
    console.log("delete button");
    console.log(item);
    setDeleteDataIndex(item);
    setDeleteModalVisible(true);
  };

  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber);
    setPageNum(pageNumber);
  };

  const handleDelete = () => {
    axios
      .delete(
        `/bsp/admin/delete-documentation/${deleteDataIndex?._id}?type=${data}`
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.success) {
          toast.success(resp.data.message, {
            style: {
              border: "2px solid #005bea",
              padding: "8px 16px",
              color: "#005bea",
              fontWeight: "500",
            },
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          // onClose();
          // getAllPressData();
          getAllPressData();
          setDeleteModalVisible(false);
        } else {
          toast.error(resp.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="add-post">
        <div className="head">
          <Typography.Text className="text">Press Notes</Typography.Text>
          <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
            <Tooltip title="New Post">
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={<PlusOutlined />}
                onClick={() => showDrawer(null)}
                type="primary"
                shape="circle"
              />
            </Tooltip>
          </ConfigProvider>
        </div>

        <Select
          labelInValue
          placeholder="Select data type here"
          onChange={(e) => setData(e.value)}
          style={{ width: "40%" }}
          defaultValue={"ACHIEVEMENT"}
        >
          <Select.Option value="ACHIEVEMENT">ACHIEVEMENT</Select.Option>
          <Select.Option value="MANIFESTO">MANIFESTO</Select.Option>
          <Select.Option value="PRESSNOTE">PRESSNOTE</Select.Option>
        </Select>

        <div className="add">
          <Drawer
            width={550}
            title={
              <p className="icon-p">
                {!isEdit ? "Add Document" : "Update Document"}
              </p>
            }
            open={open}
            onClose={onClose}
          >
            <Form
              className="update-modal-form"
              form={form}
              onFinish={handleUpdate}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name={"file_type"}
                label={<p className="label-text">File Type</p>}
                rules={[
                  {
                    required: true,
                    message: "Please select a file type",
                  },
                ]}
                // initialValue={updatedData?.content_type}
              >
                <Select
                  placeholder="Select type of your file"
                  onChange={(e) => setFileType(e)}
                >
                  <Select.Option value="document">PDF</Select.Option>
                  {/* <Select.Option value="VIDEO">Video</Select.Option> */}
                  <Select.Option value="Image">IMAGE</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="file"
                label={<p className="label-text">File</p>}
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Please upload a valid file",
                  },
                ]}
                style={{ textAlign: "center" }}
              >
                <Upload
                  name="file"
                  customRequest={customRequest}
                  onChange={onChange}
                  multiple
                  maxCount={fileType == "document" ? 1 : 10}
                  listType="picture-card"
                  showUploadList={{
                    showDownloadIcon: false,
                  }}
                  // accept=".pdf, image/*"
                  accept={fileType == "document" ? ".pdf" : "image/*"}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ background: "transparent" }}
                    />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>

              <Form.Item
                name={"file_name"}
                label={<p className="label-text">File Name</p>}
                rules={[
                  {
                    required: true,
                    message: "Please select file name",
                  },
                ]}
                // initialValue={updatedData?.content_type}
              >
                <Select placeholder="Select Name of your file">
                  <Select.Option value="ACHIEVEMENT">ACHIEVEMENT</Select.Option>
                  {/* <Select.Option value="VIDEO">Video</Select.Option> */}
                  <Select.Option value="MANIFESTO">MANIFESTO</Select.Option>
                  <Select.Option value="PRESSNOTE">PRESSNOTE</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="title"
                label={<p className="label-text">Title</p>}
                rules={[
                  {
                    required: true,
                    message: "Please enter Title",
                  },
                ]}
                // initialValue={updatedData?.title}
              >
                <Input placeholder="Enter title of your file" />
              </Form.Item>

              <Form.Item
                name="release_date"
                label={<p className="label-text">Release Date</p>}
                rules={[
                  {
                    required: true,
                    message: "Please enter Release Date",
                  },
                ]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                name="description"
                label={<p className="label-text">Description</p>}
                rules={[
                  {
                    required: true,
                    message: "Please enter the Description",
                  },
                ]}
                // initialValue={updatedData?.description}
              >
                <TextArea
                  placeholder="Enter the description of your file"
                  maxLength={200}
                  style={{
                    resize: "none",
                    fontFamily: "Poppins, sans-serif",
                    borderRadius: "14px",
                  }}
                  rows={3}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                {/* <Button onClick={handleCancel}>Cancel</Button> */}
                <Button style={{ marginLeft: "20px" }} htmlType="submit">
                  Add Notes
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>

        <Row gutter={[16, 16]}>
          {items?.map((item, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              {item.file_type === "DOCUMENT" ? (
                <>
                  <Card
                    style={{
                      textAlign: "center",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      border: "2px solid #eee",
                    }}
                    cover={
                      <object
                        data={item?.file_url[0]}
                        style={{
                          width: "100%",
                          height: 300,
                          objectFit: "contain",
                        }}
                      ></object>
                    }
                    hoverable
                    actions={[
                      <Tooltip title="Update">
                        <Button
                          size="large"
                          shape="circle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          icon={<FontAwesomeIcon icon={faPen} />}
                          onClick={() => {
                            setIsEdit(true);
                            showUpdateModal(item);
                          }}
                        />
                      </Tooltip>,
                      <Tooltip title="Delete">
                        <Button
                          size="large"
                          shape="circle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          onClick={() => showDeleteModal(item)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <Card.Meta
                      title={
                        <p style={{ fontWeight: "500", color: "#1d3e9b" }}>
                          {item.title}
                        </p>
                      }
                      description={
                        <div>
                          <p>
                            <strong>{item.description}</strong>
                          </p>
                          <p style={{ color: "#aaa" }}></p>
                        </div>
                      }
                    />
                  </Card>
                </>
              ) : (
                <>
                  <Card
                    style={{
                      textAlign: "center",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      border: "2px solid #eee",
                    }}
                    cover={
                      <Image
                        style={{
                          width: "100%",
                          height: 300,
                          objectFit: "contain",
                        }}
                        alt={`Item ${index}`}
                        src={item?.file_url[0]}
                      />
                    }
                    hoverable
                    actions={[
                      <Tooltip title="Update">
                        <Button
                          size="large"
                          shape="circle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          icon={<FontAwesomeIcon icon={faPen} />}
                          onClick={() => {
                            setIsEdit(true);
                            showUpdateModal(item);
                          }}
                        />
                      </Tooltip>,
                      <Tooltip title="Delete">
                        <Button
                          size="large"
                          shape="circle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          onClick={() => showDeleteModal(item)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <Card.Meta
                      title={
                        <p style={{ fontWeight: "500", color: "#1d3e9b" }}>
                          {item.title}
                        </p>
                      }
                      description={
                        <div>
                          <p>
                            <strong>{item.description} </strong>
                          </p>
                          <p style={{ color: "#aaa" }}></p>
                        </div>
                      }
                    />
                  </Card>
                </>
              )}
            </Col>
          ))}
        </Row>
        {items?.length !== 0 && (
          <div className="pressNotepagination">
            <Pagination
              className="pagination-core"
              showQuickJumper
              defaultCurrent={1}
              current={pageNum}
              total={totalPage}
              // showSizeChanger={true}
              // hideOnSinglePage={true}
              onChange={(pageNumber, pageSize) =>
                onChangePagination(pageNumber, pageSize)
              }
            />
          </div>
        )}
      </div>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                Are you sure you want to delete this file?
              </span>
            </p>
          }
          okButtonProps={{
            type: "default",
          }}
          open={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={handleDelete}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
    </>
  );
};
export default PressNote;
