import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Row,
  Upload,
  message,
  Select,
  ConfigProvider,
  Tooltip,
  Drawer,
  Modal,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import reverseGeocode from "../reverseGeocode";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationCrosshairs,
  faLocationDot,
  faPen,
  faTrash,
  faTriangleExclamation,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { PlusOutlined } from "@ant-design/icons";
import { TbUpload } from "react-icons/tb";

const AddImages = () => {
  const [form] = Form.useForm();
  const [geolocation, setGeolocation] = useState(null);
  const [city, setCity] = useState(null);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [items, setItems] = useState();
  // const [updateIndex, setUpdateIndex] = useState(null);
  const [uploadedMultiImage, setUploadMultiImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [imageSize, setImageSize] = useState();

  const showDrawer = (event) => {
    console.log(event);
    setSelectedEvent(event);
    form.setFieldValue("description", event?.post_data);
    setOpen(true);
  };

  const onClose = () => {
    setSelectedEvent(null);
    setOpen(false);
  };

  const getUsers = () => {
    axios
      .get("/bsp/user/admin", {
        params: {
          authorization: `["ADMIN", "SUPER_ADMIN"]`,
        },
      })
      .then((response) => {
        setUsers(response?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPostItems = () => {
    axios
      .get("/bsp/admin/get-post/all")
      .then((response) => {
        if (response?.data?.success) {
          console.log(response?.data?.data);
          setItems(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUsers();
    getPostItems();
  }, []);

  console.log(selectedEvent);

  const onFinish = (values) => {
    console.log("valuesvalues", values);
    console.log(uploadedMultiImage);
    let formData = new FormData();
    formData.append("post_data", values["description"]);
    // formData.append("location", city);
    formData.append("time_created", moment().format("DDMMYYYYHHmmss"));
    formData.append("_id", selectedEvent?._id);
    // formData.append("image", uploadedMultiImage);
    {
      uploadedMultiImage !== null &&
        formData.append("image", uploadedMultiImage);
    }

    console.log(values);
    if (selectedEvent) {
      if (geolocation) {
        values.location = city;
      }
      console.log("updatedataaaaa");
      axios
        .put("bsp/admin/update-post", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        })
        .then((resp) => {
          // console.log(resp.data);
          if (resp.data.success) {
            toast.success(resp.data.message, {
              style: {
                border: "2px solid #005bea",
                padding: "8px 16px",
                color: "#005bea",
                fontWeight: "500",
              },
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            setCity("");
            onClose();
            getPostItems();
          } else {
            toast.error(resp.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let formData = new FormData();
      formData.append("post_data", values["description"]);
      // formData.append("location", city);
      // formData.append("user_tags", values["people"]);
      formData.append("time_created", moment().format("DDMMYYYYHHmmss"));
      formData.append("image", uploadedMultiImage);

      if (geolocation) {
        values.location = city;
      }
      console.log("createdataaaaaa");
      axios
        .post("/bsp/admin/create-post", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            toast.success(resp.data.message, {
              style: {
                border: "2px solid #005bea",
                padding: "8px 16px",
                color: "#005bea",
                fontWeight: "500",
              },
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            getPostItems();
            onClose();
            setCity("");
          } else {
            toast.error(resp.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const showDeleteModal = (index) => {
    setDeleteIndex(index._id);
    setDeleteModalVisible(true);
  };

  const handleDelete = () => {
    axios
      .delete(`/bsp/admin/delete-post/${deleteIndex}`)
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.success) {
          setDeleteModalVisible(false);
          getPostItems();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setGeolocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          const cityResult = await reverseGeocode(
            position.coords.latitude,
            position.coords.longitude
          );
          if (cityResult) {
            setCity(cityResult);
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation not supported");
    }
  };

  console.log(selectedEvent);

  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    console.log("1111");
    uploadImage.current.click();
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    // Implement your custom upload logic here
    // You can use Axios, Fetch, or any other library to send the file to the server

    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
    console.log(file);
    setUploadMultiImage(file);
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    console.log(info.fileList);
  };

  console.log(uploadedMultiImage);

  useEffect(() => {
    axios
      .get("/bsp/image_config?type=highlight")
      .then((response) => {
        if (response?.data) {
          // setLeadersDropdown(response?.data);
          setImageSize(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="add-post">
      <div className="head">
        <Typography.Text className="text">BSP Highlights</Typography.Text>
        <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
          <Tooltip title="New">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
              onClick={() => showDrawer(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </ConfigProvider>
      </div>
      <div className="add">
        <Drawer
          width={550}
          title={
            selectedEvent ? (
              <p className="icon-p">Update Highlights</p>
            ) : (
              <p className="icon-p">Create Highlights</p>
            )
          }
          open={open}
          onClose={onClose}
        >
          <div className="formContainer">
            {selectedEvent ? (
              <Form
                form={form}
                onFinish={onFinish}
                className="add-form"
                layout="vertical"
                autoComplete="off"
              >
                <Row style={{ marginTop: "40px" }}>
                  <Form.Item
                    name={"description"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <TextArea
                      style={{
                        height: "200px",
                        resize: "none",
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        borderRadius: "14px",
                        padding: "10px 15px",
                      }}
                      placeholder="What's on Your Mind??"
                      maxLength={150}
                    />
                  </Form.Item>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 30px",
                  }}
                >
                  <Form.Item
                    name="file"
                    label={<p className="label-text">Upload Cover Image</p>}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                    rules={[
                      {
                        required:false,
                        message: "Please upload Image",
                      },
                    ]}
                    style={{ textAlign: "center" }}
                  >
                    <Upload
                      name="file"
                      customRequest={customRequest}
                      onChange={onChange}
                      maxCount={1}
                      // multiple
                      listType="picture-card"
                      showUploadList={{
                        showDownloadIcon: false,
                      }}
                      accept="image/*" // Specify accepted file types
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faUpload}
                          style={{ background: "transparent" }}
                        />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Upload
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    right: 0,
                    left: 0,
                    marginTop: "24px",
                  }}
                >
                  <Form.Item>
                    <ConfigProvider
                      theme={{
                        token: {
                          borderRadius: 12,
                        },
                      }}
                    >
                      <Button size="large" htmlType="submit">
                        Update Post
                      </Button>
                    </ConfigProvider>
                  </Form.Item>
                </Row>
              </Form>
            ) : (
              <Form
                form={form}
                onFinish={onFinish}
                className="add-form"
                layout="vertical"
                autoComplete="off"
              >
                <Row style={{ marginTop: "40px" }}>
                  <Form.Item
                    name={"description"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <TextArea
                      style={{
                        height: "200px",
                        resize: "none",
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        borderRadius: "14px",
                        padding: "10px 15px",
                      }}
                      placeholder="What's on Your Mind??"
                      maxLength={150}
                    />
                  </Form.Item>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 30px",
                  }}
                >
                  <Form.Item
                    name="file"
                    label={<p className="label-text">Upload Cover Image</p>}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                    rules={[
                      {
                        required: selectedEvent === null ? true : false,
                        message: "Please upload Image",
                      },
                    ]}
                    style={{ textAlign: "center" }}
                  >
                    <Upload
                      name="file"
                      customRequest={customRequest}
                      onChange={onChange}
                      maxCount={1}
                      // multiple
                      listType="picture-card"
                      showUploadList={{
                        showDownloadIcon: false,
                      }}
                      accept="image/*" // Specify accepted file types
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faUpload}
                          style={{ background: "transparent" }}
                        />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Upload
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    right: 0,
                    left: 0,
                    marginTop: "24px",
                  }}
                >
                  <Form.Item>
                    <ConfigProvider
                      theme={{
                        token: {
                          borderRadius: 12,
                        },
                      }}
                    >
                      <Button size="large" htmlType="submit">
                        Create Highlights
                      </Button>
                    </ConfigProvider>
                  </Form.Item>
                </Row>
              </Form>
            )}
            <div style={{ color: "#1d3f9bbb", "font-weight": "bolder" }}>
              Note-Upload Image height and width should be {imageSize?.width} ×{" "}
              {imageSize?.height} respectively
            </div>
          </div>
        </Drawer>
      </div>
      <div style={{ width: "100%" }} className="post-display">
        <div className="post-details">
          <table className="custom-table">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Image</th>
                <th>Post Description</th>
                {/* <th>Location</th> */}
                <th>Date Created</th>
                {/* <th style={{ width: "30%" }}>User Tags</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items?.data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td>
                      <img style={{ width: "15rem" }} src={item?.image_url} />
                    </td>
                    <td>{item?.post_data}</td>
                    <td>{moment(item?.created_at).format("DD-MM-YYYY")}</td>

                    <td>
                      <div className="actions">
                        <Tooltip title="Update">
                          <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faPen} />}
                            onClick={() => showDrawer(item)}
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={() => showDeleteModal(item)}
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                Are you sure you want to delete this file?
              </span>
            </p>
          }
          okButtonProps={{
            type: "default",
          }}
          open={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={handleDelete}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
    </div>
  );
};

export default AddImages;
