import React, { useEffect, useRef, useState } from "react";
// import logo from "../images/djt-logo.png";
// import ss from "../images/Screenshot from 2023-08-30 15-15-21.png";
import {
  Row,
  Col,
  Card,
  Image,
  Form,
  Input,
  Button,
  Modal,
  Typography,
  Upload,
  message,
  Tooltip,
  Select,
  ConfigProvider,
  Tabs,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import toast from "react-hot-toast";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faTriangleExclamation,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { TbUpload } from "react-icons/tb";

const GetImagesTab = ({
  images,
  items,
  showDeleteModal,
  showUpdateModal,
  totalPage,
  setPage,
  page,
}) => {
  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber, pageSize);
    setPage(pageNumber);
  };

  console.log(images);
  return (
    <>
      <Row gutter={[16, 16]}>
        {images?.map((item, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              style={{
                textAlign: "center",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                border: "2px solid #eee",
              }}
              cover={
                <Image
                  style={{
                    width: "80%",
                    height: 300,
                    objectFit: "contain",
                  }}
                  alt={`Item ${index}`}
                  src={item.file_url}
                  // src="https://upload.wikimedia.org/wikipedia/commons/d/d2/Elephant_Bahujan_Samaj_Party.svg"
                />
              }
              hoverable
              actions={[
                <Tooltip title="Update">
                  <Button
                    size="large"
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    icon={<FontAwesomeIcon icon={faPen} />}
                    onClick={() => showUpdateModal(item)}
                  />
                </Tooltip>,
                <Tooltip title="Delete">
                  <Button
                    size="large"
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => showDeleteModal(item)}
                  />
                </Tooltip>,
              ]}
            >
              <Card.Meta
                title={
                  <p style={{ fontWeight: "500", color: "#1d3e9b" }}>
                    {item.title}
                  </p>
                }
                description={
                  <div>
                    <p>
                      <strong>{item.description}</strong>
                    </p>
                    <p style={{ color: "#aaa" }}>
                      {Math.abs(
                        moment(item.created_at).diff(moment(), "hours")
                      ) > 24
                        ? moment(item.created_at).format("ll")
                        : moment(item.created_at).fromNow()}
                    </p>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <div className="pagination-style">
        <Pagination
          // className="pagination-core"
          current={page}
          showQuickJumper
          defaultCurrent={1}
          total={totalPage}
          // total={totalPage}
          showSizeChanger={false}
          onChange={(pageNumber, pageSize) =>
            onChangePagination(pageNumber, pageSize)
          }
        />
      </div>
    </>
  );
};

const GetVideosTab = ({ items, showDeleteModal, showUpdateModal }) => {
  return (
    <Row gutter={[16, 16]}>
      {items?.map((item, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={6}>
          <Card
            style={{
              textAlign: "center",
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
              border: "2px solid #eee",
            }}
            cover={
              <Image
                style={{
                  width: "80%",
                  height: 300,
                  objectFit: "contain",
                }}
                alt={`Item ${index}`}
                // src={item.src}
                preview={{
                  imageRender: () => (
                    <video
                      muted
                      width="100%"
                      controls
                      src="https://mdn.alipayobjects.com/huamei_iwk9zp/afts/file/A*uYT7SZwhJnUAAAAAAAAAAAAADgCCAQ"
                    />
                  ),
                }}
                src="https://i.pinimg.com/736x/ee/cb/56/eecb569b2803e4792acb2bec01f78dde.jpg"
              />
            }
            hoverable
            actions={[
              <Tooltip title="Update">
                <Button
                  size="large"
                  shape="circle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={<FontAwesomeIcon icon={faPen} />}
                  onClick={() => showUpdateModal(item)}
                />
              </Tooltip>,
              <Tooltip title="Delete">
                <Button
                  size="large"
                  shape="circle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => showDeleteModal(item)}
                />
              </Tooltip>,
            ]}
          >
            <Card.Meta
              title={
                <p style={{ fontWeight: "500", color: "#1d3e9b" }}>
                  {item.title}
                </p>
              }
              description={
                <div>
                  <p>
                    <strong>{item.description}</strong>
                  </p>
                  <p style={{ color: "#aaa" }}>
                    {Math.abs(moment(item.created_at).diff(moment(), "hours")) >
                    24
                      ? moment(item.created_at).format("ll")
                      : moment(item.created_at).fromNow()}
                  </p>
                </div>
              }
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const GetDocumentsTab = ({ items, showDeleteModal, showUpdateModal }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        {items?.map((item, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              style={{
                textAlign: "center",
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
                border: "2px solid #eee",
                overflow: "hidden",
              }}
              cover={
                <iframe
                  src="src/images/Project%20Timeline.pdf"
                  width="100%"
                  height="300px"
                  title="PDF Viewer"
                  style={{ border: "none", outline: "none", cursor: "pointer" }}
                ></iframe>
              }
              hoverable
              actions={[
                <Tooltip title="Update">
                  <Button
                    size="large"
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    icon={<FontAwesomeIcon icon={faPen} />}
                    onClick={() => showUpdateModal(item)}
                  />
                </Tooltip>,
                <Tooltip title="Delete">
                  <Button
                    size="large"
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => showDeleteModal(item)}
                  />
                </Tooltip>,
              ]}
            >
              <Card.Meta
                title={
                  <p style={{ fontWeight: "500", color: "#1d3e9b" }}>
                    {item.title}
                  </p>
                }
                description={
                  <div>
                    <p>
                      <strong>{item.description}</strong>
                    </p>
                    <p style={{ color: "#aaa" }}>
                      {Math.abs(
                        moment(item.created_at).diff(moment(), "hours")
                      ) > 24
                        ? moment(item.created_at).format("ll")
                        : moment(item.created_at).fromNow()}
                    </p>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

const Gallery = () => {
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  // const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [form] = Form.useForm();
  const [items, setItems] = useState([]);
  const [videoItems, setVideoItems] = useState([]);
  const [newImages, setNewImages] = useState();
  const [documents, setDocuments] = useState([]);
  const [uploadMultiImage, setUploadMultiImage] = useState(null);
  const [updatedData, setUpdatedData] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  // http://devv.bahujansamajparty.org/bsp/admin/get-gallery/all?content_type=Image&page_size=1&page_num=1' \

  const getImages = () => {
    axios
      .get(
        `bsp/admin/get-gallery/all?content_type=Image&page_size=12&page_num=${page}`
      )
      .then((response) => {
        // console.log(response.data.data["IMAGE"]);
        console.log(response?.data?.data?.data);
        setNewImages(response?.data?.data?.data);
        setItems(response?.data?.data["IMAGE"]);
        setTotalPage(response?.data?.data?.page_count * 10);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(page);

  useEffect(() => {
    getImages();
  }, [page]);

  // const getVideos = () => {
  //   axios
  //     .get("/bsp/admin/get-gallery/all")
  //     .then((response) => {
  //       // console.log(response.data.data["VIDEO"]);
  //       setVideoItems(response?.data?.data["VIDEO"]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   getVideos();
  // }, []);

  // const getDocuments = () => {
  //   axios
  //     .get("/bsp/admin/get-gallery/all")
  //     .then((response) => {
  //       // console.log(response.data.data["VIDEO"]);
  //       setDocuments(response?.data?.data["DOCUMENT"]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   getDocuments();
  // }, []);
  // content_type
  const showUpdateModal = (index) => {
    console.log(index);
    setUpdatedData(index);
    setUpdateIndex(index._id);
    form.setFieldValue("content_type", index?.content_type);
    form.setFieldValue("title", index?.title);
    form.setFieldValue("description", index?.description);
    // form.setFieldValue('description', event?.post_data)

    // console.log(updateIndex);
    setIsUpdateMode(true);
    setUpdateModalVisible(true);
  };

  const showAddItemModal = () => {
    setIsUpdateMode(false);
    setAddItemModalVisible(true);
  };

  console.log(uploadMultiImage);

  const handleUpdate = (values) => {
    console.log("hfjrhbfmrbrn");
    let formData = new FormData();
    formData.append("_id", updateIndex);
    formData.append("title", values["title"]);
    formData.append("description", values["description"]);
    formData.append("content_type", values["content_type"]);
    {
      uploadMultiImage !== null && formData.append("file", uploadMultiImage);
    }
    console.log(values);
    axios
      .put("/bsp/admin/update-gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          Accept: "application/pdf",
          type: "formData",
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response?.data?.success) {
          setUpdateModalVisible(false);
          getImages();
          // getVideos();
          // getDocuments();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showDeleteModal = (index) => {
    setDeleteIndex(index._id);
    setDeleteModalVisible(true);
  };

  const handleDelete = () => {
    axios
      .delete(`/bsp/admin/delete-gallery/${deleteIndex}`)
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.success) {
          setDeleteModalVisible(false);
          getImages();
          // getVideos();
          // getDocuments();
          toast.success("Media deleted", {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddItem = (values) => {
    console.log(values);
    let formData = new FormData();
    formData.append("title", values["title"]);
    formData.append("description", values["description"]);
    formData.append("content_type", values["content_type"]);
    formData.append("file", uploadMultiImage);
    axios
      .post("/bsp/admin/create-gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          Accept: "application/pdf",
          type: "formData",
        },
      })
      .then((response) => {
        if (response?.data?.success) {
          console.log(response?.data?.data);
          getImages();
          // getVideos();
          // getDocuments();
          setAddItemModalVisible(false);
          toast.success("Media added", {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    setUpdateModalVisible(false);
    setAddItemModalVisible(false);
    form.resetFields();
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    // Implement your custom upload logic here
    // You can use Axios, Fetch, or any other library to send the file to the server

    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
    console.log(file);
    setUploadMultiImage(file);
    // multiImageUploaded.push(file);
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      // setUploadedImageFile(info.file.originFileObj);
      // setUploadedImageUrl(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    console.log(info.fileList);
  };

  console.log(uploadMultiImage);

  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    console.log("mnfftg");
    uploadImage.current.click();
  };

  const handleImageUploadChange = async (e) => {
    console.log("jhfvbrv");
    const maxAllowedSize = 2 * 1024 * 1024;

    if (e.target.files[0].size < maxAllowedSize) {
      const uploadedImage = e.target?.files[0];
      // const qual = 0.8;
      console.log(uploadedImage);
      setUploadMultiImage(uploadedImage);

      if (uploadedImage?.type.includes("image")) {
      }
    } else {
      // errorNotify("File size is more than 1 mb !");
    }
  };

  console.log(uploadMultiImage);

  console.log(page);

  return (
    <div className="gallery pl-5">
      <div className="head">
        <Typography.Text className="text">BSP Gallery</Typography.Text>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1d3e9b",
            },
          }}
        >
          <Tooltip title="Add Item">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              shape="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={showAddItemModal}
            />
          </Tooltip>
        </ConfigProvider>
      </div>
      <Tabs
        style={{
          marginTop: "-40px",
        }}
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Images",
            children: (
              <GetImagesTab
                images={newImages}
                items={items}
                showDeleteModal={showDeleteModal}
                showUpdateModal={showUpdateModal}
                // onChangePagination={onChangePagination}
                totalPage={totalPage}
                setPage={setPage}
                page={page}
              />
            ),
          },
          // {
          //   key: "2",
          //   label: "Videos",
          //   children: (
          //     <GetVideosTab
          //       items={videoItems}
          //       showDeleteModal={showDeleteModal}
          //       showUpdateModal={showUpdateModal}
          //     />
          //   ),
          // },
          // {
          //   key: "3",
          //   label: "Documents",
          //   children: (
          //     <GetDocumentsTab
          //       items={documents}
          //       showDeleteModal={showDeleteModal}
          //       showUpdateModal={showUpdateModal}
          //     />
          //   ),
          // },
        ]}
      />
      {isUpdateMode ? (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1d3e9b",
              borderRadius: 10,
            },
          }}
        >
          <Modal
            title={<p className="bottom-line icon-p">Update Item</p>}
            open={updateModalVisible}
            onCancel={handleCancel}
            footer={null}
            className={"update-modal"}
          >
            <Form
              className="update-modal-form"
              form={form}
              onFinish={handleUpdate}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="file"
                label={<p className="label-text">File</p>}
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please upload a valid file",
                //   },
                // ]}
                style={{ textAlign: "center" }}
              >
                <Upload
                  name="file"
                  customRequest={customRequest}
                  onChange={onChange}
                  maxCount={1}
                  listType="picture-card"
                  showUploadList={{
                    showDownloadIcon: false,
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ background: "transparent" }}
                    />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                name={"content_type"}
                label={<p className="label-text">File Type</p>}
                rules={[
                  {
                    required: true,
                    message: "Please select a file type",
                  },
                ]}
                // initialValue={updatedData?.content_type}
              >
                <Select placeholder="Update the type of your file">
                  <Select.Option value="IMAGE">Image</Select.Option>
                  <Select.Option value="VIDEO">Video</Select.Option>
                  <Select.Option value="DOCUMENT">Document</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="title"
                label={<p className="label-text">Title</p>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                // initialValue={updatedData?.title}
              >
                <Input placeholder="Update the title of your file" />
              </Form.Item>
              <Form.Item
                name="description"
                label={<p className="label-text">Description</p>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                // initialValue={updatedData?.description}
              >
                <TextArea
                  placeholder="Change the description of your file"
                  maxLength={100}
                  style={{
                    resize: "none",
                    fontFamily: "Poppins, sans-serif",
                    borderRadius: "14px",
                  }}
                  rows={3}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button style={{ marginLeft: "20px" }} htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </ConfigProvider>
      ) : (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1d3e9b",
              borderRadius: 10,
            },
          }}
        >
          <Modal
            title={<p className="bottom-line icon-p">Add Item</p>}
            open={addItemModalVisible}
            onCancel={handleCancel}
            footer={null}
            className={"add-item-modal"}
          >
            <input
              type="file"
              style={{ display: "none" }}
              ref={uploadImage}
              onChange={(e) => handleImageUploadChange(e)}
              accept="image/png, image/jpeg, image/jpg"
            />

            <Form
              form={form}
              onFinish={handleAddItem}
              autoComplete="off"
              layout="vertical"
              className="add-modal-form"
            >
              {/* <Form.Item
                name="file"
                label={<p className="label-text">File</p>}
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Please upload a valid file",
                  },
                ]}
                style={{ textAlign: "center" }}
              >
            

            <Button
              color="info"
              size="sm"
              onClick={handleUpload}
              className="mr-0"
            >
              {" "}
              <TbUpload></TbUpload>
            </Button>

              </Form.Item> */}

              <Form.Item
                name="file"
                label={<p className="label-text">File</p>}
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please upload a valid file",
                //   },
                // ]}
                style={{ textAlign: "center" }}
              >
                <Upload
                  name="file"
                  customRequest={customRequest}
                  onChange={onChange}
                  maxCount={1}
                  listType="picture-card"
                  showUploadList={{
                    showDownloadIcon: false,
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ background: "transparent" }}
                    />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>

              <Form.Item
                name={"content_type"}
                label={<p className="label-text">File Type</p>}
                rules={[
                  {
                    required: true,
                    message: "Please select a file type",
                  },
                ]}
              >
                <Select placeholder="Type of your file">
                  <Select.Option value="IMAGE">Image</Select.Option>
                  <Select.Option value="VIDEO">Video</Select.Option>
                  <Select.Option value="DOCUMENT">Document</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="title"
                label={<p className="label-text">Title</p>}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Add a title to your file" />
              </Form.Item>
              <Form.Item
                name="description"
                label={<p className="label-text">Description</p>}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea
                  placeholder="Add a brief description to your file"
                  maxLength={100}
                  style={{
                    resize: "none",
                    fontFamily: "Poppins, sans-serif",
                    borderRadius: "14px",
                  }}
                  rows={3}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button style={{ marginLeft: "20px" }} htmlType="submit">
                  Add
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </ConfigProvider>
      )}
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                Are you sure you want to delete this file?
              </span>
            </p>
          }
          okButtonProps={{
            type: "default",
          }}
          open={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={handleDelete}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
    </div>
  );
};

export default Gallery;
