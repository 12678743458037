import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  TimePicker,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TbUpload } from "react-icons/tb";


const JourneyAdd = ({ onclose, getAllJourneyFunc, editData }) => {
  const [form] = Form.useForm();
  const [yearData, setYearData] = useState();
  const [uploadedMultiImage,setUploadMultiImage]=useState();


  // let formData = new FormData();
  // formData.append("upload_photo", file);
  // formData.append("loan_id", modalData?.loan_id);


  const onFinish = (values) => {
    console.log(values);
   
  

    if(editData == null){
      let formData = new FormData();
      formData.append("title", values["title"]);
      formData.append("description", values["description"]);
      formData.append("year", values?.year?.$y);
      formData.append("image",uploadedMultiImage);
      axios
      .post(
        "/bsp/admin/create-journey",
        formData,  // Send FormData directly as the request body
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        if (response?.data?.success) {
          // console.log(values);
          //   getLeaders();
          getAllJourneyFunc();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          onclose();
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((err) => console.log(err));
    } else {
      let formData = new FormData();
      formData.append("title", values["title"]);
      formData.append("description", values["description"]);
      formData.append("year", values?.year?.$y);
      formData.append("_id",editData?._id);
      formData.append("image",uploadedMultiImage);

      axios
      .put(
        "/bsp/admin/update-journey",
        formData,  // Send FormData directly as the request body
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        if (response?.data?.success) {
          // console.log(values);
          //   getLeaders();
          getAllJourneyFunc();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          form.resetFields();
          onclose();
        } else {
          toast.error(response?.data?.message, {
            className: "error",
          });
        }
      })
      .catch((err) => console.log(err));
    }
  
 

    // axios
    //   .post(
    //     "/create-leader",
    //     {
    //       position: values["position"],
    //       name: values["name"],
    //       quote: values["quote"],
    //       description: values["description"],
    //     },
    //     {
    //       header: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     // console.log(response.data);
    //     if (response.data.success) {
    //       // console.log(values);
    //       getLeaders();
    //       toast.success(response.data.message, {
    //         className: "toast",
    //         iconTheme: {
    //           primary: "#005bea",
    //           secondary: "#f0f7ff",
    //         },
    //       });
    //       form.resetFields();
    //       onclose();
    //     } else {
    //       toast.error(response.data.message, {
    //         className: "error",
    //       });
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  //   const yearData = {
  //     dateOfBirth: moment(editData?.year)
  //   };

  console.log(`${editData?.year}`);

  const customRequest = async ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess(200);
    }, 1000);
  };

  const onChange = (info) => {
    if (info?.file?.status === "done") {
      // console.log(info.file);
      message.success(`${info?.file?.name} file uploaded successfully`);
      // setImage(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
    }
  };

  console.log(editData);


  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    console.log("mnfftg");
    uploadImage.current.click();
  };

  const handleImageUploadChange = async (e) => {
    console.log("jhfvbrv");
    const maxAllowedSize = 2 * 1024 * 1024;

    if (e.target.files[0].size < maxAllowedSize) {
      const uploadedImage = e.target?.files[0];
      // const qual = 0.8;
      console.log(uploadedImage);
      setUploadMultiImage(uploadedImage);
     
          // let formData = new FormData();
          // formData.append("upload_file", uploadedImage);
          // formData.append("loan_id", loanId);
          // formData.append("type", type);
          // formData.append("position", values["position"]);
          // formData.append("name", values["name"]);
          // formData.append("quote",values["quote"]);
          // formData.append("description",values["description"]);

          if (uploadedImage?.type.includes("image")) {
            // setUploadingLoader(true);
            // const { data } = await updateLoanAttachment(formData);
            // // setUploadingLoader(false);
            // if (data?.sucess) {
            //   // successNotify(data.message);
            // } else {
            //   // errorNotify(data.message);
            // }
          }
       
    } else {
      // errorNotify("File size is more than 1 mb !");
    }
  };

  console.log(uploadedMultiImage);


  return (
    <>
      <div className="fill">

      <input
          type="file"
          style={{ display: "none" }}
          ref={uploadImage}
          onChange={(e) => handleImageUploadChange(e)}
          accept="image/png, image/jpeg, image/jpg"
        />
        <div className="form">
          <div className="form-container">
            <Form
              className="formSubmit"
              name="submit"
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"title"}
                    label={<p className="label-text">Title</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Title",
                      },
                    ]}
                    initialValue={editData?.title}
                  >
                    <Input
                      // disabled
                      placeholder="Title of Journey"
                      // onChange={(e) => setEventLoc(e.target.value)}
                      style={{ borderRadius: "10px" }}
                    />
                  </Form.Item>
                </Col>
                <Col flex={9}>
                  <Form.Item
                    name={"year"}
                    label={<p className="label-text">Year</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please Select the Year",
                      },
                    ]}
                    // initialValue={editData?.year}
                    // initialValue={"2002"}
                  >
                    <DatePicker
                      picker="year"
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Select the Year"
                      
                      defaultValue={
                        editData?.year ? dayjs(`${editData?.year}`) : undefined
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  name={"description"}
                  label={<p className="label-text">Description</p>}
                  rules={[
                    {
                      required: true,
                      message: "Briefly Describe the Journey",
                    },
                  ]}
                  style={{ width: "100%" }}
                  initialValue={editData?.description}
                >
                  <TextArea
                    placeholder="Describe the Journey Briefly"
                    style={{
                      height: "15rem",
                      resize: "none",
                      fontFamily: "Poppins, sans-serif",
                      borderRadius: "14px",
                    }}
                  />
                </Form.Item>
              </Row>

              <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Cover Image</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e?.fileList}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        const file = value && value[0];

                        if (file && !acceptedFormats.includes(file.type)) {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, GIF)."
                          );
                        }

                        return Promise.resolve();
                      },
                      message: "Please upload a valid image file",
                    },
                  ]}
                >
                  {/* <Upload
                    name="file"
                    customRequest={customRequest}
                    onChange={onChange}
                    maxCount={1}
                    listType="text"
                    showUploadList={{
                      showDownloadIcon: false,
                    }}
                    accept="image/jpeg,image/png,image/gif,image/ico,image/jpg"
                  >
                    <ConfigProvider
                      theme={{
                        token: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Button icon={<FontAwesomeIcon icon={faUpload} />}>
                        Select Image
                      </Button>
                    </ConfigProvider>
                  </Upload> */}

<Button
              color="info"
              size="sm"
              onClick={handleUpload}
              className="mr-0"
            >
              {" "}
              <TbUpload></TbUpload>
            </Button>
                </Form.Item>

              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: "12px",
                  },
                }}
              >
                <Form.Item>
                  <Button size="large" htmlType="submit">
                    {editData == null ? "Add Journey" : "Update Journey"}
                  </Button>
                </Form.Item>
              </ConfigProvider>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyAdd;
