import { Typography, Row, Col, Input, Select, DatePicker, Button } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { downloadBlob } from "./Downloader";

const VolunteerData = () => {
  const [stateData, setStateData] = useState();
  const [filterData, setFilterData] = useState({
    name: "",
    state: "",
    voter_id: "",
    mobile: "",
    start_date: "",
    end_date: "",
  });

  console.log(filterData);
  const getStatesFunc = () => {
    axios
      .get("/bsp/states")
      .then((response) => {
        setStateData(response?.data?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStatesFunc();
  }, []);

  // 'http://192.168.1.154:6069/volunteer_report?export=True&state=&mobile_no=&voter_id=&start_date=&end_date=' \
  const exportData = () => {
    axios
      .get("/volunteer_report", {
        params: {
          export: true,
          state: filterData?.state,
          mobile_no: filterData.mobile,
          voter_id: filterData?.voter_id,
          start_date: filterData?.start_date,
          end_date: filterData?.end_date,
        },
      })
      .then((response) => {
        setStateData(response?.data?.data);
        console.log(response?.data);
        downloadBlob(response?.data, "Volunteer Report");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="event pl-5">
        <div className="head">
          <Typography.Text className="text">Volunteer Report</Typography.Text>
        </div>
      </div>
      <div className="fill">
        <div className="form">
          <div className="form-container voter-Data">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Mobile</label>
                <Input
                  placeholder="Please Select Mobile"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      mobile: e.target.value,
                    })
                  }
                />
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">State</label>

                <Select
                  placeholder="Please Select State"
                  className="select-box-width"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      state: e,
                    })
                  }
                >
                  {stateData?.map((state) => (
                    <Select.Option key={state._id} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Voter ID</label>
                <Input
                  placeholder="Plesae Select Voter ID"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      voter_id: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Start Date</label>

                <DatePicker
                  className="select-box-width"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      start_date: e,
                    })
                  }
                  placeholder="Plase Select Start Date"
                />
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">End Date</label>
                <DatePicker
                  className="select-box-width"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      end_date: e,
                    })
                  }
                  placeholder="Please Select End Date"
                />
              </Col>
            </Row>
            <div className="filter-button-div">
              <Button className="filter-button-data" onClick={exportData}>
                Export
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VolunteerData;
