import React from "react";
import ReactFacebookLogin from "@greatsumini/react-facebook-login";
import Typography from "antd/es/typography/Typography";
export default function FacebookLogin(props) {
  const onResponse = (resp) => {
    console.log(resp);
  };
  return (
    <div className="facebook-c pl-5">
      <div className="head">
        <Typography.Text className="text">Recent Posts</Typography.Text>
      </div>
      <div className="facebook-container">
        <ReactFacebookLogin
          appId="1389128045263332"
          autoLoad={true}
          fields="name,email,picture"
          callback={onResponse}
          onFailure={onResponse}
          className="facebook"
        />
      </div>
    </div>
  );
}
