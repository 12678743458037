import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsDownload, BsPlug, BsPlus, BsThreeDots } from "react-icons/bs";
import {
  faList,
  faPen,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import TaskCard from "./custom/TaskCard";
import { DownOutlined } from "@ant-design/icons";
import ModalCreateTask from "./custom/ModalCreateTask";
import DialogDemo from "./custom/updateUser";

const GetAccessTable = ({ task = false }) => {
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showAutData, setShowAuthData] = useState(false);
  const [userId, setUserId] = useState([]);
  const [newAddAuth, setNewAddAuth] = useState();
  const [userOldAuth, setUserOldAuth] = useState(null);
  const [updateAccessData, setUpdateAccessData] = useState([]);
  const [data, setData] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [districtListing, setDistrictListing] = useState();
  const [vidhanSabha, setVidhanSabha] = useState();
  const [assCon, setAssCon] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [stateData, setStateData] = useState();
  const [districtData, setDistrictData] = useState();
  const [ageData, setAgeData] = useState();
  const [selectedProfession, setSelectedProfession] = useState();
  const [profession, setProfession] = useState();
  const [selctedTagMember, setSelectedTagMember] = useState();
  const [tagMember, setTagMember] = useState();
  const [editUserAuth, setEditUserAuth] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [taskview, setTaskView] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const { Option } = Select;

  // const getEvents = () => {
  //   axios
  //     .get(`/bsp/user/admin?page_num=${page}&page_size=${pageSize}&name=${name}&mobile=${mobile}&state=${selectedState}&district=${selectedDistrict}&pincode=${pincode}&assembly_constitution=${assCon}`)
  //     .then((response) => {
  //       setUserData(response?.data?.data);
  //       setTotalPage(response?.data?.data?.page_count * 10);
  //       // setEvents(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getStatesFunc = () => {
    axios
      .get("/bsp/states")
      .then((response) => {
        setStateData(response?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEvents = () => {
    axios
      .get("/bsp/user/admin", {
        params: {
          page_num: page,
          page_size: pageSize,
          name: name,
          mobile: mobile,
          state: selectedState,
          district: selectedDistrict,
          assembly_constitution: assCon,
        },
      })
      .then((response) => {
        setUserData(response?.data?.data);
        setTotalPage(response?.data?.data?.page_count * 10);
        // setEvents(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEvents();
    getStatesFunc();
  }, [page]);

  const getAccessEndpoints = (data) => {
    axios
      .get(`/bsp/user/access_endpoints?user_id=${data}`)
      .then((response) => {
        // console.log(response?.data);
        setNewAddAuth(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // .put(
  //   "/update-event",
  //   {
  //     _id: event._id,
  //     event_type: value["event_type"].label,
  //     location: value["location"],
  //     guest_list: value["guest_list"],
  //     co_host: value["co_host"],
  //     organizer_name: value["event_organizer"],
  //     event_name: value["event_name"],
  //     event_speakers: value["speakers"],
  //     agenda: value["agenda"],
  //     start_date: moment(value["start_date"].$d).format("YYYY-MM-DD"),
  //     start_time: moment(startTime.$d).format("HH:mm"),
  //     end_date: endDate,
  //     end_time: moment(endTime.$d).format("HH:mm"),
  //     description: value["event_description"],
  //   },
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }
  // )

  //   toast.success(resp.data.message, {
  //     style: {
  //       border: "2px solid #005bea",
  //       padding: "8px 16px",
  //       color: "#005bea",
  //       fontWeight: "500",
  //     },
  //     iconTheme: {
  //       primary: "#005bea",
  //       secondary: "#f0f7ff",
  //     },
  //   });
  //   form.resetFields();
  //   setCity("");
  //   onClose();
  //   getPostItems();
  // } else {
  //   toast.error(resp.data.message);
  // }

  const updateAccessEndpoints = (data) => {
    axios
      .put(
        "/bsp/user/access_endpoints",

        {
          user_ids: userId,
          access_list: editUserAuth,
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            style: {
              border: "2px solid #005bea",
              padding: "8px 16px",
              color: "#005bea",
              fontWeight: "500",
            },
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
          setIsFormOpen(false);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeUserFunc = (value, index) => {
    setUserOldAuth(value?.access);
    setUserId(value?._id);
    getAccessEndpoints(value?._id);
  };

  //   const newAuthorizationFunc=(data)=>{
  //     console.log(data);
  //     if(updateAccessData?.length == 0){
  //      updateAccessData.push(data?.target?.value);
  //     } else {
  //      updateAccessData?.forEach((element,index) => {
  //   if(data?.target?.value !== element){
  // updateAccessData.push(data?.target?.value);
  //   }
  //   if(data?.target?.value == element){
  //   }
  // });
  //     }

  //   }

  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber, pageSize);
    setPage(pageNumber);
  };

  useEffect(() => {
    axios
      .get(`/bsp/states`)
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });

    // /bsp/drop-downs
  }, []);

  const handleStateChange = (data) => {
    setSelectedState(data);
    axios
      .get(`/bsp/state_districts?state=${data}`)
      .then((response) => {
        console.log(response?.data?.data);
        setDistrictListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(selectedState);
  console.log(selectedDistrict);
  console.log(assCon);
  console.log(name);
  console.log(mobile);
  console.log(pincode);

  const handleDistrictChange = (district) => {
    setSelectedDistrict(district);
    // /bsp/district_constitutions?district=Mathura
    axios
      .get(
        `/bsp/district_constitutions?state=${selectedState}&district=${district}`
      )
      .then((response) => {
        console.log(response?.data?.data);
        setVidhanSabha(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setSelectedDistrict(district);
  };

  const closeForm = () => {
    // setSelectedEvent(null);
    setIsFormOpen(false);
  };

  const onEdit = (value) => {
    setUserOldAuth(value?.access);
    setEditUserAuth(value?.access);
    setUserId((prevIds) => [...prevIds, value?._id]);
    // setUserId([...userId,value?._id]);
    getAccessEndpoints(value?._id);
    console.log(value);
    setIsFormOpen(true);
  };

  console.log(userId);

  //   const removeOldAuth=(data)=>{
  // console.log(data?.target?.value);
  //   }

  //   const filterFunc=()=>{

  //   }

  const removeOldAuth = (event) => {
    const value = event.target.value;
    setEditUserAuth((prevAuth) => {
      if (prevAuth.includes(value)) {
        return prevAuth.filter((data) => data !== value);
      } else {
        return [...prevAuth, value];
      }
    });
  };

  const newAuthorizationFunc = (event) => {
    const value = event.target.value;
    setEditUserAuth((prevAuth) => {
      if (prevAuth.includes(value)) {
        return prevAuth.filter((data) => data !== value);
      } else {
        return [...prevAuth, value];
      }
    });
  };

  const showDeleteModal = (value) => {
    console.log(value?._id);
    // setDeleteIndex(index._id);
    setDeleteUser(value?._id);
    setDeleteModalVisible(true);
  };

  const handleDelete = () => {
    axios
      .delete(`/bsp/user/admin/${deleteUser}`)
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.success) {
          setDeleteModalVisible(false);
          getEvents();
          toast.success(response?.data?.message, {
            className: "toast",
            iconTheme: {
              primary: "#005bea",
              secondary: "#f0f7ff",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(editUserAuth);

  const updateAuth = () => {};

  const handleUrl = (key) => {
    navigate(`/userlist/${key}`);
  };

  return (
    <>
      <div className="fill">
        <div className="form">
          <div className="form-container voter-Data">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">State</label>
                <Select
                  placeholder="Please Select State"
                  className="select-box-width"
                  onChange={handleStateChange}
                >
                  {stateData?.data?.map((state) => (
                    <Select.Option key={state._id} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">District</label>
                <Select
                  placeholder="Please Select District"
                  className="select-box-width"
                  onChange={handleDistrictChange}
                >
                  {districtListing?.map((district, index) => (
                    <Select.Option key={index} value={district}>
                      {district}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">VidhanSabha</label>
                <Select
                  placeholder="Please Select Vidhansabha"
                  className="select-box-width"
                  onChange={(e) => setAssCon(e)}
                >
                  {vidhanSabha?.map((assembly, index) => (
                    <Select.Option key={index} value={assembly}>
                      {assembly}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Name</label>
                <Input
                  placeholder="Please Enter Age"
                  onChange={(e) => setName(e.target.value)}
                  // onChange={changeInteger}
                />
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Mobile</label>

                <Input
                  placeholder="Please Enter Mobile"
                  onChange={(e) => setMobile(e.target.value)}
                  // onChange={changeInteger}
                />
              </Col>
            </Row>
            <div className="filter-button-div">
              <Button className="filter-button-data" onClick={getEvents}>
                Filter
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="details">
        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>S.No.</th>
              <th style={{ width: "8%" }}>Actions</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Role</th>
              <th>State</th>
              <th>District</th>
              <th>Assembly Constitution</th>
              <th>Gender</th>
              <th>DOB</th>
              <th style={{ width: "8%" }}>User ID</th>
            </tr>
          </thead>

          <tbody>
            {userData?.data?.map((value, index) => {
              return (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <div className="actions">
                        <Tooltip title="Update">
                          {task ? (
                            <Button
                              shape="circle"
                              icon={<FontAwesomeIcon icon={faPen} />}
                              disabled={
                                value?.status
                                  ? value?.status !== "Applied"
                                  : true
                              }
                              onClick={() => {
                                setUser(value);
                                setOpen(true);
                              }}
                            />
                          ) : (
                            <Button
                              shape="circle"
                              icon={<FontAwesomeIcon icon={faPen} />}
                              onClick={() => onEdit(value)}
                            />
                          )}
                        </Tooltip>
                        {task ? (
                          <Tooltip title="Task">
                            <Button
                              shape="circle"
                              icon={<FontAwesomeIcon icon={faList} />}
                              // onClick={() => showDeleteModal(item)}
                              onClick={() => {
                                setTaskView(true);
                                handleUrl(value?.user_id);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Delete">
                            <Button
                              shape="circle"
                              icon={<FontAwesomeIcon icon={faTrash} />}
                              // onClick={() => showDeleteModal(item)}
                              onClick={() => showDeleteModal(value)}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </td>
                    <td>{value?.name}</td>
                    <td>{value?.mobile}</td>
                    <td>{value?.role}</td>
                    <td>{value?.state}</td>
                    <td>{value?.district}</td>
                    <td>{value?.assembly_constitution}</td>
                    <td>{value?.gender}</td>
                    <td>{value?.date_of_birth}</td>
                    <td>{value?.user_id}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>

        <div className="pagination-style">
          <Pagination
            className="pagination-core"
            current={page}
            showQuickJumper
            defaultCurrent={1}
            total={totalPage}
            // total={totalPage}
            showSizeChanger={false}
            onChange={(pageNumber, pageSize) =>
              onChangePagination(pageNumber, pageSize)
            }
          />
        </div>
      </div>

      <Drawer
        title={<p className="icon-p">Update Authorization</p>}
        width="50%"
        onClose={closeForm}
        open={isFormOpen}
        bodyStyle={{
          background: "#fff",
        }}
        destroyOnClose
      >
        <div>
          <Row>
            <Col span={8}>
              <Card title="Old Authorization" bordered={false}>
                {userOldAuth?.map((data, index) => {
                  return (
                    <Checkbox onChange={removeOldAuth} value={data}>
                      {data}
                    </Checkbox>
                  );
                })}
              </Card>
            </Col>
            <Col span={8}>
              <Card title="New Authorization" bordered={false}>
                {newAddAuth?.data?.map((data, index) => {
                  return (
                    // <div>
                    //   {value}
                    // </div>
                    <Checkbox onChange={newAuthorizationFunc} value={data}>
                      {data}
                    </Checkbox>
                  );
                })}
              </Card>
            </Col>
          </Row>

          <Button onClick={updateAccessEndpoints}>Update Authorization</Button>
        </div>
      </Drawer>

      {/* <div>.</div>
          <div>.</div>
          <div>.</div>
          <div>.</div> */}

      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                Are you sure you want to delete this file?
              </span>
            </p>
          }
          okButtonProps={{
            type: "default",
          }}
          open={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={handleDelete}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <DialogDemo open={open} setOpen={setOpen} data={user} />
    </>
  );
};

export default GetAccessTable;

export const GetTaskViewComponent = () => {
  const param = useParams();
  const [todo, setTodo] = useState(null);
  const [inprogress, setInprogress] = useState(null);
  const [complete, setComplete] = useState(null);
  const [open, setOpen] = useState(false);
  const [todopage, setTodopage] = useState(1);
  const [inprogresspage, setInprogresspage] = useState(1);
  const [completepage, setCompletepage] = useState(1);
  const [userData, setUserData] = useState(null);

  const fetchData = async (status) => {
    try {
      let page;
      if (status === "Todo") {
        page = todopage;
      } else if (status === "InProgress") {
        page = inprogresspage;
      } else {
        page = completepage;
      }
      const response = await axios.get(
        `/bsp/admin/get-task/all?user_id=${param.id}&status=${status}&page_no=${page}`
      );
      const res = await response.data;
      if (res.success) {
        if (status === "Todo") {
          setTodo(res.data);
        } else if (status === "Inprogress") {
          setInprogress(res.data);
        } else {
          setComplete(res.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const userdata = localStorage.getItem("user");
    const userDataObj = JSON.parse(userdata);
    setUserData(userDataObj);

    fetchData("Todo");
    fetchData("Inprogress");
    fetchData("Completed");
  }, []);
  return (
    <div style={{ padding: "0 40px 20px 40px " }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              padding: "20px 0",
              borderBottom: "1px dashed gray",
              marginBottom: 20,
            }}
          >
            <div className="profile">
              <div className="avatar"></div>
              <div className="profile-details">
                <h2 className="name">{userData?.name}</h2>
                <p className="role">{userData?.role?.[0]}</p>
              </div>
            </div>
            <div className="referral">
              <p>
                Referral Code: <span>123456789</span>
              </p>
            </div>
          </div>
        </Col>

        <Col span={24}>
          <Row justify="space-between" align={"middle"}>
            <Col>
              <Typography.Title level={3}>All Task</Typography.Title>
            </Col>
            <Col>
              <Button size="middle" type="primary" icon={<BsDownload />}>
                Download Report
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Button
            onClick={() => setOpen(true)}
            size="middle"
            type="default"
            icon={<BsPlus />}
          >
            Add New Task
          </Button>
        </Col>

        <Col span={24}>
          <Row gutter={32}>
            <Col span={8}>
              <Card
                title={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "green",
                        borderRadius: 99,
                      }}
                    />
                    <span>To Do</span>
                    <div
                      style={{
                        padding: "0 16px",
                        borderRadius: "30px",
                        background: "#4E629E",
                        color: "white",
                      }}
                    >
                      {" "}
                      {todo?.data?.length}
                    </div>
                  </div>
                }
                headStyle={{ border: 0 }}
                style={{ borderColor: "#D3D3D3" }}
              >
                <Space
                  size={"middle"}
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {todo?.data && todo?.data.length > 0 ? (
                    todo?.data.map((item, index) => (
                      <TaskCard id={param?.id} data={item} key={index} />
                    ))
                  ) : (
                    <Empty description="No data found" />
                  )}
                  {todo?.has_next && (
                    <Pagination defaultCurrent={1} total={todo?.record_count} />
                  )}
                </Space>
              </Card>
            </Col>
            <Col span={8}>
              {" "}
              <Card
                title={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "lightgreen",
                        borderRadius: 99,
                      }}
                    />
                    <span>In Progress</span>
                    <div
                      style={{
                        padding: "0 16px",
                        borderRadius: "30px",
                        background: "#4E629E",
                        color: "white",
                      }}
                    >
                      {" "}
                      {inprogress?.data?.length}
                    </div>
                  </div>
                }
                headStyle={{ border: 0 }}
                style={{ borderColor: "#D3D3D3" }}
              >
                <Space
                  size={"middle"}
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {inprogress?.data && inprogress?.data?.length > 0 ? (
                    inprogress?.data?.map((item, index) => (
                      <TaskCard id={param?.id} data={item} key={index} />
                    ))
                  ) : (
                    <Empty description="No data found" />
                  )}
                  {inprogress?.has_next && (
                    <Pagination
                      defaultCurrent={1}
                      total={inprogress?.record_count}
                    />
                  )}
                </Space>
              </Card>
            </Col>
            <Col span={8}>
              {" "}
              <Card
                title={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "darkgreen",
                        borderRadius: 99,
                      }}
                    />
                    <span>Completed</span>
                    <div
                      style={{
                        padding: "0 16px",
                        borderRadius: "30px",
                        background: "#4E629E",
                        color: "white",
                      }}
                    >
                      {complete?.data?.length}
                    </div>
                  </div>
                }
                headStyle={{ border: 0 }}
                style={{ borderColor: "#D3D3D3" }}
              >
                <Space
                  size={"middle"}
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {complete?.data && complete?.data.length > 0 ? (
                    complete?.data.map((item, index) => (
                      <TaskCard id={param?.id} data={item} key={index} />
                    ))
                  ) : (
                    <Empty description="No data found" />
                  )}
                  {complete?.has_next && (
                    <Pagination
                      defaultCurrent={1}
                      total={complete?.record_count}
                    />
                  )}
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalCreateTask
        valid={() => fetchData("Todo")}
        id={param?.id}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
