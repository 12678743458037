import { Pagination, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  // /bsp/admin/get-contactus/all

//   const { data } = await axios.get("/bsp/admin/get-feedback/all", {
//     params: {
//       page_size: pageSize,
//       page_num: pageNumber,
//       // state:value?.state,
//       // district:value?.district,
//       // // age:ageFilter,
//       // profession:value?.profession?.value,
//       // tag_to:value?.tag_member?.value
//     },
//   });

  const ContactUsFunc = () => {

    axios
      .get("/bsp/admin/get-contactus/all",{
        params:{
            page_size:10,
            page_num:page
        }
      })
      .then((response) => {
        if (response?.data?.success) {
          console.log(response?.data?.data);
          setContactUsData(response?.data?.data?.data);
          setTotalPage(response?.data?.data?.page_count * 10);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangePagination = (pageNumber, pageSize) => {
    // getFeedback(pageNumber, pageSize);
    setPage(pageNumber);
  };

  useEffect(() => {
    ContactUsFunc();
  }, [page]);

  console.log(contactUsData);

  return (
    <>
      <div className="event pl-5">

    <div className="head">
          <Typography.Text className="text">Contact Us Data</Typography.Text>
          </div>


          
      <div className="details">
        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Description</th>
              <th>Subject</th>
              <th>Contact ID</th>
              {/* <th style={{ width: "8%" }}>Location</th>
               <th style={{ width: "8%" }}>Actions</th> */}
              {/* <th>Assembly Constitiency</th>
                <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {contactUsData?.map((item, index) => (
              <tr key={index}>
                <td>{item?.name}</td>
                <td>{item?.mobile_no}</td>
                <td>{item?.email_id}</td>
                <td>{item?.description}</td>
                <td>{item?.subject}</td>
                <td>{item?.contact_id}</td>
                {/* <td>{item.feedback_by.age}</td>
                  <td>{item.feedback_by.gender}</td>
                  <td>{item.feedback_by.state}</td>
                  <td>{item.feedback_by.district}</td>
                  <td>{item.feedback_by.assembly_constitution}</td> */}
                {/* <td>
                    <div style={{ display: "flex" }}>
                      <Button
                        style={{ marginRight: "0.6rem" }}
                        onClick={() => openViewMessage(item)}
                      >
                        <FaEye />
                        View Message
                      </Button>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        placement="topLeft"
                        onConfirm={() => handleDeleteConfrim(item)}
                      >
                        <Button
                          shape="circle"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        ></Button>
                      </Popconfirm>
                    </div>
                  </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-style">
          <Pagination
            className="pagination-core"
              current={page}
              total={totalPage}
            showSizeChanger={false}
              onChange={(pageNumber, pageSize) =>
                onChangePagination(pageNumber, pageSize)
              }
          />
        </div>
      </div>
      </div>
    </>
  );
};

export default ContactUs;
