import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "../stepform.css";
import axios from "axios";
import phoneInput from "../PhoneInput.json";
import moment from "moment";
import { Toaster, toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faUserPlus,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const SubmitButton = ({ form }) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, form]);

  return (
    submittable && (
      <Button size="large" htmlType="submit">
        Submit
      </Button>
    )
  );
};

export const JoinMember = () => {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [PinValid, SetPinValid] = useState(null);
  const [roleList, setRoleList] = useState(null);
  const [role, setRole] = useState("");
  const [isMobileValid, setMobileValid] = useState(false);
  const [otp, setOtp] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [isCitizen, setIsCitizen] = useState(null);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [assCon, setAssCon] = useState("");
  const [pin, setPin] = useState("");
  const [data, setData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [clicked, isClicked] = useState(false);
  const [dropTitle, setDropTitle] = useState([]);
  const [dropGender, setDropGender] = useState([]);
  const [dialInput, setDialInput] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [superAdmin, setSuperAdmin] = useState("");
  const [msg, setMsg] = useState("");
  const [maritalStatus, setMaritalStatus] = useState();
  const [professionDropdown, setProfessionDropDown] = useState();
  const [districtListing, setDistrictListing] = useState();
  const [vidhanSabha, setVidhanSabha] = useState();
  const [casteData, setCasteData] = useState();
  // {
  //   "title": "Ms.",
  //   "name": "Vanshika",
  //   "mobile": "9166716691",
  //   "email": "vanshika.agrawal@gmail.com",
  //   "is_citizen": true,
  //   "gender": "Female",
  //   "date_of_birth":"1990-01-01",
  //   "state": "Uttar Pradesh",
  //   "district": "Bareilly",
  //   "assembly_constitution": "Abc",
  //   "block": "block 1",
  //   "pincode": "243001",
  //   "authorization": "ADMIN"
  // }

  const handleCheckedChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleDialInput = (value) => {
    setDialInput(value);
  };

  // const handleStateChange = (state) => {
  //   console.log(state);
  //   setSelectedState(state);
  // };

  const handleDistrictChange = (district) => {
    // /bsp/district_constitutions?district=Mathura
    axios
      .get(
        `/bsp/district_constitutions?state=${selectedState}&district=${district}`
      )
      .then((response) => {
        console.log(response?.data?.data);
        setVidhanSabha(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setSelectedDistrict(district);
  };

  const handleAssemblyChange = (assembly) => {
    setAssCon(assembly);
  };

  const handleZipcodeChange = (zipcode) => {
    setPin(zipcode);
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    setMobileValid(value.length === 10 && /^\d+$/.test(value));
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    setPin(value);
    SetPinValid(value.length === 10 && /^\d+$/.test(value));
  };

  const getDistricts = (state) => {
    const selectedState = data.find((item) => item.name === state);
    return selectedState ? selectedState.districts : [];
  };

  const handleStateChange = (data) => {
    setSelectedState(data);
    axios
      .get(`/bsp/state_districts?state=${data}`)
      .then((response) => {
        console.log(response?.data?.data);
        setDistrictListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAssemblyConstitutions = (district) => {
  //   const selectedDistrict = data
  //     .flatMap((item) => item.districts)
  //     .find((item) => item.name === district);
  //   return selectedDistrict ? selectedDistrict.assembly_constitutions : [];
  // };

  const getZipcodes = (district) => {
    const selectedDistrict = data
      .flatMap((item) => item.districts)
      .find((item) => item.name === district);
    return selectedDistrict ? selectedDistrict.pincodes : [];
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      // console.log(info.file);
      message.success(`${info.file.name} file uploaded successfully`);
      // setImage(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // const

  const customRequest = async ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess(200);
    }, 1000);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 90,
        }}
        value={dialInput}
        placeholder="Select Dial Input"
        onChange={handleDialInput}
      >
        {phoneInput?.map((item, index) => {
          return (
            <Select.Option key={index} value={item.dial_code}>
              {item.emoji} {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select Date of Birth!",
      },
    ],
  };

  useEffect(() => {
    fetch("/bsp/drop-downs")
      .then((resp) => resp.json())
      .then((response) => {
        const responseData = response?.data;

        responseData?.forEach((item) => {
          // console.log(item, ".gallery .ant-tabs-content-holder");
          if (item.field === "DESIGNATIONS") {
            setRoleList(item?.value);
          }
          if (item.field === "TITLE") {
            const titleValues = item?.value;
            setDropTitle(titleValues);
            // } else if (item.field === "GENDER") {
            // const genderValues = item.value;
            const genderValues =
              title === "Mr." ? ["Male"] : ["Female", "Other"];
            setDropGender(genderValues);
          }
          if (item.field === "PROFESSIONS") {
            setProfessionDropDown(item?.value);
          }
          if (item.field === "MARITAL_STATUS") {
            setMaritalStatus(item?.value);
          }
          if (item?.field === "CASTE") {
            setCasteData(item?.value);
          }
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [title]);

  // console.log(professionDropdown);

  useEffect(() => {
    axios
      .get(`/bsp/states`)
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });

    // /bsp/drop-downs
  }, []);
  console.log(data);

  const handleGetOtp = () => {
    axios
      .post(
        "/send-otp",
        {
          mobile: phoneNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        // console.log(response);
        if (response?.data?.success) {
          isClicked(true);
        }
        setMsg(response?.data?.message);
        setInterval(() => {
          setMsg("");
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleNextClick = () => {
    const personalDetails1 = document.getElementById("personal-details-1");
    const personalDetails2 = document.getElementById("personal-details-2");
    personalDetails2.style.display = "block";
    personalDetails1.style.display = "none";
  };

  const handlePreviousClick = () => {
    const personalDetails1 = document.getElementById("personal-details-1");
    const personalDetails2 = document.getElementById("personal-details-2");
    personalDetails2.style.display = "none";
    personalDetails1.style.display = "block";
  };

  const handleDateChange = (date, dateString) => {
    const formattedDate = moment(dateString).format("YYYY-MM-DD");
    setDob(formattedDate);
  };

  // {
  //   "title": "Ms.",
  //   "name": "Vanshika",
  //   "mobile": "9166716691",
  //   "email": "vanshika.agrawal@gmail.com",
  //   "is_citizen": true,
  //   "gender": "Female",
  //   "date_of_birth":"1990-01-01",
  //   "state": "Uttar Pradesh",
  //   "district": "Bareilly",
  //   "assembly_constitution": "Abc",
  //   "block": "block 1",
  //   "pincode": "243001",
  //   "authorization": "ADMIN"
  // }

  const onFinish = (value) => {
    console.log("testtt");
    console.log(value);
    let formData = new FormData();
    // console.log(value, role, "checking value");
    formData.append("title", value["title"].label);
    formData.append("name", value["name"]);
    formData.append("mobile", value["mobile"]);
    formData.append("email", value["email"]);
    formData.append("is_citizen", value["is_citizen"]);
    formData.append("gender", value["gender"].label);
    formData.append("role", value["role"]);
    formData.append(
      "date_of_birth",
      moment(value["date_of_birth"].$d).format("YYYY-MM-DD")
    );
    formData.append("state", value["state"]);
    formData.append("district", value["district"]);
    formData.append("assembly_constitution", value["assembly_constitution"]);
    formData.append("pincode", value["pincode"]);
    formData.append("authorization", value["authorization"]);
    formData.append("marital_status", value["marital_status"]);
    formData.append("profession", value["profession"]);
    formData.append("mother_name", value["mother_name"]);
    {
      value?.caste !== undefined && formData.append("caste", value["caste"]);
    }
    {
      value?.spouse_name !== undefined &&
        formData.append("spouse_name", value["spouse_name"]);
    }
    {
      value?.aniversary_date?.$d !== undefined &&
        formData.append(
          "anniversary_date",
          moment(value["aniversary_date"]?.$d).format("YYYY-MM-DD")
        );
    }
    {
      value?.facebook_id !== undefined &&
        formData.append("facebook", value["facebook_id"]);
    }
    {
      value?.insta_id !== undefined &&
        formData.append("insta", value["insta_id"]);
    }
    {
      value?.twitter_id !== undefined &&
        formData.append("twitter", value["twitter_id"]);
    }

    // console.log(value);
    {
      axios
        .post("/bsp/user/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        })
        .then((resp) => {
          // console.log(resp.data);
          if (resp?.data?.success) {
            // getEvents();
            toast.success(resp?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            handlePreviousClick();
            // onClose();
          } else {
            toast.error(resp?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // const onFinish = (values) => {
  //   console.log(values);
  //   // console.log("Received values of form: ", values);
  //   axios
  //     .post(
  //       "/otp-verify",
  //       {
  //         mobile: phoneNumber,
  //         otp: otp,
  //         title: title,
  //         name: name,
  //         is_citizen: isCitizen,
  //         email: email,
  //         gender: gender,
  //         date_of_birth: dob,
  //         state: selectedState,
  //         district: selectedDistrict,
  //         assembly_constitution: assCon,
  //         pincode: pin,
  //         authorization: superAdmin,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       // console.log(response);
  //       if (response?.data?.success) {
  //         toast.success("User added Successfully", {
  //           style: {
  //             border: "2px solid #2192ff",
  //             padding: "16px 20px",
  //             borderRadius: "10px",
  //             color: "#10309b",
  //             fontWeight: "500",
  //           },
  //           iconTheme: {
  //             primary: "#2192ff",
  //             secondary: "#fffaee",
  //           },
  //         });
  //         form.resetFields();
  //         handlePreviousClick();
  //         setOtp("");
  //         document.getElementById("otp-btn").disabled = true;
  //       }
  //       setMsg(response?.data?.message);
  //       setInterval(() => {
  //         setMsg("");
  //       }, 5000);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const getAllDropdowns=()=>{
  //   axios.get("/bsp/drop-downs")
  //   .then((response)=>{
  //       console.log(response?.data);
  //       // setListingData(response?.data?.data?.data)
  //       setDropDownData(response?.data?.data);
  //   })
  //   .catch((err)=>{
  //       console.log(err);
  //   });
  //   };

  //   useEffect(()=>{
  //     getAllDropdowns();
  //   },[])

  //   console.log(dropDownData);

  return (
    <div className="joinmember">
      <div className="head">
        <Typography.Text className="text">User Management</Typography.Text>
      </div>
      <div className="stepform">
        <div className="form-container">
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 10,
              },
            }}
          >
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              className="form"
              onFinish={onFinish}
              initialValues={{
                prefix: "+91",
              }}
            >
              <div id="personal-details-1">
                <h1>Create/Modify User</h1>
                <div className="name-tag">
                  <Form.Item
                    name="title"
                    label={<p className="label-text">Title</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please select Title",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Title"
                      labelInValue
                      onChange={(e) => setTitle(e.label)}
                    >
                      {dropTitle?.map((item, index) => {
                        return (
                          <Select.Option key={index}>{item}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="name"
                    label={<p className="label-text">Name</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="mobile"
                  label={<p className="label-text">Mobile No.</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Mobile Number",
                    },
                    {
                      max: 10,
                      min: 10,
                      message: "Mobile Number should be 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    style={{
                      width: "100%",
                    }}
                    type="number"
                    placeholder="Enter your Mobile Number"
                    value={phoneNumber}
                    onChange={handleMobileChange}
                  />
                </Form.Item>
                {/* <div className="otp">
                  {isMobileValid && (
                    <Form.Item>
                      <Button
                        id="otp-btn"
                        onClick={handleGetOtp}
                        // disabled={!isMobileValid}
                      >
                        Get OTP
                      </Button>
                    </Form.Item>
                  )}
  
                  {clicked && (
                    <div id="otp-field">
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: "true",
                            message: "Please enter correct OTP",
                          },
                          {
                            max: 4,
                            min: 4,
                            message: "Value should be 4 digits",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter 4 digit OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          disabled={!handleGetOtp}
                        />
                      </Form.Item>
                    </div>
                  )}
                </div> */}
                <Form.Item
                  name="email"
                  label={<p className="label-text">Email ID</p>}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-Mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-Mail!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="authorization"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#7a96e0",
                      },
                    }}
                  > */}
                  <Radio.Group
                    value={superAdmin}
                    onChange={(e) => setSuperAdmin(e.target.value)}
                    size="large"
                  >
                    <Radio.Button value="SUPER_ADMIN">
                      <FontAwesomeIcon icon={faUserTie} />
                      &nbsp;&nbsp;As a Super Admin
                    </Radio.Button>
                    <Radio.Button value="ADMIN">
                      <FontAwesomeIcon icon={faUserPlus} />
                      &nbsp;&nbsp;As an Admin
                    </Radio.Button>
                    <Radio.Button value="USER">
                      <FontAwesomeIcon icon={faUsers} />
                      &nbsp;&nbsp;As a User
                    </Radio.Button>
                  </Radio.Group>
                  {/* </ConfigProvider> */}
                </Form.Item>

                <div className="agreement">
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Should accept Agreement")
                              ),
                      },
                    ]}
                  >
                    {/* <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#7a96e0",
                        },
                      }}
                    > */}
                    <Checkbox
                      onChange={handleCheckedChange}
                      style={{ textAlign: "justify" }}
                    >
                      <p
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#1d3e9bcc",
                        }}
                      >
                        <span style={{ color: "red" }}>*</span>&nbsp;I certify
                        that above provided information is correct and there is
                        no mistake. I know that all further communication will
                        be done on above provided details.
                      </p>
                    </Checkbox>
                    {/* </ConfigProvider> */}
                  </Form.Item>
                </div>
                {isChecked && (
                  <Form.Item style={{ float: "right" }}>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#7a96e0",
                        },
                      }}
                    >
                      <Button
                        size="large"
                        // disabled={!isChecked}
                        htmlType="submit"
                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    </ConfigProvider>
                  </Form.Item>
                )}
              </div>

              <div id="personal-details-2">
                <div className="details2">
                  <Form.Item
                    name="is_citizen"
                    label={
                      <p className="label-text">Are you an Indian Citizen</p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please specify your country of origin!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio
                        // style={{ color: "#1d3e9bbb" }}
                        value={true}
                        checked={isCitizen === true}
                        onChange={(e) => setIsCitizen(e.target.value)}
                      >
                        Yes
                      </Radio>
                      <Radio
                        // style={{ color: "#1d3e9bbb" }}
                        value={false}
                        checked={isCitizen === false}
                        onChange={(e) => setIsCitizen(e.target.value)}
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="date_of_birth"
                    label={<p className="label-text">Date of Birth</p>}
                    {...config}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                      placeholder="Select Date"
                    />
                  </Form.Item>
                </div>
                <div className="profile">
                  {/* <Form.Item
                    name="file"
                    label={<p className="label-text">Upload Voter ID</p>}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                  >
                    <Upload
                      name="file"
                      customRequest={customRequest}
                      onChange={onChange}
                      maxCount={1}
                      listType="text"
                      showUploadList={{
                        showDownloadIcon: false,
                      }}
                    >
                      <Button icon={<FontAwesomeIcon icon={faUpload} />}>
                        Select Image
                      </Button>
                    </Upload>
                  </Form.Item> */}
                  <Form.Item
                    name="gender"
                    label={<p className="label-text">Gender</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please specify your gender!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "260px" }}
                      placeholder="Select Gender"
                      labelInValue
                      onChange={(e) => setGender(e.label)}
                      disabled={!title}
                    >
                      {dropGender?.map((item, index) => {
                        return (
                          <Select.Option key={index}>{item}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="role"
                    label={<p className="label-text">Role</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please specify Role!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "260px" }}
                      placeholder="Select Role" 
                      onChange={(e) => setRole(e.label)}
                      disabled={!title}
                    >
                      {roleList?.map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    {/* <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "260px" }}

                      placeholder="Select Role"
                      // defaultValue={["a10", "c12"]}
                      disabled={!title}
                      onChange={handleChange}
                      options={options}
                    >
                        {roleList?.map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select> */}
                  </Form.Item>
                </div>

                <div className="profile">
                  <Form.Item
                    name="profession"
                    label={<p className="label-text">Profession</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please select your Profession",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "260px" }}
                      placeholder="Select Profession"
                      onChange={(e) => setRole(e.label)}
                      disabled={!title}
                    >
                      {professionDropdown?.map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="marital_status"
                    label={<p className="label-text">Marital Status</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please select your marital status",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "260px" }}
                      placeholder="Marital Status"
                      onChange={(e) => setRole(e.label)}
                      disabled={!title}
                    >
                      {maritalStatus?.map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

                <div className="assembly">
                  <Form.Item
                    name="mother_name"
                    label={<p className="label-text">Mother Name</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mother Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Mother Name"
                      value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="spouse_name"
                    label={<p className="label-text">Spouse Name</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Mother Name",
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Spouse Name"
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="assembly">
                  <Form.Item
                    name="caste"
                    label={<p className="label-text">Caste</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Mother Name",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder="Select Caste"
                      // value={selectedState}
                      // onChange={handleStateChange}
                    >
                      {casteData?.map((state, index) => (
                        <Select.Option key={index} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="aniversary_date"
                    label={<p className="label-text">Aniversary Date</p>}
                    // {...config}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      // onChange={handleDateChange}
                      placeholder="Select Date"
                    />
                  </Form.Item>
                </div>
                <h2 style={{ color: "#1d3e9b" }}>Social Handle Links</h2>

                <div className="assembly">
                  <Form.Item
                    name="insta_id"
                    label={<p className="label-text">Insta ID</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Mother Name",
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Insta ID"
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="facebook_id"
                    label={<p className="label-text">Facebook ID</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Mother Name",
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Facebook ID"
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="assembly">
                  <Form.Item
                    name="twitter_id"
                    label={<p className="label-text">Twitter ID</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Mother Name",
                    //   },
                    // ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Twitter ID"
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <h2 style={{ color: "#1d3e9b" }}>Address Details</h2>

                <div className="state">
                  <Form.Item
                    name="state"
                    label={<p className="label-text">State</p>}
                    rules={[
                      {
                        required: "true",
                        message: "Please select your State",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select State"
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      {data?.map((state, index) => (
                        <Select.Option key={index} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="district"
                    label={<p className="label-text">District</p>}
                    rules={[
                      {
                        required: "true",
                        message: "Please select your District",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select District"
                      // value={selectedDistrict}
                      onChange={handleDistrictChange}
                      disabled={!districtListing}
                    >
                      {districtListing?.map((district, index) => (
                        <Select.Option key={index} value={district}>
                          {district}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="assembly">
                  <Form.Item
                    name="assembly_constitution"
                    label={
                      <p className="label-text">Assembly Constitution (AC)</p>
                    }
                    rules={[
                      {
                        required: "true",
                        message: "Please select your Assembly Constitution",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your Assembly"
                      onChange={handleAssemblyChange}
                      disabled={!vidhanSabha}
                    >
                      {vidhanSabha?.map((assembly, index) => (
                        <Select.Option key={index} value={assembly}>
                          {assembly}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="pincode"
                    label={<p className="label-text">pincode</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your pincode",
                      },
                      {
                        max: 6,
                        min: 6,
                        message: "pincode should be 6 digits",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                      }}
                      type="number"
                      placeholder="Enter your pincode"
                      value={pin}
                      onChange={handlePinChange}
                      disabled={!assCon}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="pincode"
                    label={<p className="label-text">Pin-Code</p>}
                    rules={[
                      {
                        required: "true",
                        message: "Please select pincode",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select zipcode"
                      onChange={handleZipcodeChange}
                    >
                      {getZipcodes(selectedDistrict)?.map((zip) => (
                        <Select.Option key={zip} value={zip}>
                          {zip}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </div>
                <div className="button-grp">
                  <Form.Item>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#7a96e0",
                        },
                      }}
                    >
                      <Button size="large" onClick={handlePreviousClick}>
                        Previous
                      </Button>
                    </ConfigProvider>
                  </Form.Item>

                  {/* <Form.Item>
                    <Space>{otp && <SubmitButton form={form} />}</Space>
                  </Form.Item> */}

                  <Button size="large" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </ConfigProvider>
          {msg && <p className="error">{msg}</p>}
        </div>
        <Toaster />
      </div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
    </div>
  );
};
