import { PlusOutlined } from "@ant-design/icons";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { TbUpload } from "react-icons/tb";
import { errorHandler } from "../errorHandler";

const AddLeader = ({ getLeaders, onclose, editData }) => {
  const [form] = Form.useForm();
  const [editedData, setEditedData] = useState();
  // const [uploadingLoader, setUploadingLoader] = useState(false);
  const [uploadedMultiImage, setUploadMultiImage] = useState(null);
  // const [uploadedMultiImage,setUploadMultiImage]=useState(null);
  const [imageSize, setImageSize] = useState();

  const onFinish = (values) => {
    console.log(values);
    // let formData = new FormData();
    // formData.append("position", values["position"]);
    // formData.append("name", values["name"]);
    // formData.append("quote",values["quote"]);
    // formData.append("description",values["description"]);

    // if(editData !== null){
    // let formEditData = new FormData();
    // formEditData.append("_id", values["_id"]);
    // formEditData.append("is_visible",values["true"]);
    // formData.append("position", values["position"]);
    // formData.append("name", values["name"]);
    // formData.append("quote",values["quote"]);
    // formData.append("description",values["description"]);
    // setEditedData(formEditData);
    // }

    // export const uploadConsentFormCenter = (formData) => {
    //   return api.post(`/MFI/center/update_center_documents`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Accept: "application/pdf",
    //       type: "formData",
    //     },
    //   });
    // };

    if (editData == null) {
      if (uploadedMultiImage == null) {
        toast.error("Please Upload Image", {
          className: "error",
        });
      }

      let formData = new FormData();
      formData.append("position", values["position"]);
      formData.append("name", values["name"]);
      formData.append("quote", values["quote"]);
      formData.append("description", values["description"]);
      formData.append("image", uploadedMultiImage);

      axios
        .post("/bsp/admin/create-leader", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
            Accept: "application/pdf",
            type: "formData",
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            getLeaders();
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            onclose();
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      let formEditData = new FormData();
      formEditData.append("_id", editData?._id);
      formEditData.append("is_visible", ["true"]);
      formEditData.append("position", values["position"]);
      formEditData.append("name", values["name"]);
      formEditData.append("quote", values["quote"]);
      formEditData.append("description", values["description"]);
      // formEditData.append("image", uploadedMultiImage);
      {
        uploadedMultiImage !== null &&
          formEditData.append("image", uploadedMultiImage);
      }

      axios
        .put("/bsp/admin/update-leader", formEditData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
            Accept: "application/pdf",
            type: "formData",
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            getLeaders();
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            onclose();
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    }

    // editData == null
    //   ? axios
    //       .post(
    //         "/create-leader",
    //          formData,
    //          {
    //           headers: {
    //             "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         if (response?.data?.success) {
    //           getLeaders();
    //           toast.success(response?.data?.message, {
    //             className: "toast",
    //             iconTheme: {
    //               primary: "#005bea",
    //               secondary: "#f0f7ff",
    //             },
    //           });
    //           form.resetFields();
    //           onclose();
    //         } else {
    //           toast.error(response?.data?.message, {
    //             className: "error",
    //           });
    //         }
    //       })
    //       .catch((err) => console.log(err))
    //   : axios
    //       .put(
    //         "/update-leader",
    //         editedData,
    //         {
    //          headers: {
    //             "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         if (response?.data?.success) {
    //           getLeaders();
    //           toast.success(response?.data?.message, {
    //             className: "toast",
    //             iconTheme: {
    //               primary: "#005bea",
    //               secondary: "#f0f7ff",
    //             },
    //           });
    //           form.resetFields();
    //           onclose();
    //         } else {
    //           toast.error(response?.data?.message, {
    //             className: "error",
    //           });
    //         }
    //       })
    //       .catch((err) => console.log(err));
  };

  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    console.log("mnfftg");
    uploadImage.current.click();
  };

  const handleImageUploadChange = async (e) => {
    console.log("jhfvbrv");
    const maxAllowedSize = 2 * 1024 * 1024;

    if (e.target.files[0].size < maxAllowedSize) {
      const uploadedImage = e.target?.files[0];
      // const qual = 0.8;
      console.log(uploadedImage);
      // setUploadMultiImage(uploadedImage);

      if (uploadedImage?.type.includes("image")) {
        // setUploadingLoader(true);
        // const { data } = await updateLoanAttachment(formData);
        // // setUploadingLoader(false);
        // if (data?.sucess) {
        //   // successNotify(data.message);
        // } else {
        //   // errorNotify(data.message);
        // }
      }
    } else {
      // errorNotify("File size is more than 1 mb !");
    }
  };

  console.log(uploadedMultiImage);

  const customRequest = async ({ file, onSuccess, onError }) => {
    // Implement your custom upload logic here
    // You can use Axios, Fetch, or any other library to send the file to the server

    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
    console.log(file);
    setUploadMultiImage(file);
    // multiImageUploaded.push(file);
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      // setUploadedImageFile(info.file.originFileObj);
      // setUploadedImageUrl(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    console.log(info.fileList);
  };

  console.log(editData);

  const getImageSize = () => {
    axios
      .get("/bsp/image_config?type=leader")
      .then((response) => {
        if (response?.data) {
          // setLeadersDropdown(response?.data);
          setImageSize(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getImageSize();
  }, []);
  console.log(imageSize);
  // /bsp/image_config?type=event

  return (
    <div className="leaderform">
      <input
        type="file"
        style={{ display: "none" }}
        ref={uploadImage}
        onChange={(e) => handleImageUploadChange(e)}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Form
        name="leaderform"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        // initialValues={{
        // quote:editData?.quote !== "undefined" && editData?.quote
        // }}
      >
        <Row justify={"space-between"}>
          <Form.Item
            name={"position"}
            label={<p className="label-text">Prefix</p>}
            rules={[
              {
                required: true,
                message: "Please enter a Prefix",
              },
            ]}
            style={{ width: "48%" }}
            initialValue={editData?.position}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter a Prefix"
            />
          </Form.Item>
          <Form.Item
            name={"name"}
            label={<p className="label-text">Name</p>}
            rules={[
              {
                required: true,
                message: "Please enter the name",
              },
            ]}
            style={{ width: "48%" }}
            initialValue={editData?.name}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter name of the person"
            />
          </Form.Item>
        </Row>

        {/* <Row justify={"space-between"}>
          <Form.Item
            name={"instagram"}
            label={<p className="label-text">Instagram</p>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter Instagram ID",
            //   },
            // ]}
            style={{ width: "48%" }}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter Instagram ID"
            />
          </Form.Item>
          <Form.Item
            name={"Youtube"}
            label={<p className="label-text">Youtube</p>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter the",
            //   },
            // ]}
            style={{ width: "48%" }}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter Youtube ID"
            />
          </Form.Item>
        </Row> */}

        {/* <Row justify={"space-between"}>
          <Form.Item
            name={"twitter"}
            label={<p className="label-text">Twitter</p>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter Instagram ID",
            //   },
            // ]}
            style={{ width: "48%" }}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter Twitter ID"
            />
          </Form.Item>
          <Form.Item
            name={"facebook"}
            label={<p className="label-text">Facebook</p>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter the",
            //   },
            // ]}
            style={{ width: "48%" }}
          >
            <Input
              style={{ borderRadius: "10px" }}
              placeholder="Enter Facebook ID"
            />
          </Form.Item>
        </Row> */}

        <Form.Item
          name={"quote"}
          label={<p className="label-text">Quote</p>}
          rules={[
            {
              required: true,
              message: "Please enter his/her quote",
            },
          ]}
          initialValue={editData?.quote !== "undefined" && editData?.quote}
        >
          <TextArea
            style={{
              height: "75px",
              resize: "none",
              fontFamily: "Poppins, sans-serif",
              borderRadius: "14px",
            }}
            placeholder="Famous Quote of the person"
          />
        </Form.Item>

        <Form.Item
          name={"description"}
          label={<p className="label-text">Description</p>}
          rules={[
            {
              required: true,
              message: "Please enter a long description",
            },
            // {
            //   min: 1000,
            //   message: "Must be at least 1000 characters",
            // },
          ]}
          initialValue={editData?.description}
        >
          <TextArea
            style={{
              height: "180px",
              resize: "none",
              fontFamily: "Poppins, sans-serif",
              borderRadius: "14px",
            }}
            placeholder="Enter a long Description"
          />
        </Form.Item>

        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* 
            <Form.Item
            name="file"
            label={<p className="label-text">Upload Cover Image</p>}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: true,
                // validator(_, value) {
                //   const acceptedFormats = [
                //     "image/jpeg",
                //     "image/png",
                //     "image/gif",
                //   ];
                //   const file = value && value[0];

                //   if (file && !acceptedFormats.includes(file.type)) {
                //     return Promise.reject(
                //       "Please upload a valid image file (JPEG, PNG, GIF)."
                //     );
                //   }

                //   return Promise.resolve();
                // },
                message: "Please upload a valid image file",
              },
            ]}
          > */}
          {/* <Button
            color="info"
            size="sm"
            onClick={handleUpload}
            className="mr-0"
          >
            {" "}
            <TbUpload></TbUpload>
          </Button> */}

          <Form.Item
            name="file"
            label={<p className="label-text">Upload Cover Image</p>}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: editData === null ? true : false,
                message: "Please upload Image",
              },
            ]}
            style={{ textAlign: "center" }}
          >
            <Upload
              name="file"
              customRequest={customRequest}
              onChange={onChange}
              maxCount={1}
              // multiple
              listType="picture-card"
              showUploadList={{
                showDownloadIcon: false,
              }}
              accept="image/*" // Specify accepted file types
            >
              <div>
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ background: "transparent" }}
                />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
          </Form.Item>

          {/* {editData == null && uploadedMultiImage == null && <p>Plmnbvcbn</p>} */}

          {/* </Form.Item> */}

          {/* <Form.Item
            name="file"
            label={<p className="label-text">Upload Cover Image</p>}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: true,
                validator(_, value) {
                  const acceptedFormats = [
                    "image/jpeg",
                    "image/png",
                    "image/gif",
                  ];
                  const file = value && value[0];

                  if (file && !acceptedFormats.includes(file.type)) {
                    return Promise.reject(
                      "Please upload a valid image file (JPEG, PNG, GIF)."
                    );
                  }

                  return Promise.resolve();
                },
                message: "Please upload a valid image file",
              },
            ]}
          >
            <Upload
              name="file"
              maxCount={1}
              listType="text"
              showUploadList={{
                showDownloadIcon: false,
              }}
              accept="image/jpeg,image/png,image/gif,image/ico,image/jpg"
            >
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: "10px",
                  },
                }}
              >
                <Button icon={<FontAwesomeIcon icon={faUpload} />}>
                  Select Image
                </Button>
              </ConfigProvider>
            </Upload>
          </Form.Item> */}

          {/* <Row
          justify={"center"}
          style={{ display: "flex", alignItems: "center" }}
        > */}
          <ConfigProvider
            theme={{
              token: {
                borderRadius: "12px",
              },
            }}
          >
            <Form.Item>
              <Button htmlType="submit" size="large">
                {editData == null ? "Add Leader" : "Update Leader"}
                {/* Add Leader */}
              </Button>
            </Form.Item>
          </ConfigProvider>
        </Row>
      </Form>
      <div style={{ color: "#1d3f9bbb", "font-weight": "bolder" }}>
        Note-Upload Image height and width should be {imageSize?.height} ×{" "}
        {imageSize?.width} respectively
      </div>
    </div>
  );
};

const Leadership = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [leadersDropdown, setLeadersDropdown] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteLeader, setDeleteLeader] = useState();
  const [loader, setLoader] = useState(false);

  const showForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEditData(null);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);

  const getLeaders = () => {
    setLoader(true);
    axios
      // http://192.168.1.154:6070/bsp/admin/get-leader/all
      .get("/bsp/admin/get-leader/all")
      .then((response) => {
        if (response?.data?.success) {
          setItems(response?.data?.data?.data);
          console.log(response?.data?.data);
          setLoader(false);
        } else {
          Cookies.remove("sessions");
          errorHandler(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(loader);

  const getLeaderDropdown = () => {
    axios
      .get("/bsp/admin/get-leader-dropdown")
      .then((response) => {
        if (response?.data) {
          setLeadersDropdown(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLeaders();
    getLeaderDropdown();
  }, []);

  const showDeleteModal = (value) => {
    setDeleteLeader(value?._id);
    setIsDeleteModalVisible(true);
  };

  // const id = "65013135c62dc719af31de18";

  // useEffect(() => {
  //   axios
  //     .get(`/get-leader/${id}`)
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // });

  // `get-voters-list?page_num=${page}&page_size=${pageSize}`

  // previous running code
  // const handleSelectChange = (value) => {
  //   console.log(JSON.parse(value));

  //   axios
  //   .get(`/get-leader/all/${JSON.parse(value?._id)}`)
  //   .then((response) => {
  //     console.log(response.data.data);
  //     setItems(response.data.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  //   // if (value) {
  //   //   setSelectedItem(JSON.parse(value));
  //   // } else {
  //   //   setSelectedItem(null);
  //   // }
  // };

  const handleOptionChanges = (value) => {
    // console.log(JSON.parse(value));
    if (value !== undefined) {
      axios
        .get(`/bsp/admin/get-leader/${value}`)
        .then((response) => {
          setItems([response?.data?.data]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editDataFunc = (item) => {
    // form.setFieldValue('quote', item?.quote);
    setIsFormOpen(true);
    setEditData(item);
    setModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (deleteLeader) {
      axios
        .delete(`/bsp/admin/delete-leader/${deleteLeader}`)
        .then((response) => {
          if (response?.data?.success) {
            //   getEvents();
            getLeaders();
            setIsDeleteModalVisible(false);
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // "/delete-leader/65098516db6c81f7785df397"

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  console.log(items);

  return (
    <>
      <div className="leadership pl-5">
        <div className="head">
          <Typography.Text className="text">Our Ideals</Typography.Text>
          <Tooltip title="New Ideals">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
              onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </div>
        <Drawer
          title={
            <p className="icon-p">
              {editData == null ? "Add a Ideals" : "Update a Ideals"}
            </p>
          }
          width={550}
          onClose={closeForm}
          open={isFormOpen}
          bodyStyle={{
            background: "#ffff",
          }}
          destroyOnClose
        >
          <AddLeader
            getLeaders={getLeaders}
            onclose={closeForm}
            editData={editData}
          />
        </Drawer>
        {/* <ConfigProvider
          theme={{
            token: {
              borderRadius: 10,
            },
          }}
        >

          <Select
            // className="mw-input"
            // style={{ width: "100%" }}
            showSearch
            style={{
              width: "20%",
              marginTop: "-10px",
              borderRadius: "10px",
            }}
            placeholder="Select Leader Here"
            allowClear
            optionFilterProp="children"
            onChange={(e) => handleOptionChanges(e)}
            onClear={getLeaders}
            // onSearch={onSearch}
            // filterOption={filterOption}
            options={leadersDropdown?.data?.map((data, i) => {
              return {
                value: data?._id,
                label: `${data?.title}`, //This label will be shown on the UI
              };
            })}
          />
        </ConfigProvider> */}

        {loader && <Spin />}

        {items?.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
              fontSize: "xx-large",
              fontWeight: "bold",
            }}
          >
            No Data found
          </div>
        ) : null}

        {items?.map((value, index) => {
          // {value?.image}
          console.log(value);
          return (
            <>
              {/* {console.log(index)} */}
              <div className="selected-item" key={index + 1}>
                {/* {selectedItem.photo ? ( */}
                {/* <img
                  src="https://pbs.twimg.com/profile_images/1089496873818218496/14kcpjmA_400x400.jpg"
                  // alt={selectedItem.title}
                /> */}
                <img src={value?.image_url} />

                {/* ) : ( */}
                {/* <img
              src="https://pbs.twimg.com/profile_images/1089496873818218496/14kcpjmA_400x400.jpg"
              alt={selectedItem.title}
            /> */}
                {/* )} */}
                <div className="selected-text">
                  <Typography.Title level={4} className="title">
                    {value?.position} {value?.name}
                  </Typography.Title>

                  {/* <div className="actions"> */}
                  <Tooltip title="Update">
                    <Button
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faPen} />}
                      onClick={() => editDataFunc(value)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => showDeleteModal(value)}
                    />
                  </Tooltip>
                  {/* </div> */}

                  <p className="quote">"{value?.quote}"</p>
                  <Typography.Paragraph
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {/* {selectedItem && (
                <div>
                  <p>{selectedItem.description}</p>
                </div>
              )} */}
                    <p>{value?.description}</p>
                  </Typography.Paragraph>
                  <div className="icons-style">
                    {value?.social_handles_url?.facebook && (
                      <FaFacebookF size={20} />
                    )}
                    {value?.social_handles_url?.twitter && (
                      <FaXTwitter size={20} />
                    )}
                    {value?.social_handles_url?.insta && (
                      <FaSquareInstagram size={20} />
                    )}
                    {value?.social_handles_url?.youtube && (
                      <FaYoutube size={20} />
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                You're going to delete the Leader. Are your sure?
              </span>
            </p>
          }
          open={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onOk={handleDeleteConfirm}
          okButtonProps={{
            type: "default",
          }}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
    </>
  );
};
export default Leadership;
