import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, ConfigProvider, Drawer, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import EventDetails from "./EventDetails";
import FormComponent from "./FormComponent";

export const Event = () => {
  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);

  // axios
  //     .get(
  //       "/voter/admin/get-voters-list",{
  //         params:{
  //           page_num:page,
  //           page_size:10,
  //           state:selectedState,
  //           district:selectedDistrict,
  //           vidhan_sabha:selectedVidhansabha,
  //           epic_no:epicNo
  //         }
  //       }
  //     )

  const getEvents = () => {
    setLoader(true);
    axios
      .get("/bsp/admin/get-event/all", {
        params: {
          page_num: page,
          page_size: 10,
        },
      })
      .then((response) => {
        if (response?.data?.success) {
          console.log(response?.data?.data, "cheking data");
          setTotalPage(response?.data?.data?.page_count * 10);
          const setEventData = response?.data?.data?.data?.map(
            (item, index) => ({
              ...item,
              index: index,
            })
          );
          setEvents(setEventData);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEvents();
  }, [page]);

  console.log(page);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const showForm = (event) => {
    setSelectedEvent(event);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setSelectedEvent(null);
    setIsFormOpen(false);
  };

  return (
    <div
      style={{ paddingLeft: "1.2rem", paddingBottom: "5rem" }}
      className="event "
    >
      <div className="head">
        <Typography.Text className="text">Upcoming Events</Typography.Text>
        <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
          <Tooltip title="New Event">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
              onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </ConfigProvider>
      </div>
      <div>
        <EventDetails
          events={events}
          onEdit={showForm}
          getEvents={getEvents}
          loader={loader}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
        />
      </div>
      <Drawer
        title={
          selectedEvent ? (
            <p className="icon-p">
              {/* <EditOutlined className="icons" /> */}
              Update an Event
            </p>
          ) : (
            <p className="icon-p">
              {/* <FormOutlined className="icons" /> */}
              Create an Event
            </p>
          )
        }
        width={720}
        onClose={closeForm}
        open={isFormOpen}
        bodyStyle={{
          background: "#fff",
        }}
        destroyOnClose
      >
        <FormComponent
          event={selectedEvent}
          onClose={closeForm}
          getEvents={getEvents}
        />
      </Drawer>
    </div>
  );
};
