import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JourneyAdd from "./JourneyAdd";
import toast from "react-hot-toast";
import {
  faPen,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

const JourneyComponent = () => {
  const [journeyData, setJourneyData] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteJourney, setDeleteJourney] = useState();

  //   /get-journey/all

  const getAllJourneyFunc = () => {
    axios
      .get("/bsp/admin/get-journey/all")
      .then((response) => {
        console.log(response?.data?.data?.data);
        setJourneyData(response?.data?.data?.data);
        if (response?.success) {
          //   setLeadersDropdown(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showDeleteModal = (value) => {
    console.log(value);
    setDeleteJourney(value?._id);
    setIsDeleteModalVisible(true);
  };

  console.log(deleteJourney);

  const showForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEditData(null);
  };

  const editDataFunc = (value) => {
    setIsFormOpen(true);
    setEditData(value);
    console.log(value);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteConfirm = () => {
    "/delete-journey/657167166c4d4fbde1481bc3";

    if (deleteJourney) {
      axios
        .delete(`/bsp/admin/delete-journey/${deleteJourney}`)
        .then((response) => {
          // console.log(response.data);
          if (response?.data?.success) {
            //   getEvents();
            getAllJourneyFunc();
            setIsDeleteModalVisible(false);
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    console.log("delete data");
    setIsDeleteModalVisible(false);
  };

  console.log(editData);

  useEffect(() => {
    getAllJourneyFunc();
  }, []);

  return (
    <>
      <div className="leadership pl-5">
        <div className="head">
          <Typography.Text className="text">Our Journey</Typography.Text>
          <Tooltip title="Add Journey">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
              onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </div>
        <Drawer
          title={
            <p className="icon-p">
              {editData == null ? "Add a Journey" : "Update Journey"}
            </p>
          }
          width={550}
          onClose={closeForm}
          open={isFormOpen}
          bodyStyle={{
            background: "#ffff",
          }}
          destroyOnClose
        >
          {/* <AddLeader getLeaders={getLeaders} onclose={closeForm} editData={editData}/> */}
          <JourneyAdd
            onclose={closeForm}
            getAllJourneyFunc={getAllJourneyFunc}
            editData={editData}
          />
        </Drawer>

        {journeyData?.map((value, index) => {
          return (
            <>
              <div className="selected-item">
                <img
                  // src="https://pbs.twimg.com/profile_images/1089496873818218496/14kcpjmA_400x400.jpg"
                  // // alt={selectedItem.title}
                  src={value?.image_url}
                  style={{ width: "13rem" }}
                />

                <div className="selected-text">
                  <Typography.Title level={4} className="title">
                    {/* {value?.name} - {value?.position} */}
                    {value?.title}
                  </Typography.Title>

                  <p className="journey-year">{value?.year}</p>
                  <Typography.Paragraph
                    style={{
                      fontSize: 16,
                    }}
                  >
                    <p>{value?.description}</p>
                  </Typography.Paragraph>
                  <Tooltip title="Update">
                    <Button
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faPen} />}
                      onClick={() => editDataFunc(value)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => showDeleteModal(value)}
                    />
                  </Tooltip>
                </div>
              </div>
            </>
          );
        })}

        <ConfigProvider
          theme={{
            token: {
              borderRadius: "10px",
            },
          }}
        >
          <Modal
            width={400}
            className="delete-modal"
            title={
              <p className="p-icon">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="delete-icon"
                />
                <span className="delete-span">
                  You're going to delete the event. Are your sure?
                </span>
              </p>
            }
            open={isDeleteModalVisible}
            onCancel={handleDeleteCancel}
            onOk={handleDeleteConfirm}
            okButtonProps={{
              type: "default",
            }}
            okText={
              <p className="p-icon">
                <span>Yes, Delete!</span>
              </p>
            }
            cancelText={
              <p className="p-icon">
                <span>No, Keep it.</span>
              </p>
            }
            destroyOnClose
          ></Modal>
        </ConfigProvider>
      </div>
    </>
  );
};

export default JourneyComponent;
