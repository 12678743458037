import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, ConfigProvider, Drawer, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import VoterDataListing from "./VoterDataListing";
import VoterDataAdd from "./VoterDataAdd";

const VoterDataComponent = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [voterData, setVoterData] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const showForm = (event) => {
    setSelectedEvent(event);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setSelectedEvent(null);
    setIsFormOpen(false);
  };

  console.log(page);

  // /voter/admin/get-voters-list?page_num=1&page_size=10&state=Uttar Pradesh&district=Agra

  // const getVoterListFunc= ()=>{
  //     axios.get(`/bsp/admin/get-voters-list?page_num=${page}&page_size=${pageSize}`)
  //     .then((response)=>{
  //       console.log(response);
  //         if(response?.data?.success){
  //             setVoterData(response?.data);
  //             setTotalPage(response?.data?.data?.page_count * 10);
  //         }
  //     })
  //     .catch((error)=>{
  //         console.log(error);
  //     });
  // }

  // const getVoterListFunc= ()=>{
  //       axios.get(`/voter/admin/get-voters-list?page_num=1&page_size=10&state=Uttar Pradesh&district=Agra`)
  //       .then((response)=>{
  //         console.log(response);
  //           if(response?.data?.success){
  //               setVoterData(response?.data);
  //               setTotalPage(response?.data?.data?.page_count * 10);
  //           }
  //       })
  //       .catch((error)=>{
  //           console.log(error);
  //       });
  //   }

  // // console.log(page);

  // useEffect(()=>{
  //     getVoterListFunc();
  // },[page])

  console.log(typeof totalPage);

  return (
    <>
      <div className="event pl-5">
        <div className="head">
          <Typography.Text className="text">Voter Data </Typography.Text>
          {/* <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
          <Tooltip title="New Event">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
              onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </ConfigProvider> */}
        </div>
        <div>
          {/* <EventDetails events={events} onEdit={showForm} getEvents={getEvents} /> */}
          <VoterDataListing
            setPage={setPage}
            page={page}
            setPageSize={setPageSize}
          />
        </div>
        <Drawer
          title={
            selectedEvent ? (
              <p className="icon-p">
                {/* <EditOutlined className="icons" /> */}
                Update Voter Data
              </p>
            ) : (
              <p className="icon-p">
                {/* <FormOutlined className="icons" /> */}
                Upload Voter Data
              </p>
            )
          }
          width={720}
          onClose={closeForm}
          open={isFormOpen}
          bodyStyle={{
            background: "#fff",
          }}
          destroyOnClose
        >
          {/* <FormComponent
          event={selectedEvent}
          onClose={closeForm}
          getEvents={getEvents}
        /> */}

          <VoterDataAdd />

          {/* <div>This is drawer data</div> */}
        </Drawer>
      </div>
    </>
  );
};

export default VoterDataComponent;
