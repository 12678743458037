import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, ConfigProvider, Drawer, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PushListingNotification from "./PushListingNotification";
import PushAddNotification from "./PushAddNotification";
import { errorHandler } from "../errorHandler";

const PushNotificationComp = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [listingData, setListingData] = useState();
  const [stateData, setStateData] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // const [page,setPage]=useState();

  const closeForm = () => {
    // setSelectedEvent(null);
    setIsFormOpen(false);
  };

  const showForm = (value) => {
    console.log(value);
    setSelectedData(value);
    setIsFormOpen(true);
  };

  //   /get-notification/all
  // /bsp/admin/get-voters-list?page_num=${page}&page_size=${pageSize}
  const getAllNotificationFunc = () => {
    axios
      .get(
        `/bsp/admin/get-notification/all?page_num=${page}&page_size=${pageSize}`
      )
      .then((response) => {
        if (response?.data?.success) {
          setListingData(response?.data?.data?.data);
          console.log(response?.data?.data);
          setTotalPage(response?.data?.data?.page_count * 10);
        } else {
          errorHandler(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(typeof totalPage);

  // const getLeaders = () => {
  //     axios
  //       .get("/get-leader/all")
  //       .then((response) => {
  //         // console.log(response.data.data);
  //         setItems(response?.data?.data?.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const getStatesFunc = () => {
    axios
      .get("/bsp/states")
      .then((response) => {
        setStateData(response?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(selectedData);

  useEffect(() => {
    getAllNotificationFunc();
    getStatesFunc();
  }, []);

  useEffect(() => {
    getAllNotificationFunc();
  }, [page]);

  console.log(page);

  return (
    <>
      <div className="event pl-5">
        <div className="head">
          <Typography.Text className="text">All Notification</Typography.Text>
          <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
            <Tooltip title="New Event">
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={<PlusOutlined />}
                onClick={() => showForm(null)}
                type="primary"
                shape="circle"
              />
            </Tooltip>
          </ConfigProvider>
        </div>
        <div>
          <PushListingNotification
            listingData={listingData}
            getAllNotificationFunc={getAllNotificationFunc}
            showForm={showForm}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
            // setPageSize={setPageSize}
          />
        </div>

        <Drawer
          title={
            <p className="icon-p">
              {selectedData == null
                ? "Add Notification"
                : "Update Notification"}
            </p>
          }
          width={720}
          onClose={closeForm}
          open={isFormOpen}
          bodyStyle={{
            background: "#fff",
          }}
          destroyOnClose
        >
          {/* <FormComponent
          event={selectedEvent}
          onClose={closeForm}
          getEvents={getEvents}
        /> */}

          <PushAddNotification
            stateData={stateData}
            getAllNotificationFunc={getAllNotificationFunc}
            selectedData={selectedData}
            closeForm={closeForm}
          />
        </Drawer>
      </div>
    </>
  );
};

export default PushNotificationComp;
