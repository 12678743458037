import React, { useState } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Button, ConfigProvider, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const Tweets = () => {
  const [loading, setLoading] = useState(true);
  const [tweetText, setTweetText] = useState("");

  const handleTweetChange = (event) => {
    setTweetText(event.target.value);
  };

  const handleSubmitTweet = () => {
    console.log("Tweet submitted:", tweetText);
    setTweetText("");
  };

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="twitter pl-5">
      <div className="head">
        <Typography.Text className="text">Recent Tweets</Typography.Text>
      </div>
      <div className="tweet-area">
        <TextArea
          value={tweetText}
          onChange={handleTweetChange}
          placeholder="What's on your mind?"
          maxLength={280}
          style={{
            fontFamily: "Poppins, sans-serif",
            height: "100px",
            resize: "none",
            borderRadius: "14px",
            border: "none",
            overflow: "hidden",
            padding: "7px 12px",
            fontSize: "16px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          }}
        />
        {/* {tweetText && ( */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1DA1F2",
              borderRadius: "14px",
            },
          }}
        >
          <Button
            icon={<FontAwesomeIcon icon={faXTwitter} />}
            htmlType="submit"
            size="large"
            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
            onClick={handleSubmitTweet}
            style={{
              width: "18%",
              margin: "0 auto",
              right: "0",
            }}
            disabled={!tweetText}
          >
            Tweet
          </Button>
        </ConfigProvider>
        {/* )} */}
      </div>
      <div>
        {loading && (
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "280px",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="AnandAkash_BSP"
          onLoad={handleLoad}
        />
      </div>
    </div>
  );
};

export default Tweets;
