import { Typography } from "antd";
import React from "react";

export const Live = () => {
  return (
    <div className="live pl-5">
      <div className="head">
        <Typography.Text className="text">
          Live News from BSP Social Media
        </Typography.Text>
      </div>
      <div className="wrapper">
        <div className="iframe">
          <iframe
            title="facebook"
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02tTpsKKXYVBsfAXc7L3UEZTKDav6NfYsyCnipL1MoBBDNyUEyJQhnK6tMPRAg6hPil%26id%3D100088669653759&show_text=true&width=500"
            width="100%"
            height="96%"
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
          <p>Facebook</p>
        </div>
        <div className="iframe">
          <iframe
            id="youtubePlayer"
            height="96%"
            width="100%"
            src="https://www.youtube.com/embed/jfE_X74mNLg?si=K6xxmDtcGVVgXOTX"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <p>Youtube</p>
        </div>
        <div className="iframe">
          <iframe
            title="Instagram"
            src="https://www.instagram.com/p/Cv8xSysqapv/embed"
            width="100%"
            height="97%"
            frameborder="0"
            scrolling="no"
            allowtransparency="true"
            allowfullscreen=""
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          ></iframe>
          <p>Instagram</p>
        </div>
      </div>
    </div>
  );
};
