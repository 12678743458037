import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Space,
  Select,
  Card,
  Row,
  Col,
  Modal,
  notification,
  Form,
  Checkbox,
  Tag,
  Divider,
  Typography,
} from "antd";
import { PiFlowerLotus } from "react-icons/pi";
import axios from "axios";
import Title from "antd/es/typography/Title";
import { downloadBlob } from "./Downloader";
import { FilterOutlined } from "@ant-design/icons";
import { MdLocationCity } from "react-icons/md";
import { TbAssembly, TbFileExport } from "react-icons/tb";
import { GiElephant, GiLotusFlower, GiPalm } from "react-icons/gi";
import { FaBicycle } from "react-icons/fa";
import { FaBroom, FaHand } from "react-icons/fa6";
import { DigitFormate } from "../lib/utils";

const cardStyle = {
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
  height: "100%",
};

const Report = () => {
  const [open, setOpen] = useState(false);
  const [totals, setTotals] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loksabha, setLoksabha] = useState([]);
  const [defaultLok, setDefaultLok] = useState("Agra");
  const [defaultAsem, setDefaultAsem] = useState();
  const [assembly, setAssembly] = useState([]);
  const [position, setPosition] = useState("");
  const [report_type, setReport_Type] = useState("report");
  const [party, setParty] = useState("");
  const [partyData, setPartyData] = useState(null);
  const [positionData, setPositionData] = useState(null);
  const [defaultState, setDefaultState] = useState("Uttar Pradesh");

  const [AssemblyData, setAssemblyData] = useState(null);
  const [states, setStates] = useState(null);

  const [totalVoters, setTotalVoters] = useState("");

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  const LockSabha = async () => {
    try {
      console.log("Assembly Call");
      const { data } = await axios.get(
        `/bsp/reports/voters_report/dropdown?dropdown_type=assembly_constituency&loksabha=${defaultLok}`
      );
      setAssembly(data?.data?.data);

      console.log("Assembly Data", data?.data);
      setTotalVoters(data?.data?.total_voters);
      setDefaultAsem(data?.data?.data[0]?.ac_name);
      setAssemblyData(data?.data?.data[0]);
    } catch (err) {}
  };
  const Assembly = async () => {
    try {
      const { data } = await axios.get(
        "/bsp/reports/voters_report/dropdown?dropdown_type=state"
      );

      setStates(data?.data);
    } catch (err) {}
  };

  const fetchdata = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/bsp/reports/voters_report`, {
        params: {
          state: defaultState,
          report_type: report_type,
          position: position,
          party: party,
          loksabha: defaultLok,
          ac_name: defaultAsem,
          export: false,
        },
      });

      if (data?.success) {
        setLoading(false);
        setData(data?.data);

        let calculatedTotals = {};

        data?.data.forEach((record) => {
          // Iterate over each key in the record
          Object.keys(record).forEach((key) => {
            // Check if the value is numeric
            const value = parseInt(record[key]);
            if (!isNaN(value)) {
              // If the key already exists in calculatedTotals, add the value to it
              // Otherwise, initialize it with the value
              calculatedTotals[key] = (calculatedTotals[key] || 0) + value;
            }
          });
        });

        setTotals(calculatedTotals); // Set the totals state
      } else {
        // Handle error or show notification
      }
      console.log("Report", data);
    } catch (err) {
      console.log(err);
      setLoading(false); // Ensure loading state is set to false even if there's an error
    }
  };

  const columns =
    data.length > 0
      ? Object.keys(data[0]).map((key) => ({
          title:
            key === "b_no"
              ? "Booth No"
              : key === "total_voters"
              ? "Total Votes"
              : key === "valid_voters"
              ? "Valid Votes"
              : key === "votes_share"
              ? "Votes Share"
              : key, // fallback to the key itself if no specific title is defined
          dataIndex: key,
          key: key,
        }))
      : [];

  const Party = async () => {
    try {
      const { data } = await axios.get(`/bsp/drop-downs`);

      setPartyData(data?.data?.filter((item) => item?.field === "PARTY"));
      setPositionData(data?.data?.filter((item) => item?.field === "POSITION"));
    } catch (err) {
      console.log(err);
    }
  };

  const State = async () => {
    try {
      const { data } = await axios.get(
        `/bsp/reports/voters_report/dropdown?dropdown_type=loksabha&state=${defaultState}`
      );

      setLoksabha(data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    State();
  }, [defaultState]);

  useEffect(() => {
    LockSabha();
  }, [defaultLok]);

  useEffect(() => {
    fetchdata();
    Assembly();
    Party();
    console.log("changed", defaultAsem, defaultLok, partyData);
  }, [defaultAsem, party, position]);

  // Render the table only when data is available
  const table = data.length >= 0 && (
    <Table
      style={{
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
      }}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      loading={loading}
      scroll={{
        x: 1300,
      }}
      bordered={true}
      columns={columns}
      dataSource={data}
      onChange={handleChange}
      summary={(pageData) => {
        // Initialize an object to store totals for each numeric column except "b_no"
        const totals = {};

        // Initialize total for "b_no" separately
        let totalBNo = 0;

        // Iterate over each record in the current page data
        pageData.forEach((record) => {
          // Iterate over each key in the record
          Object.keys(record).forEach((key) => {
            // Check if the value is numeric
            const value = parseInt(record[key]);
            if (!isNaN(value)) {
              // If the key is "b_no", add it to totalBNo
              // Otherwise, add it to totals object
              if (key === "b_no") {
                totalBNo += value;
              } else {
                // If the key already exists in totals, add the value to it
                // Otherwise, initialize it with the value
                totals[key] = (totals[key] || 0) + value;
              }
            }
          });
        });

        // Create a summary row using the calculated totals
        return (
          <Table.Summary.Row
            style={{
              background: "",
              color: "",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            <Table.Summary.Cell>Total</Table.Summary.Cell>
            {Object.keys(totals).map((key) => (
              <Table.Summary.Cell key={key}>
                {DigitFormate(totals[key])}
              </Table.Summary.Cell>
            ))}
          </Table.Summary.Row>
        );
      }}
    />
  );

  const ExportData = () => {
    axios
      .get(`/bsp/reports/voters_report`, {
        params: {
          state: defaultState,
          report_type: report_type,
          position: position,
          party: party,
          loksabha: defaultLok,
          ac_name: defaultAsem,
          export: true,
        },
      })
      .then((response) => {
        console.log(response?.data);
        downloadBlob(response?.data, `${defaultLok}_${defaultAsem}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setReport_Type("position");
    setParty(values.Party_name);
    setPosition(values.position);
    setFiltered(true);
    setOpen(false);
  };

  const resetFun = () => {
    setReport_Type("report");
    setParty("");
    setPosition("");
    setFiltered(false);
    setOpen(false);
    fetchdata();
  };
  return (
    <div style={{ padding: "10px" }}>
      <Space
        style={{
          marginBottom: 16,
        }}
        align="end"
      >
        <div>
          <p>State</p>
          <Select
            title="State"
            style={{
              width: 200,
            }}
            onChange={(value) => setDefaultState(value)}
            placeholder="State"
            defaultValue={defaultState}
          >
            {states &&
              states.length > 0 &&
              states.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div>
          <p>Loksabha</p>
          <Select
            title="Loksabha"
            style={{
              width: 200,
            }}
            onChange={(value) => setDefaultLok(value)}
            placeholder="locksabha"
            defaultValue={defaultLok}
          >
            {loksabha &&
              loksabha.length > 0 &&
              loksabha.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div>
          <p>Assembly</p>
          <Select
            style={{
              width: 200,
            }}
            onChange={(value) => {
              setDefaultAsem(value);
              const selectedAssemblyData = assembly.find(
                (item) => item.ac_name === value
              );
              setAssemblyData(selectedAssemblyData);
            }}
            placeholder="Assembly"
            //   defaultValue={defaultAsem}
            value={defaultAsem}
          >
            {assembly &&
              assembly.length > 0 &&
              assembly.map((e) => (
                <Select.Option value={e.ac_name} key={e.ac_name}>
                  {e.ac_name}-{e.ac_no}
                </Select.Option>
              ))}
          </Select>
        </div>
        <Space.Compact>
          <Button icon={<FilterOutlined />} onClick={() => setOpen(true)} />{" "}
          {filtered && (
            <Button onClick={resetFun} type="primary">
              Clear
            </Button>
          )}
        </Space.Compact>

        <Modal
          title="Positioning Report"
          footer={null}
          closable
          onCancel={() => setOpen(false)}
          open={open}
        >
          <Form onFinish={onFinish}>
            {partyData && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select a party!",
                  },
                ]}
                name="Party_name"
                label="Party"
              >
                <Select
                  style={{
                    width: 200,
                  }}
                  placeholder="Party"
                  //   defaultValue={defaultAsem}
                >
                  {partyData.length > 0 &&
                    partyData[0]?.value.map((e) => (
                      <Select.Option value={e} key={e}>
                        {e}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            {positionData && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select a position!",
                  },
                ]}
                name="position"
                label="Postion"
              >
                <Select
                  style={{
                    width: 200,
                  }}
                  placeholder="Party"
                  //   defaultValue={defaultAsem}
                >
                  {positionData.length > 0 &&
                    positionData[0]?.value.map((e) => (
                      <Select.Option value={e} key={e}>
                        {e}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Space align="start" size={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  View Report
                </Button>
              </Form.Item>
              {filtered && (
                <Button onClick={resetFun} danger>
                  Clear
                </Button>
              )}
            </Space>
          </Form>
        </Modal>
        <Button icon={<TbFileExport />} type="primary" onClick={ExportData}>
          Export
        </Button>
      </Space>
      <Row style={{ marginBottom: "20px" }} gutter={12}>
        <Col>
          <Card style={cardStyle} bordered>
            <Space align="center" size={4}>
              <MdLocationCity size={20} />
              {defaultLok}
            </Space>
            <br />
            Total Voters: {DigitFormate(totalVoters)}
          </Card>
        </Col>
        <Col>
          <Card style={cardStyle}>
            <Space align="center" size={4}>
              <TbAssembly size={20} />
              {defaultAsem}
            </Space>
            <br />
            Total Voters:{" "}
            {AssemblyData && DigitFormate(AssemblyData.total_votes)}
            <br />
            Total Booths: {data && data.length}
          </Card>
        </Col>

        <Col>
          {partyData && (
            <PositionChart
              party={party}
              position={position}
              totals={totals}
              partyData={partyData}
            />
          )}
        </Col>
      </Row>

      {/* {party && (
        <Title color="blue" level={2}>
          {party}-{position} POSITION
        </Title>
      )} */}
      {table}
    </div>
  );
};

export default Report;

const PositionChart = ({ partyData, totals, position, party }) => {
  const parties = Object.keys(totals).filter(
    (key) =>
      key !== "b_no" &&
      key !== "Total Voters" &&
      key !== "Valid Voters" &&
      key !== "NOTA"
  );
  const partyVotes = parties.map((party) => ({ party, votes: totals[party] }));

  // Sort parties by votes in descending order
  partyVotes.sort((a, b) => b.votes - a.votes);

  // Assign positions to parties based on their ranking
  const partyPositions = {};
  partyVotes.forEach((partyData, index) => {
    partyPositions[partyData.party] = index + 1;
  });

  const TotalCard = () => {
    return (
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
        <Tag>
          <p>
            Votes: {DigitFormate(totals.total_voters || totals["Total Voters"])}
          </p>
          <p>
            Valid votes:{" "}
            {DigitFormate(totals.valid_voters || totals["Valid Voters"])}{" "}
          </p>
          {totals.votes_share && (
            <p>Vote share: {DigitFormate(totals.votes_share)}</p>
          )}
        </Tag>
      </div>
    );
  };
  console.log("Partry positions", JSON.stringify(totals));
  return (
    <>
      {!position ? (
        <Card style={{ ...cardStyle, width: "300px" }}>
          {partyData &&
            partyData.length > 0 &&
            partyData[0]?.value.map((e) => (
              <div
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBlockStart: "2px",
                }}
                key={e}
              >
                <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                  {e === "BSP" && <GiElephant />}
                  {e === "BJP" && <PiFlowerLotus />}
                  {e === "SP" && <FaBicycle />}
                  {e === "AAP" && <FaBroom />}
                  {e === "INC" && <FaHand />}
                  {e}
                </div>
                {e === "BSP" && (
                  <div>
                    {DigitFormate(totals.BSP)} <Divider type="vertical" />{" "}
                    <PositionIndex e={partyPositions.BSP} />
                  </div>
                )}
                {e === "BJP" && (
                  <div>
                    {DigitFormate(totals.BJP)} <Divider type="vertical" />{" "}
                    <PositionIndex e={partyPositions.BJP} />
                  </div>
                )}
                {e === "SP" && (
                  <div>
                    {DigitFormate(totals.SP)} <Divider type="vertical" />{" "}
                    <PositionIndex e={partyPositions.SP} />
                  </div>
                )}
                {e === "AAP" && (
                  <div>
                    {DigitFormate(totals.AAP)} <Divider type="vertical" />{" "}
                    <PositionIndex e={partyPositions.AAP} />
                  </div>
                )}
                {e === "INC" && (
                  <div>
                    {DigitFormate(totals.INC)} <Divider type="vertical" />{" "}
                    <PositionIndex e={partyPositions.INC} />
                  </div>
                )}
              </div>
            ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Title level={3}>Total</Title> {TotalCard()}
          </div>
        </Card>
      ) : (
        <Card style={{ ...cardStyle, width: "200px", position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Title level={4}>{party}</Title>
            {party === "BSP" && <GiElephant size={30} />}
            {party === "BJP" && <PiFlowerLotus size={30} />}
            {party === "SP" && <FaBicycle size={30} />}
            {party === "AAP" && <FaBroom size={30} />}
            {party === "INC" && <FaHand size={30} />}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title
              level={1}
              style={{
                background:
                  party === "BSP"
                    ? "darkblue"
                    : party === "BJP"
                    ? "red"
                    : party === "SP"
                    ? "orange"
                    : party === "AAP"
                    ? "grey"
                    : "",
                backgroundImage:
                  party === "INC" && `linear-gradient(180deg,red, gray, green)`,
                padding: "5px",
                width: "90px",
                height: "90px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                borderRadius: "100px",
                color: "white",
              }}
            >
              {position}
            </Title>
          </div>
          {TotalCard()}
        </Card>
      )}
    </>
  );
};

const PositionIndex = ({ e }) => {
  return <Tag color="gold">{e || "N"}</Tag>;
};
