import moment from "moment";
import { Space, Table, Tag } from "antd";

import React, { useState } from "react";
import {
  Button,
  Carousel,
  ConfigProvider,
  Modal,
  Pagination,
  Spin,
  Tooltip,
} from "antd";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventDetails = ({ events, onEdit, getEvents, loader,setPage,page,totalPage }) => {
  console.log(totalPage);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [showEvent, setShowEvent] = useState(null);
  // const [filteredData, setFilteredData] = useState([]);

  // const startDate = moment().format("YYYY-MM-DD");

  // const id = "65013135c62dc719af31de18";

  // useEffect(() => {
  //   axios
  //     .get(`/get-event/${id}`)
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // });

  console.log(loader);

  const showDeleteModal = (event) => {
    setShowEvent(event.event_name);
    setDeleteEvent(event._id);
    setIsDeleteModalVisible(true);
  };

  const columns = [
    {
      title: "S.No.",
      key: "name",
      render: (item) => <div>{item?.index + 1}</div>,
    },
    {
      title: "Image",
      key: "age",
      render: (item) => (
        <div style={{ display: "flex" }}>
          {
            item?.image_url?.map((images) => (
              <div style={{ width: "12rem", objectFit: "cover" }}>
                {" "}
                <img style={{ width: "100%" }} src={images} alt="ssa" />
              </div>
            ))
            //
          }
        </div>
      ),
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Speaker",
      dataIndex: "event_speakers",
      key: "event_speakers",
    },
    {
      title: "Chief Guest",
      dataIndex: "guest_list",
      key: "guest_list",
    },
    {
      title: "Date",
      key: "start_date",
      render: (item) => (
        <div style={{ display: "flex" }}>
          {moment(item.start_date).format("MMM Do")} -{" "}
          {moment(item.end_date).format("MMM Do, YYYY")}
        </div>
      ),
    },
    {
      title: "Time",
      key: "start_time",
      render: (item) => (
        <div style={{ display: "flex", width: "5rem" }}>
          {moment(item.start_time, "HH:mm").format("hh:mm A")} -{" "}
          {moment(item.end_time, "HH:mm").format("hh:mm A")}
        </div>
      ),
    },
    {
      title: "Mode of Event",
      dataIndex: "event_type",
      key: "event_type",
    },
    {
      title: "Event Link",
      key: "guest_list",
      render: (item) => (
        <div>
          {" "}
          {item?.link !== null && (
            <Link to={item?.link} target="_blank">
              Go to Link
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Actions",
      key: "action",
      render: (item) => (
        <div style={{ display: "flex" }} className="actions">
          <Tooltip title="Update">
            <Button
              shape="circle"
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={() => onEdit(item)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              style={{ marginLeft: "0.5     rem" }}
              shape="circle"
              icon={<FontAwesomeIcon icon={faTrash} />}
              onClick={() => showDeleteModal(item)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const images = [
    // {
    //   original: "https://picsum.photos/id/1018/1000/600/",
    //   thumbnail: "https://picsum.photos/id/1018/250/150/",
    // },
    // {
    //   original: "https://picsum.photos/id/1015/1000/600/",
    //   thumbnail: "https://picsum.photos/id/1015/250/150/",
    // },
    // {
    //   original: "https://picsum.photos/id/1019/1000/600/",
    //   thumbnail: "https://picsum.photos/id/1019/250/150/",
    // },
  ];

  const handleDeleteConfirm = () => {
    if (deleteEvent) {
      axios
        .delete(`/bsp/admin/delete-event/${deleteEvent}`)
        .then((response) => {
          // console.log(response.data);
          if (response?.data?.success) {
            getEvents();
            setIsDeleteModalVisible(false);
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

  const contentStyle = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  console.log(events?.length);

  // const imagesStatic = [
  //   {
  //     original: "https://picsum.photos/id/1024/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1024/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1025/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1025/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1026/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1026/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1027/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1027/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1029/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1029/250/150/",
  //   },
  // ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      style={{ marginBottom: "5rem", paddingBottom: "2rem" }}
      className="details w-full  relative "
    >
      <Table dataSource={events} columns={columns} pagination={false} />
      {/* <table className="custom-table">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>S.No.</th>
            <th>Image</th>
            <th>Event Name</th>
            <th>Speaker</th>
            <th>Chief Guest</th>
            <th>Date</th>
            <th>Time</th>
            <th>Mode of Event</th>
            <th>Event Link</th>
            <th style={{ width: "8%" }}>Location</th>
            <th style={{ width: "8%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events?.data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td style={{ display: "flex" }}> */}
      {/*    
 

                  {item?.image_url?.map((images) => (
                    <img src={images} alt="ssa" />
                  ))}

                  {/* {item.images.map(image => <img src={image.url} alt="ssa" />)} */}
      {/* <Slider {...settings}>
        {item?.image_url?.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`slide-${index}`} />
          </div>
        ))}
      </Slider> */}
      {/* <Carousel autoplay={false}
                  dots={false}>
                    {item?.image_url?.map((value,index)=>{
                      <div>
                        <img src={value} width="50px" height="50px" alt="no image"/>
                      </div>
                    })}
      
      </Carousel> */}
      {/* </td>
                <td>{item.event_name}</td>
                <td>{item.event_speakers}</td>
                <td>{item.guest_list}</td>
                <td>
                  {moment(item.start_date).format("MMM Do")} -{" "}
                  {moment(item.end_date).format("MMM Do, YYYY")}
                </td>
                <td>
                  {moment(item.start_time, "HH:mm").format("hh:mm A")} -{" "}
                  {moment(item.end_time, "HH:mm").format("hh:mm A")}
                </td>
                <td>{item.event_type}</td> */}
      {/* <td>{item?.link}</td> */}
      {/* <td>
                  {item?.link !== null && (
                    <Link to={item?.link} target="_blank">
                      Go to Link
                    </Link>
                  )}
                </td>
                <td>{item.location}</td>
                <td>
                  <div className="actions">
                    <Tooltip title="Update">
                      <Button
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => showDeleteModal(item)}
                      />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })} */}
      {/* {loader && <Spin />}
        </tbody> */}
      {/* </table> */}
      <div>
        <Pagination
          style={{ float: "right" }}
          className="pagination-core"
          current={page}
          showQuickJumper
          defaultCurrent={1}
          total={totalPage}
          // total={totalPage}
          showSizeChanger={false}
          onChange={(pageNumber, pageSize) =>
            onChangePagination(pageNumber, pageSize)
          }
        />
      </div>
      {events?.length == 0 && (
        <div className="zero-events-text">No events Found</div>
      )}
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                You're going to delete the "{showEvent}" event. Are your sure?
              </span>
            </p>
          }
          open={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onOk={handleDeleteConfirm}
          okButtonProps={{
            type: "default",
          }}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <div className="pagination-style"></div>
      {/* <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div> */}
    </div>
  );
};

export default EventDetails;
