import { Button, Carousel, ConfigProvider, Form, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./login.css";
import { Navbar } from "../components/Navbar";
import { Footers } from "../components/Footer";
import Dashboard from "../components/Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Image1 from "../images/loginpage1.jpg";
import Image2 from "../images/loginpage2.jpg";
import Cookies from "js-cookie";
import Image3 from "../images/loginpage3.jpg";
import { useNavigate } from "react-router-dom";

const Container = () => {
  return (
    <div className="merger">
      <div className="merge">
        <Carousel
          autoplay
          effect="fade"
          style={{
            borderRadius: "24px",
            overflow: "hidden",
          }}
        >
          {/* <div className="content-style">
            <img
              src="https://c4.wallpaperflare.com/wallpaper/370/228/846/baby-elephant-elephant-amazing-wildlife-wallpaper-preview.jpg"
              alt=""
            />
          </div>
          <div className="content-style">
            <img
              src="https://images.unsplash.com/photo-1564760055775-d63b17a55c44?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8NXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
              alt=""
            />
          </div> */}
          <div className="content-style">
            <img src={Image3} alt="" />
          </div>
          <div className="content-style">
            <img src={Image2} alt="" />
          </div>
          <div className="content-style">
            <img src={Image1} alt="" />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [isMobileValid, setMobileValid] = useState(false);
  const [isOTPValid, setOTPValid] = useState(false);
  const [clicked, isClicked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [otpRequested, setOTPRequested] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  // const [isValid, setIsValid] = useState(false);
  const [apiResponse, setAPIResponse] = useState(false);
  const [msg, setMsg] = useState("");

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    setMobileValid(value.length === 10 && /^\d+$/.test(value));
  };

  const handleOTPchange = (e) => {
    const value = e.target.value;
    setOTP(value);
    setOTPValid(value.length === 4 && /^\d+$/.test(value));
  };

  const handleGetOtp = () => {
    if (mobile) {
      axios
        .post(
          "/bsp/authentication?type=ADMIN&login_type=MOBILE",
          {
            mobile: mobile,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response?.data?.success) {
            setOTPRequested(true);
            setResendCountdown(30);
            const countdownInterval = setInterval(() => {
              setResendCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);

            setTimeout(() => {
              setOTPRequested(false);
              clearInterval(countdownInterval);
            }, 30000);
            isClicked(true);
            setAPIResponse(true);
          }
          setMsg(`${response?.data?.message}`);
          setTimeout(() => {
            setMsg("");
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(isLoggedIn);

  const OTPverify = () => {
    axios
      .post(
        "/bsp/authentication/otp-verify",
        {
          mobile: mobile,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp?.data?.success) {
          Cookies.set("sessions", resp?.data?.data?.session, { expires: 1/24 });
          localStorage.setItem("user", JSON.stringify(resp?.data?.data));
          setIsLoggedIn(true);
          navigate("/");
          console.log(resp);
        }
        setAPIResponse(false);
        setMsg(`${resp?.data?.message}`);
        setTimeout(() => {
          setMsg("");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    axios
      .post("/bsp/authentication/logout")
      .then((resp) => {
        // console.log(resp);
        if (resp?.data?.success) {
          form.resetFields();
          Cookies.remove("sessions");
          localStorage.removeItem("user");
          isClicked(false);
          setMobile("");
          setMobileValid(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (Cookies.get("sessions")) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="contain">
      <div style={{ height: "10%" }}>
        <Navbar isLoggedIn={isLoggedIn} onClick={handleLogout} />
      </div>
      <div className="form" style={{ display: "flex", flexDirection: "row" }}>
        {/* <Container /> */}
        <div className="form-contain">
          <div className="container">
            <div className="headers">
              <h2>
                <span>Login </span>to your Account
              </h2>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#1d3e9b",
                  borderRadius: "10px",
                },
              }}
            >
              <Form name="login" form={form} layout="vertical">
                {clicked ? (
                  <div>
                    <Form.Item>
                      <Button
                        icon={
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            onClick={() => {
                              isClicked(false);
                              // console.log(clicked);
                            }}
                          />
                        }
                      ></Button>
                    </Form.Item>
                    <Form.Item
                      name="otp"
                      label={<p className="label-text">OTP</p>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter correct OTP",
                        },
                        {
                          max: 4,
                          min: 4,
                          message: "Should be 4 digits",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter 4 digit OTP"
                        value={otp}
                        maxLength={4}
                        onChange={handleOTPchange}
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Form.Item>
                        {isOTPValid && (
                          <Button
                            htmlType="submit"
                            type="primary"
                            onClick={OTPverify}
                            // disabled={!isOTPValid}
                          >
                            Login
                          </Button>
                        )}
                      </Form.Item>
                      {/* <Form.Item>
                        <button
                          className="text-btn"
                          disabled={!isMobileValid || otpRequested}
                          onClick={handleGetOtp}
                        >
                          {otpRequested
                            ? `Resend OTP in ${resendCountdown}s`
                            : "Resend OTP"}
                        </button>
                      </Form.Item> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <Form.Item
                      name="mobile"
                      label={<p className="label-text">Mobile Number</p>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Mobile Number",
                        },
                        {
                          len: 10,
                          message: "Mobile Number should be 10 digits",
                        },
                        // {
                        //   pattern: /^[6-9]\d{9}$/,
                        //   message: "Must start with 6, 7, 8, or 9",
                        // },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter your Mobile Number"
                        value={mobile}
                        maxLength={10}
                        onChange={handleMobileChange}
                      />
                    </Form.Item>
                    <Form.Item>
                      {isMobileValid && (
                        <Button
                          id="otp-btn"
                          style={{ marginTop: "10px" }}
                          onClick={handleGetOtp}
                          // disabled={!isMobileValid}
                        >
                          Get OTP
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                )}
                {msg && (
                  <p className={apiResponse ? "msg success" : "msg"}>{msg}</p>
                )}
              </Form>
            </ConfigProvider>
          </div>
        </div>
      </div>
      <Footers />
    </div>
  );
};
