import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const DateComponent = () => {
  return (
    <Tooltip title={moment().format("dddd, MMMM D, YYYY")}>
      <span>{moment().format("MMM Do, YYYY")}</span>
    </Tooltip>
  );
};

export const Clock = () => {
  const [time, setTime] = useState({
    minutes: new Date().getMinutes(),
    hours: new Date().getHours(),
    seconds: new Date().getSeconds(),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setTime({
        minutes: date.getMinutes(),
        hours: date.getHours(),
        seconds: date.getSeconds(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const convertToTwoDigit = (number) => {
    return number.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
  };
  return (
    <Tooltip title={moment().format("h:mm:ss a")}>
      <div className="clock">
        <div className="span">
          <span>
            {time.hours > 12
              ? convertToTwoDigit(time.hours - 12)
              : convertToTwoDigit(time.hours)}
          </span>
          :<span>{convertToTwoDigit(time.minutes)}</span>
          {/* <span>{convertToTwoDigit(time.seconds)}</span> */}
          <span>{time.hours >= 12 ? "PM" : "AM"}</span>
        </div>
      </div>
    </Tooltip>
  );
};
