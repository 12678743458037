import { Card, Col, Dropdown, Progress, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { format } from "date-fns";
import { TbLocation } from "react-icons/tb";
import { FaLocationArrow } from "react-icons/fa";
import { TiLocationOutline } from "react-icons/ti";
import ModalCreateTask from "./ModalCreateTask";

function TaskCard({ data, id }) {
  console.log("taskCard", data);

  const per = data?.count / 100;

  const percent = data?.task_count ? data?.task_count / per : 0;

  const [open, setOpen] = useState(false);
  const items = [
    {
      label: (
        <div
          onClick={(e) => {
            console.log("edit", e);
            setOpen(true);
          }}
        >
          Edit
        </div>
      ),
      key: "0",
    },
  ];
  return (
    <>
      <Card
        bordered
        size="small"
        title={
          <>
            {data?.is_high_priority && (
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <div
                  style={{
                    width: 8,
                    height: 8,
                    backgroundColor: "red",
                    borderRadius: 99,
                  }}
                ></div>
                <span>High Priority</span>
              </div>
            )}
          </>
        }
        headStyle={{ border: 0 }}
        extra={
          <Dropdown
            overlayStyle={{ width: "100px" }}
            placement=""
            menu={{ items }}
            trigger={["click"]}
          >
            <BsThreeDots size={20} />
          </Dropdown>
        }
        style={{ width: "100%", padding: 16, borderColor: "#D3D3D3" }}
      >
        <Typography.Title level={3}>{data?.name}</Typography.Title>
        <Typography.Paragraph>{data?.description}</Typography.Paragraph>
        <Progress percent={percent} />
        <Row justify="space-between" style={{ marginTop: 16 }}>
          <Col>{format(data?.start_date, "dd-MMMM-yyyy")}</Col>
          <Col>{format(data?.end_date, "dd-MMMM-yyyy")}</Col>
        </Row>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          <Space size={2}>
            <TiLocationOutline />{" "}
            <div>
              {data?.vidhansabha}, {data?.state}
            </div>
          </Space>
        </div>
      </Card>
      <ModalCreateTask id={id} open={open} setOpen={setOpen} edit={data} />
    </>
  );
}

export default TaskCard;
