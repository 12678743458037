import React from "react";
import { Footer } from "antd/es/layout/layout";
import { Clock, DateComponent } from "./Clock";

const Para = () => {
  return
  (
    <>
  <p >© Bahujan Samaj Party</p>;
    
    </>
  )
};

export const Footers = () => {
  return (
    <div className="footer">
      <Footer className="foot">
        {/* <DateComponent /> */}
        <Para />
        {/* <Clock /> */}
      </Footer>
    </div>
  );
};
