import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Modal,
  Typography,
  Col,
  Row,
  Pagination,
  message,
  Popconfirm,
  Input,
  Form,
  Select,
  DatePicker,
} from "antd";
import axios from "axios";

/**
 * @typedef Feedback {import('./feedback.d.ts')}
 */

import {
  faPen,
  faEye,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { PlusOutlined } from "@ant-design/icons";
import { FaEye } from "react-icons/fa";
import { MdOutlineReply } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorHandler } from "../errorHandler";
import { useLocation } from "react-router-dom";

const WorkersMsgComponent = () => {
  const [viewMessage, setViewMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [feedbackData, setFeedbackData] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [replyMessage, setReplyMessage] = useState(false);
  const [stateData, setStateData] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [districtData, setDistrictData] = useState();
  const [form] = Form.useForm();
  const [vidhanSabha, setVidhanSabha] = useState();
  const [profession, setProfessionData] = useState();
  const [totalPage, setTotalPage] = useState(false);
  const [tagMember, setTagMember] = useState();
  const [ageData, setAgeData] = useState();
  const [selectedProfession, setSelectedProfession] = useState();
  const [selctedTagMember, setSelectedTagMember] = useState();
  const location = useLocation();
  console.log(location?.pathname);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
  ];

  const openViewMessage = async (data) => {
    setFeedback(data);

    setViewMessage(true);
  };

  const replyMessaegFunc = () => {
    setReplyMessage(true);
  };

  console.log(page);

  const getFeedback = async (pageNumber, pageSize) => {
    const { data } = await axios.get("/bsp/admin/get-feedback/all", {
      params: {
        page_size: pageSize,
        page_num: pageNumber,
        // state:value?.state,
        // district:value?.district,
        // // age:ageFilter,
        // profession:value?.profession?.value,
        // tag_to:value?.tag_member?.value
      },
    });
    console.log(data.data, "checking data of feedback all ");
    if (data.success) {
      // message.success(data.message);
      setFeedbackData(data.data.data);
      setTotalPage(data.data.page_count * 10);
    } else {
      message.error(data.message);
    }
  };

  // const onFinish=async(value)=>{
  // const { data } = await axios.get("/bsp/admin/get-feedback/all", {
  //   params: {
  //     page_size: pageSize,
  //     page_num: pageNumber,
  //     state:value?.state,
  //     district:value?.district,
  //     profession:value?.profession?.value,
  //     tag_to:value?.tag_member?.value
  //   },
  // });
  // console.log(data.data, "checking data of feedback all ");
  // if (data.success) {
  //   // message.success(data.message);
  //   setFeedbackData(data.data.data);
  //   setTotalPage(data.data.page_count * 10);
  // } else {
  //   message.error(data.message);
  // }
  // }

  const onChangePagination = (pageNumber, pageSize) => {
    getFeedback(pageNumber, pageSize);
    setPage(pageNumber);
  };

  const handleDeleteConfrim = async (item) => {
    console.log(item);
    const { data } = await axios.delete(
      `/bsp/admin/delete-feedback/${item?._id}`
    );
    console.log(data, "checking delte data");
    if (data.success) {
      message.success(data.message);
      getFeedback(page, 10);
    } else {
      errorHandler(data?.message);
      message.error(data.message);
    }
  };

  const getStatesFunc = () => {
    axios
      .get("/bsp/states")
      .then((response) => {
        setStateData(response?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDropdowns = () => {
    axios
      .get("/bsp/drop-downs")
      .then((response) => {
        console.log(response?.data);
        // setListingData(response?.data?.data?.data)
        response?.data?.data?.map((item, index) => {
          if (item?.field === "PROFESSIONS") {
            setProfessionData(item?.value);
          }
          if (item?.field === "TAG_TO") {
            setTagMember(item?.value);
          }
        });
        // setDropDownData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFeedback(1, 10);
    getStatesFunc();
    getAllDropdowns();
    setPage(1);
    return () => {
      setSelectedState(null);
      console.log("test clean");
    };
  }, [location?.pathname]);

  console.log(page);
  console.log(stateData);

  const handleStateChange = (state) => {
    setSelectedState(state);

    axios
      .get(`/bsp/state_districts?state=${state}`)
      .then((response) => {
        setDistrictData(response?.data?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // /bsp/state_districts
  };

  const handleDistrictChange = (district) => {
    // /bsp/district_constitutions?district=Mathura
    setSelectedDistrict(district);
    axios
      .get(
        `/bsp/district_constitutions?state=${selectedState}&district=${district}`
      )
      .then((response) => {
        console.log(response?.data?.data);
        setVidhanSabha(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterFunc = async () => {
    console.log(ageData);
    const integerAge = parseInt(ageData);
    console.log(typeof integerAge);
    const { data } = await axios.get("/bsp/admin/get-feedback/all", {
      params: {
        page_size: 10,
        page_num: page,
        state: selectedState,
        district: selectedDistrict,
        // age:integerAge,
        profession: selectedProfession,
        tag_to: selctedTagMember,
      },
    });
    console.log(data.data, "checking data of feedback all ");
    if (data.success) {
      // message.success(data.message);
      setFeedbackData(data.data.data);
      setTotalPage(data.data.page_count * 10);
    } else {
      message.error(data.message);
    }
  };

  console.log(selectedState);
  // console.log(selectedDistrict);
  // console.log(ageData);
  // console.log(selectedProfession);
  // console.log(selctedTagMember);

  const changeInteger = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      <div className="event pl-5">
        <div className="head">
          <Typography.Text className="text">Workers Feedback</Typography.Text>
          <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
            {/* <Tooltip title="New Event">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />}
            //   onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip> */}
          </ConfigProvider>
        </div>
        {/* </div> */}
        {/* <div> */}
        {/* Tabular Form */}
        {/* 
        <div className="fill">
        <div className="form">
          <div className="form-container voter-Data"> */}
        {/* <Form
              className="formSubmit"
              name="submit"
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
            <Row gutter={[16]}>
              <Col flex={6}>
                <Form.Item
                  name={"state"}
                  label={<p className="label-text">Select State</p>}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please set the end date",
                  //   },
                  // ]}
                >
                  <Select
                      placeholder="Select State"
                      // value={selectedState}
                      onChange={handleStateChange}

                    >
                      {stateData?.data?.map((state) => (
                        <Select.Option key={state._id} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>


              <Col flex={6}>
                <Form.Item
                  name={"district"}
                  label={<p className="label-text">Select District</p>}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please set the end time",
                  //   },
                  // ]}
                >
                  <Select
                      placeholder="Select District"
                      // value={selectedDistrict}
                      onChange={handleDistrictChange}
                      // disabled={!selectedState}
                    >
                      {districtData?.map((district) => (
                        <Select.Option
                          key={district.name}
                          value={district}
                        >
                          {district}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
              <Col flex={6}>

              <Form.Item
                    name="assembly_constitution"
                    label={
                      <p className="label-text">Assembly Constitution (AC)</p>
                    }
                    // rules={[
                    //   {
                    //     required: "true",
                    //     message: "Please select your Assembly Constitution",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder="Select your Assembly"
                      // onChange={handleAssemblyChange}
                      // disabled={!vidhanSabha}
                    >
                      {vidhanSabha?.map(
                        (assembly,index) => (
                          <Select.Option key={index} value={assembly}>
                            {assembly}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
               
              </Col>
              <Col flex={8}>
                  <Form.Item
                    name={"profession"}
                    label={<p className="label-text">Profession</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select the Profession",
                    //   },
                    // ]}
                    // initialValue={event?.}
                  >

                  <Select
                    labelInValue
                    placeholder="Profession"
                    // onChange={(e) => setFrequency(e.label)}
                  >
                    {profession?.map((item,index)=>{
                      return (
                        <>
                    <Select.Option value={item}>{item}</Select.Option>
                        </>
                      )
                    })}
                  </Select>
                  </Form.Item>
                </Col>
                <Col flex={8}>
                  <Form.Item
                    name={"tag_member"}
                    label={<p className="label-text">Tagged Member</p>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select the Profession",
                    //   },
                    // ]}
                    // initialValue={event?.}
                  >

                  <Select
                    labelInValue
                    placeholder="Tagged Member"
                    // onChange={(e) => setFrequency(e.label)}
                  >
                    {tagMember?.map((item,index)=>{
                      return (
                        <>
                    <Select.Option value={item}>{item}</Select.Option>
                        </>
                      )
                    })}
                  </Select>
                  </Form.Item>
                </Col>
          
            </Row>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item>
            <Button htmlType="submit" size="large" className="primary">
              Filter
            </Button>
          </Form.Item>
            </div>
            </Form> */}

        {/* </div>
        </div>
      </div> */}

        <div className="fill">
          <div className="form">
            <div className="form-container voter-Data">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">State</label>

                  <Select
                    placeholder="Please Select State"
                    className="select-box-width"
                    onChange={handleStateChange}
                    allowClear
                    value={selectedState}
                  >
                    {stateData?.data?.map((state) => (
                      <Select.Option key={state._id} value={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">District</label>
                  <Select
                    placeholder="Please Select District"
                    className="select-box-width"
                    onChange={handleDistrictChange}
                  >
                    {districtData?.map((district) => (
                      <Select.Option key={district.name} value={district}>
                        {district}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">Age</label>

                  <Input
                    placeholder="Please Enter Age"
                    onChange={(e) => setAgeData(e.target.value)}
                    // onChange={changeInteger}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">Profession</label>
                  <Select
                    placeholder="Please Select Profession"
                    className="select-box-width"
                    onChange={(e) => setSelectedProfession(e)}
                  >
                    {profession?.map((item, index) => {
                      return (
                        <>
                          <Select.Option value={item}>{item}</Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <label className="label-text">Tagged Member</label>

                  <Select
                    placeholder="Please Select Tagged Member"
                    className="select-box-width"
                    onChange={(e) => setSelectedTagMember(e)}
                  >
                    {tagMember?.map((item, index) => {
                      return (
                        <>
                          <Select.Option value={item}>{item}</Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <div className="filter-button-div">
                <Button className="filter-button-data" onClick={filterFunc}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="details">
          <table className="custom-table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Name</th>
                <th>Mobile</th>
                <th>Age</th>
                <th>Gender</th>
                <th>State</th>
                <th>District</th>
                {/* <th style={{ width: "8%" }}>Location</th>
               <th style={{ width: "8%" }}>Actions</th> */}
                <th>Assembly Constitiency</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {feedbackData.map((item, index) => (
                <tr key={index}>
                  <td>{item.feedback_by.name}</td>
                  <td>{item.feedback_by.mobile}</td>
                  <td>{item.feedback_by.age}</td>
                  <td>{item.feedback_by.gender}</td>
                  <td>{item.feedback_by.state}</td>
                  <td>{item.feedback_by.district}</td>
                  <td>{item.feedback_by.assembly_constitution}</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Button
                        style={{ marginRight: "0.6rem" }}
                        onClick={() => openViewMessage(item)}
                      >
                        <FaEye />
                        View Message
                      </Button>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        placement="topLeft"
                        onConfirm={() => handleDeleteConfrim(item)}
                      >
                        <Button
                          shape="circle"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        ></Button>
                      </Popconfirm>
                    </div>
                    {/* <Button onClick={replyMessaegFunc}><MdOutlineReply/>Reply</Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-style">
            <Pagination
              className="pagination-core"
              current={page}
              total={totalPage}
              showSizeChanger={false}
              onChange={(pageNumber, pageSize) =>
                onChangePagination(pageNumber, pageSize)
              }
            />
          </div>
        </div>
      </div>

      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>
      <div>.</div>

      <Modal
        width="800px"
        title="Feedback Data"
        centered
        open={viewMessage}
        onOk={() => setViewMessage(false)}
        onCancel={() => setViewMessage(false)}
      >
        {feedback && feedback.feedback_by && (
          <Row>
            <Col span={13}>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Message </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.message}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Feedback Id </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_id}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Created By </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.created_by}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Type </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.type}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Is Visible </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.is_visible ? "true" : "false"}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Created At </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.created_at}</b>
                  </span>
                </Col>
              </Row>
            </Col>
            {/* new column */}
            <Col span={11}>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Name </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.name}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Mobile </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.mobile}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Pincode </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.pincode}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>State </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.state}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>District </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.district}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>User Id </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.user_id}</b>
                  </span>
                </Col>
              </Row>
              <Row className="feedbackMessage">
                <Col span={7}>
                  <span>Assembly Constitution </span>: &nbsp;{" "}
                </Col>
                <Col span={14}>
                  <span>
                    <b> {feedback.feedback_by.assembly_constitution}</b>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default WorkersMsgComponent;
