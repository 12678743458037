import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { LoginForm } from "./login-components/LoginForm";
import Leadership from "./components/Leadership";
import { Event } from "./components/Event";
import AddImages from "./components/AddPost";
import News from "./components/News";
import { JoinMember } from "./components/JoinMember";
import VoterDataComponent from "./components/VoterDataComponent";
import JourneyComponent from "./components/JourneyComponent";
import WorkersMsgComponent from "./components/WorkersMsgComponent";
import PushNotificationComp from "./components/PushNotificationComp";
import GetAccessComponent from "./components/GetAccessComponent";
import Gallery from "./components/Gallery";
import ContactUs from "./components/ContactUsData";
import PressNote from "./components/PressNote";
import VolunteerData from "./components/VolunteerData";
import Tweets from "./components/Twitter";
import FacebookLogin from "./components/Facebook";
import { Live } from "./components/Live";
import Report from "./components/Report";
import "./app.css";
import { GetTaskViewComponent } from "./components/GetAccessTable";

const App = () => {
  return (
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Dashboard />}>
          <Route path=""  element={<Leadership />} />
          <Route path="event" element={<Event />} />
          <Route path="highlights" element={<AddImages />} />
          <Route path="news" element={<News />} />
          <Route path="joinmemeber" element={<JoinMember />} />
          <Route path="userlist" element={<GetAccessComponent task={true} />} />
          <Route path="userlist/:id" element={<GetTaskViewComponent />} />
          <Route path="pressnote" element={<PressNote />} />
          <Route path="voterdata" element={<VoterDataComponent />} />
          <Route path="ourjounery" element={<JourneyComponent />} />
          <Route path="workersmessage" element={<WorkersMsgComponent />} />
          <Route path="broadcastmessage" element={<PushNotificationComp />} />
          <Route path="authorization" element={<GetAccessComponent />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact_us" element={<ContactUs />} />
          <Route path="report" element={<Report />} />
          <Route path="volunteerdata" element={<VolunteerData />} />
          <Route path="twitter" element={<Tweets />} />
          <Route path="facebook" element={<FacebookLogin />} />
          <Route path="live" element={<Live />} />
        </Route>
      </Routes>
   
  );
};

export default App;
