import React, { useEffect, useState } from "react";
import { Button, ConfigProvider, Modal, Tooltip } from "antd";
import logo from "../images/Flag-of-Bahujan-Samaj-Party.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

function Greeting() {
  const [greeting, setGreeting] = useState("");
  const currentTime = new Date().getHours();
  useEffect(() => {
    if (currentTime >= 6 && currentTime < 12) {
      setGreeting("Good Morning");
    } else if (currentTime >= 12 && currentTime < 15) {
      setGreeting("Good Afternoon");
    } else if (currentTime >= 15 && currentTime < 21) {
      setGreeting("Good Evening");
    } else {
      setGreeting("Good Night");
    }
  }, [currentTime]);
  return (
    <>
      {/* <span>Hello!!</span> {greeting} */}
      <span>"सर्वजन हिताय - सर्वजन सुखाय"</span>
    </>
  );
}

export const Navbar = ({ isLoggedIn, handleClick }) => {
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const location = useLocation();
  const handleLogout = () => {
    setIsLogoutModalVisible(true);
  };

  const handleCancelLogout = () => {
    setIsLogoutModalVisible(false);
  };

  const handleConfirmLogout = () => {
    handleClick();
    setIsLogoutModalVisible(false);
  };

  return (
    <div className="navbar">
      {/* <a href="/"> */}
      <div className="top-logo">
        {/* <div className="content">
          <h1 data-text="BSP">BSP</h1>
        </div> */}
        <img src={logo} alt="logo" />
      </div>
      {/* </a> */}
      <div className="nav ">
        <p className="dashboard-title">"सर्वजन हिताय - सर्वजन सुखाय"</p>

        {location?.pathname !== "/" && (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#fff",
                borderRadius: "12px",
              },
            }}
          >
            <Tooltip title={"Logout"}>
              <Button
                icon={
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ color: "#22409a" }}
                    fontSize={"18px"}
                  />
                }
                type="primary"
                size="large"
                style={{
                  marginRight: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleLogout}
              ></Button>
            </Tooltip>
          </ConfigProvider>
        )}
      </div>

      {/* {isLoggedIn ? (
        <div className="nav">
          <p className="dashboard-title">"सर्वजन हिताय - सर्वजन सुखाय"</p>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#fff",
                borderRadius: "12px",
              },
            }}
          >
            <Tooltip title={"Logout"}>
              <Button
                icon={
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ color: "#22409a" }}
                    fontSize={"18px"}
                  />
                }
                type="primary"
                size="large"
                style={{
                  marginRight: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleLogout}
              ></Button>
            </Tooltip>
          </ConfigProvider>
        </div>
      ) : (
        <div className="navtitle">
          <h2 className="dashboard-title">
            <Greeting />
          </h2>
        </div>
        // <div className="navtitle dashboard-title">
        //   <img src={Image3}/>
        // </div>
      )} */}
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
            colorPrimary: "#1d3e9b",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <span className="delete-span">
                Are you sure you want to Logout?
              </span>
            </p>
          }
          open={isLogoutModalVisible}
          onOk={handleConfirmLogout}
          onCancel={handleCancelLogout}
          okButtonProps={{
            type: "default",
            size: "large",
          }}
          cancelButtonProps={{
            size: "large",
          }}
          okText={
            <p className="p-icon">
              <span>Yes, Logout!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Stay Back.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
    </div>
  );
};
