import { Button, Form, Input, Modal, Select, notification } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";


const DialogDemo = ({ open, setOpen, data }) => {
  const [form] = Form.useForm();
  const [dropData, setDropData] = useState(null);
  const [status, setStatus] = useState([]);
  const [position, setPosition] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDrop = () => {
    axios
      .get("/bsp/drop-downs")
      .then((response) => {
        setDropData(response?.data?.data);
        setStatus(
          response?.data?.data.find((item) => item.field === "POSITION STATUS")
        );
        setPosition(
          response?.data?.data.find(
            (item) => item.field === "POSITION APPLIED FOR"
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDrop();
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await axios.put(
        "/bsp/user/activate_user",
        JSON.stringify({
          user_id: data?.user_id,
          ...values,
        })
      );

      console.log("datagsdvyvduudv", res?.data);
      if (res?.data?.success) {
        setLoading(false);
        setOpen(false);
        notification.success({
          message: res?.data?.message,
          placement: "bottomRight",
        });
        form.resetFields();
      } else {
        notification.error({
          message: res?.data?.message,
          placement: "bottomRight",
        });
        setLoading(false);
        form.resetFields();
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal centered open={open} onCancel={() => setOpen(false)} footer={null}>
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          status: data?.status,
          position_applied: data?.position_name,
          remarks: data?.remarks,
        }}
        layout="vertical"
      >
        
        <Form.Item label="Status" name="status">
          <Select
            placeholder="Select status"
            options={
              status &&
              status?.value?.map((item) => ({
                label: item,
                value: item,
              }))
            }
          />
        </Form.Item>
        <Form.Item label="Position" name="position_applied">
          <Select
            defaultValue={data?.position_name}
            disabled={form.getFieldValue("status") !== "Modify"}
            options={
              position &&
              position?.value?.map((item) => ({
                label: item,
                value: item,
              }))
            }
            placeholder="Select position"
          />
        </Form.Item>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={4} placeholder="Enter remarks" />
        </Form.Item>

        <Form.Item>
          <Button
            // disabled={form.getFieldValue("status") !== "Modify"}
            type="primary"
            htmlType="submit"
          >
            Apply
          </Button>{" "}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DialogDemo;
