import { Button, ConfigProvider, Modal, Pagination, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faTrash,
    faTriangleExclamation,
  } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";

const PushListingNotification=({listingData,getAllNotificationFunc,showForm,totalPage,page,setPage})=>{
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteNotification,setDeleteNotification]=useState();
  // const [page,setPage]=useState(1)


  console.group(totalPage);

  const showDeleteModal = (index) => {
    // setDeleteIndex(index._id);
    console.log(index);
    setDeleteNotification(index?._id);
    setDeleteModalVisible(true);
  };

  const handleDelete=()=>{
    axios
    .delete(`/bsp/admin/delete-notification/${deleteNotification}`)
    .then((response) => {
    //   console.log(response.data);
      if (response?.data?.success) {
        setDeleteModalVisible(false);
        getAllNotificationFunc();
        toast.success(response?.data?.message, {
          className: "toast",
          iconTheme: {
            primary: "#005bea",
            secondary: "#f0f7ff",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const showDrawer=(value)=>{
    showForm(value);
  }

  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

    console.log(listingData);
    return (
        <>
         <div className="details">
      <table className="custom-table">
        <thead>
          <tr>
          <th style={{ width: "5%" }}>Image</th>
            <th style={{ width: "5%" }}>Title</th>
            <th>State</th>
            <th>District</th>
            <th>Message</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Action</th>
            {/* <th>Mode of Event</th>
            <th style={{ width: "8%" }}>Location</th>
            <th style={{ width: "8%" }}>Actions</th> */}
          </tr>
        </thead>
        <tbody>
            {listingData?.map((value,index)=>{
                return (
                    <>
                    <tr key={index}>
                    <img style={{ width: "15rem" }} src={value?.image_url} />
                        {/* <td><img src={value?.image_url}/></td> */}
                        <td>{value?.title}</td>
                        <td>{value?.state}</td>
                        <td>{value?.district}</td>
                        <td>{value?.message}</td>
                        <td>{value?.created_by}</td>
                        {/* <td>{value?.created_at}</td> */}
                    <td>{moment(value?.created_at).format('DD-MM-YYYY')}</td>
                        <td>
                        <div className="actions">
                        {/* <Tooltip title="Update">
                          <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faPen} />}
                            onClick={() => showDrawer(value)}
                          />
                        </Tooltip> */}
                        <Tooltip title="Delete">
                          <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={() => showDeleteModal(value)}
                          />
                        </Tooltip>
                      </div>
                        </td>
                    </tr>
                    </>
                )
            })}
          {/* {events?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.event_name}</td>
                <td>{item.event_speakers}</td>
                <td>{item.guest_list}</td>
                <td>
                  {moment(item.start_date).format("MMM Do")} -{" "}
                  {moment(item.end_date).format("MMM Do, YYYY")}
                </td>
                <td>
                  {moment(item.start_time, "HH:mm").format("hh:mm A")} -{" "}
                  {moment(item.end_time, "HH:mm").format("hh:mm A")}
                </td>
                <td>{item.event_type}</td>
                <td>{item.location}</td>
                <td>
                  <div className="actions">
                    <Tooltip title="Update">
                      <Button
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => showDeleteModal(item)}
                      />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })} */}
        </tbody>
      </table>
      </div>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: "10px",
          },
        }}
      >
        <Modal
          width={400}
          className="delete-modal"
          title={
            <p className="p-icon">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="delete-icon"
              />
              <span className="delete-span">
                Are you sure you want to delete this file?
              </span>
            </p>
          }
          okButtonProps={{
            type: "default",
          }}
          open={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={handleDelete}
          okText={
            <p className="p-icon">
              <span>Yes, Delete!</span>
            </p>
          }
          cancelText={
            <p className="p-icon">
              <span>No, Keep it.</span>
            </p>
          }
          destroyOnClose
        ></Modal>
      </ConfigProvider>
      <div className="pagination-style">
        <Pagination
          className="pagination-core"
          current={page}
          showQuickJumper
          // defaultCurrent={1}
          // total={totalPage}
          total={totalPage}
          showSizeChanger={false}
          onChange={(pageNumber, pageSize) =>
            onChangePagination(pageNumber, pageSize)
          }
        />
      </div>
        </>
    );
}

export default PushListingNotification;