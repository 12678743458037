import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Row,
  Col,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";

const { TextArea } = Input;
const { Option } = Select;

function ModalCreateTask({ open, setOpen, edit, id, valid }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [loadingS, setLoadingS] = useState(false);
  const [districts, setDistrictListing] = useState([]);
  const [selectedState, setSelectedState] = useState(edit?.state);
  const [selectedDistrict, setSelectedDistrict] = useState(edit?.district);
  const [vidhanSabha, setVidhanSabha] = useState([]);

  useEffect(() => {
    const getStatesFunction = async () => {
      try {
        setLoadingS(true);
        const response = await axios.get("/bsp/states");
        console.log("bubububub", response.data);
        if (response.data.success) {
          setStates(response.data?.data);
          setLoadingS(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    getStatesFunction();
  }, []);

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...edit,
        start_date: edit.start_date ? moment(edit.start_date) : null,
        end_date: edit.end_date ? moment(edit.end_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [edit, form]);

  const onFinish = async (values) => {
    setLoading(true);
    const url = edit ? "/bsp/admin/update-task" : "/bsp/admin/create-task";

    const method = edit ? "put" : "post";
    const formData = new FormData();
    formData.append("user_id", id);
    if (edit) {
      formData.append("task_id", edit.task_id);
    }
    Object.entries(values).forEach(([key, value]) => {
      if (key === "start_date" || key === "end_date") {
        formData.append(key, value.format("YYYY-MM-DD"));
      } else {
        formData.append(key, value);
      }
    });

    await axios({
      method: method,
      url: url,
      data: formData,
    })
      .then((response) => {
        message.success(response?.data?.message);
        valid()
        setOpen(false); // Close the modal after successful submission
        setLoading(false);
        form.resetFields();
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error(error.message);
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please check the form and try again.");
    console.log("Failed:", errorInfo);
  };

  const handleStateChange = async (e) => {
    console.log("state changed", e);
    setSelectedState(e);
    await axios
      .get(`/bsp/state_districts?state=${e}`)
      .then((response) => {
        console.log(response?.data?.data);
        setDistrictListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleStateChange(selectedState);
    handleDistrictChange(selectedDistrict);
  }, [selectedState, selectedDistrict]);

  const handleDistrictChange = (p) => {
    setSelectedDistrict(p);
    // /bsp/district_constitutions?district=Mathura
    axios
      .get(`/bsp/district_constitutions?state=${selectedState}&district=${p}`)
      .then((response) => {
        console.log(response?.data?.data);
        setVidhanSabha(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setSelectedDistrict(district);
  };
  return (
    <Modal
      title={
        <h3 style={{ textAlign: "center" }}>
          {edit ? "Update Task" : "Create New Task"}
        </h3>
      }
      footer={null}
      centered
      open={open}
      onCancel={() => setOpen(false)}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: edit?.name,
          description: edit?.description,
          start_date: edit?.start_date ? moment(edit.start_date) : null,
          end_date: edit?.end_date ? moment(edit.end_date) : null,
          state: edit?.state,
          district: edit?.district,
          vidhansabha: edit?.vidhansabha,
          highPriority: edit?.highPriority,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                { required: true, message: "Please select a start date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Date"
              name="end_date"
              rules={[
                { required: true, message: "Please select an end date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Task Name"
              name="name"
              rules={[
                { required: true, message: "Please enter the task name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Count"
              name="count"
              rules={[{ required: true, message: "Please enter the count!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Task Detail" name="description">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "Please select a state!" }]}
            >
              <Select
                onChange={(value) => setSelectedState(value)}
                loading={loadingS}
                placeholder="Select a state"
              >
                {states.length > 0 &&
                  states.map((item) => <Option value={item}>{item}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="District"
              name="district"
              rules={[{ required: true, message: "Please select a district!" }]}
            >
              <Select
                onChange={(value) => setSelectedDistrict(value)}
                disabled={districts?.length === 0 ? true : false}
                placeholder="Select a district"
              >
                {districts?.length > 0 &&
                  districts.map((item) => <Option value={item}>{item}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="bottom">
          <Col span={12}>
            <Form.Item
              label="Vidhansabha"
              name="vidhansabha"
              rules={[{ required: true, message: "Please Select Vidhansabha" }]}
            >
              <Select
                disabled={vidhanSabha?.length === 0 ? true : false}
                placeholder="Select a vidhansabha"
              >
                {vidhanSabha?.length > 0 &&
                  vidhanSabha.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{ display: "flex" }}
              name="is_high_priority"
              valuePropName="checked"
            >
              <Checkbox>High Priority</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ display: "flex", justifyContent: "end" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalCreateTask;
