import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Modal,
  Tooltip,
  Col,
  Row,
  Form,
  Input,
  Pagination,
  Select,
} from "antd";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEye,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// import {
//     Col,
//     ConfigProvider,
//     DatePicker,
//     Form,
//     Input,
//     Row,
//     Select,
//     TimePicker,
//     Upload,
//     message,
//   } from "antd";

const VoterDataListing = () => {
  const [showEvent, setShowEvent] = useState("DeleteData");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [showName, setShowName] = useState();
  const [stateData, setStateData] = useState();
  const [selectedState, setSelectedState] = useState();
  const [districtData, setDistrictData] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [vidhanSabha, setVidhanSabha] = useState();
  const [selectedVidhansabha, setSelectedVidhansabha] = useState();
  const [epicNo, setEpicNo] = useState();
  const [voterData, setVoterData] = useState(null);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  console.log(totalPage);

  const onChangePagination = (pageNumber, pageSize) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

  // console.log(selectedState);
  // console.log(selectedDistrict);
  // console.log(selectedVidhansabha);
  console.log(voterData);

  // const getVoterListFunc = () => {
  //   axios
  //     .get(
  //       `/voter/admin/get-voters-list?page_num=1&page_size=10&state=Uttar Pradesh&district=Agra`
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       if (response?.data?.success) {
  //         setVoterData(response?.data);
  //         setTotalPage(response?.data?.data?.page_count * 10);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // console.log(page);



  const showDeleteModal = (item) => {
    console.log(item);
    setShowName(item?.voter_name_eng);
  };

  // /voter/admin/hierarchy?type=assembly_constituency&state=Uttar Pradesh&district=Agra

  const getStatesFunc = () => {
    axios
      .get("/voter/admin/hierarchy?type=state")
      .then((response) => {
        setStateData(response?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStatesFunc();
  }, []);

  const handleStateChange = (state) => {
    setSelectedState(state);

    console.log(state);
    axios
      .get(`/voter/admin/hierarchy?type=district&state=${state}`)
      // .get(`/bsp/state_districts?state=${state}`)
      .then((response) => {
        setDistrictData(response?.data?.data);
        console.log(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // /bsp/state_districts
  };

  const handleDistrictChange = (district) => {
    // /bsp/district_constitutions?district=Mathura
    setSelectedDistrict(district);
    axios
      .get(`/voter/admin/hierarchy?type=assembly_constituency&state=${selectedState}&district=${district}`)
      .then((response) => {
        console.log(response?.data?.data);
        setVidhanSabha(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterFunc = () => {
    console.log(page);
    console.log(selectedState);
    console.log("datttttttattattatta");
    axios
      .get(
        "/voter/admin/get-voters-list",{
          params:{
            page_num:page,
            page_size:10,
            state:selectedState,
            district:selectedDistrict,
            vidhan_sabha:selectedVidhansabha,
            epic_no:epicNo
          } 
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.success) {
          setVoterData(response?.data);
          setTotalPage(response?.data?.data?.page_count * 10);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    filterFunc();
  }, [page]);

  return (
    <>
      {/* <div className="fill">
        <div className="form">
          <div className="form-container voter-Data">
            <Row gutter={[16]}>
              <Col flex={6}>
                <Form.Item
                  name={"end_date"}
                  label={<p className="label-text">Select State</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end date",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Select State"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
              <Col flex={6}>
                <Form.Item
                  name={"end_time"}
                  label={<p className="label-text">Select District</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end time",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Select District"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
              <Col flex={6}>
                <Form.Item
                  name={"end_time"}
                  label={<p className="label-text">Enter Loksabha</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end time",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter Loksabha"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
              <Col flex={6}>
                <Form.Item
                  name={"end_time"}
                  label={<p className="label-text">Enter Vidhansabha</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end time",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter Vidhansabha"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
              <Col flex={6}>
                <Form.Item
                  name={"end_time"}
                  label={<p className="label-text">Enter Block</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end time",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter Block"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
              <Col flex={6}>
                <Form.Item
                  name={"end_time"}
                  label={<p className="label-text">Enter GramPanchayat</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please set the end time",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter GramPanchayat"
                    // onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <Button size="large" className="primary">
                Filter
              </Button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="fill">
        <div className="form">
          <div className="form-container voter-Data">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">State</label>

                <Select
                  placeholder="Please Select State"
                  className="select-box-width"
                  onChange={handleStateChange}
                >
                  {stateData?.data?.map((state) => (
                    <Select.Option key={state._id} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">District</label>
                <Select
                  placeholder="Please Select District"
                  className="select-box-width"
                  onChange={handleDistrictChange}
                >
                  {districtData?.map((district) => (
                    <Select.Option key={district.name} value={district}>
                      {district}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Vidhansabha</label>

                <Select
                  placeholder="Please Select Vidhansabha"
                  className="select-box-width"
                  onChange={(e) => setSelectedVidhansabha(e)}
                >
                  {vidhanSabha?.map((assembly, index) => (
                    <Select.Option key={index} value={assembly}>
                      {assembly}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">EPIC NO</label>
                <Input
                  placeholder="Please Enter EPIC No"
                  onChange={(e) => setEpicNo(e.target.value)}
                />
              </Col>
              {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <label className="label-text">Tagged Member</label>

                <Select
                  placeholder="Please Select Tagged Member"
                  className="select-box-width"
                >
               
                      </Select>
              </Col> */}
            </Row>
            <div className="filter-button-div">
              <Button className="filter-button-data" onClick={filterFunc}>
                Filter
              </Button>
            </div>
          </div>
        </div>
      </div>

      {voterData == null ? 
    <div className="voter_null_data">Please Select State and District to get the data</div>
    :
    <div className="details">
    <table className="custom-table">
      <thead>
        <tr>
          {/* <th style={{ width: "5%" }}>Name</th> */}
          <th>Name</th>
          <th>Age</th>
          <th>Gender</th>
          <th>EPIC NO</th>
          <th>Village/Town</th>
          <th>Ward</th>
          <th>Post Office</th>
          <th>Police Station</th>
          <th>Assembly Constituency</th>
          <th>Parliamentry Constituency</th>
          <th>District</th>
          <th>PinCode</th>
          <th>Relative Name</th>
          <th>Relation Type</th>
        </tr>
      </thead>

      <tbody>
        {voterData?.data?.data?.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item?.name}</td>
              <td>{item?.age}</td>
              <td>{item?.gender}</td>
              <td>{item?.epic_no}</td>
              <td>{item?.village}</td>
              <td>{item?.ward}</td>
              <td>{item?.post_office}</td>
              <td>{item?.police_station}</td>
              <td>{item?.assembly_constituency}</td>
              <td>{item?.parliamentary_constituency}</td>
              <td>{item?.district}</td>
              <td>{item?.pincode}</td>
              <td>{item?.relative_name}</td>
              <td>{item?.relation_type}</td>

              {/* <td>
                <div className="actions">
                  <Tooltip title="View">
                    <Button
                      shape="circle"
                      // icon={ <FontAwesomeIcon icon="fa-solid fa-eye" />}
                      icon={<FontAwesomeIcon icon={faEye} />}
                      // onClick={() => onEdit(item)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => showDeleteModal(item)}
                    />
                  </Tooltip>
                </div>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>

    <ConfigProvider
      theme={{
        token: {
          borderRadius: "10px",
        },
      }}
    >
      <Modal
        width={400}
        className="delete-modal"
        title={
          <p className="p-icon">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="delete-icon"
            />
            <span className="delete-span">
              You're going to delete the "{showName}" event. Are your sure?
            </span>
          </p>
        }
        open={isDeleteModalVisible}
        onCancel={handleDeleteCancel}
        //   onOk={handleDeleteConfirm}
        okButtonProps={{
          type: "default",
        }}
        okText={
          <p className="p-icon">
            <span>Yes, Delete!</span>
          </p>
        }
        cancelText={
          <p className="p-icon">
            <span>No, Keep it.</span>
          </p>
        }
        destroyOnClose
      ></Modal>
    </ConfigProvider>
  </div>
    }

    
{voterData !== null && 
 <div className="pagination-style">
 <Pagination
   className="pagination-core"
   current={page}
   showQuickJumper
   defaultCurrent={1}
   total={totalPage}
   showSizeChanger={false}
   onChange={(pageNumber, pageSize) =>
     onChangePagination(pageNumber, pageSize)
   }
 />
</div>
}
     

      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
      <div className="footer-static">.</div>
    </>
  );
};

export default VoterDataListing;
