import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import dayjs from "dayjs";
import { TbUpload } from "react-icons/tb";

function FormComponent({ event, onClose, getEvents }) {
  const [form] = Form.useForm();
  const [eventType, setEventType] = useState("");
  const [eventLoc, setEventLoc] = useState("");
  const [guest, setGuest] = useState("");
  const [coHost, setCoHost] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [name, setName] = useState("");
  const [speakers, setSpeakers] = useState("");
  const [agenda, setAgenda] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState(null);
  const [description, setDescription] = useState("");
  const [uploadedMultiImage, setUploadMultiImage] = useState(null);
  const [multiImageUploaded, setMultiImageUploaded] = useState([]);
  const [imageSize, setImageSize] = useState();

  // const [image, setImage] = useState("");

  console.log(multiImageUploaded?.length);
  console.log("event checking", event);

  const onFinish = (value) => {
    console.log(value);
    console.log(moment(value["end_time"].$d).format("HH:mm"));
    if (event) {
      let formData = new FormData();
      formData.append("_id", event?._id);
      formData.append("event_type", value["event_type"].label);
      formData.append("location", value?.["location"]);
      formData.append("guest_list", value["guest_list"]);
      formData.append("co_host", value["co_host"]);
      formData.append("organizer_name", value["event_organizer"]);
      formData.append("event_name", value["event_name"]);
      formData.append("event_speakers", value["speakers"]);
      formData.append("agenda", value["agenda"]);
      formData.append(
        "start_date",
        moment(value["start_date"].$d).format("YYYY-MM-DD")
      );
      formData.append(
        "start_time",
        moment(value["start_time"].$d).format("HH:mm")
      );
      formData.append(
        "end_date",
        moment(value["end_date"].$d).format("YYYY-MM-DD")
      );
      formData.append("end_time", moment(value["end_time"].$d).format("HH:mm"));
      formData.append("description", value["event_description"]);
      // {multiImageUploaded?.length !== 0 && formData.append("image",multiImageUploaded)};
      formData.append("link", value["event_link"]);
      // formData.append("image",multiImageUploaded);
      {
        multiImageUploaded?.length !== 0 &&
          value?.file.forEach((data) => {
            formData.append("image", data?.originFileObj);
          });
      }

      axios
        .put("/bsp/admin/update-event", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response?.data?.success) {
            getEvents();
            toast.success(response?.data?.message, {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            onClose();
          } else {
            toast.error(response?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let formData = new FormData();
      formData.append("event_type", value["event_type"].label);
      formData.append("location", value?.["location"]);
      formData.append("guest_list", value["guest_list"]);
      formData.append("co_host", value["co_hosts"]);
      formData.append("organizer_name", value["event_organizer"]);
      formData.append("event_name", value["event_name"]);
      formData.append("event_speakers", value["speakers"]);
      formData.append("agenda", value["agenda"]);
      formData.append(
        "start_date",
        moment(value["start_date"].$d).format("YYYY-MM-DD")
      );
      formData.append(
        "start_time",
        moment(value["start_time"].$d).format("HH:mm")
      );
      formData.append(
        "end_date",
        moment(value["end_date"].$d).format("YYYY-MM-DD")
      );
      formData.append("end_time", moment(value["end_time"].$d).format("HH:mm"));
      formData.append("description", value["event_description"]);
      formData.append("link", value["event_link"]);
      value?.file.forEach((data) => {
        formData.append("image", data?.originFileObj);
      });
      // formData.append("image",multiImageUploaded);

      axios
        .post("/bsp/admin/create-event", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data'
          },
        })
        .then((resp) => {
          // console.log(resp.data);
          if (resp?.data?.success) {
            getEvents();
            toast.success("Event Created Successfully", {
              className: "toast",
              iconTheme: {
                primary: "#005bea",
                secondary: "#f0f7ff",
              },
            });
            form.resetFields();
            onClose();
          } else {
            toast.error(resp?.data?.message, {
              className: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    }
    // const values = {
    //   ...value,
    //   start_date: value["start_date"].format("YYYY-MM-DD"),
    //   end_date: value["end_date"].format("YYYY-MM-DD"),
    //   start_time: value["start_time"].format("hh:mm a"),
    //   end_time: value["end_time"].format("hh:mm a"),
    //   event_type: value["event_type"].label,
    //   file: value["file"][0].name,
    // };
    // console.log(values);
  };

  // const onChange = (info) => {
  //   if (info?.file?.status === "done") {
  //     // console.log(info.file);
  //     message.success(`${info?.file?.name} file uploaded successfully`);
  //     // setImage(URL.createObjectURL(info.file.originFileObj));
  //   } else if (info.file.status === "error") {
  //     message.error(`${info?.file?.name} file upload failed.`);
  //   }
  // };

  // const customRequest = async ({ file, onSuccess, onError }) => {
  //   setTimeout(() => {
  //     onSuccess(200);
  //   }, 1000);
  // };

  const handleStartTimeChange = (time) => {
    console.log(time);
    setStartTime(time);
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  console.log(event);

  const uploadImage = useRef(null);
  const handleUpload = (e) => {
    console.log("mnfftg");
    uploadImage.current.click();
  };

  // const handleImageUploadChange = async (e) => {
  //   console.log("jhfvbrv");
  //   const maxAllowedSize = 2 * 1024 * 1024;

  //   if (e.target.files[0].size < maxAllowedSize) {
  //     const uploadedImage = e.target?.files[0];
  //     // const qual = 0.8;
  //     console.log(uploadedImage);
  //     setUploadMultiImage(uploadedImage);

  //         if (uploadedImage?.type.includes("image")) {
  //         }

  //   } else {
  //     // errorNotify("File size is more than 1 mb !");
  //   }
  // };

  const customRequest = async ({ file, onSuccess, onError }) => {
    // Implement your custom upload logic here
    // You can use Axios, Fetch, or any other library to send the file to the server

    setTimeout(() => {
      onSuccess("ok");
    }, 1000);
    console.log(file);
    setMultiImageUploaded(file);
    // multiImageUploaded.push(file);
  };

  const onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      // setUploadedImageFile(info.file.originFileObj);
      // setUploadedImageUrl(URL.createObjectURL(info.file.originFileObj));
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    console.log(info.fileList);
  };

  console.log(uploadedMultiImage);
  console.log(typeof multiImageUploaded);
  // moment(dateString).format("DD-MM-YYYY")

  useEffect(() => {
    axios
      .get("/bsp/image_config?type=event")
      .then((response) => {
        if (response?.data) {
          // setLeadersDropdown(response?.data);
          setImageSize(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="fill">
      <div className="form">
        <div className="form-container">
          {/* <input
          type="file"
          style={{ display: "none" }}
          ref={uploadImage}
          onChange={(e) => handleImageUploadChange(e)}
          accept="image/png, image/jpeg, image/jpg"
        /> */}
          {/* initialValue={event?.co_host === "Undefined" ? event?.co_host : null} */}

          {event ? (
            <Form
              className="formSubmit"
              name="submit"
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                event_type: {
                  value: event?.event_type,
                  label: event?.event_type,
                },
                start_date: event?.start_date
                  ? dayjs(`${event?.start_date}`)
                  : undefined,
                end_date: event?.end_date
                  ? dayjs(`${event?.end_date}`)
                  : undefined,
                // start_date:moment(event?.start_date, 'YYYY-MM-DD'),
                // end_date:moment(event?.end_date, 'YYYY-MM-DD'),
                start_time: dayjs(event?.start_time, "HH:mm"),
                end_time: dayjs(event?.end_time, "HH:mm"),
                co_host: event?.co_host === "Undefined" ? event?.co_host : null,
              }}
            >
              <Row justify={"space-between"}>
                <Form.Item
                  name="event_type"
                  label={<p className="label-text">Event Type</p>}
                  rules={[
                    {
                      required: true,
                      message: "Event Type is required",
                    },
                  ]}
                  style={{ width: "47.5%" }}
                >
                  <Select
                    labelInValue
                    placeholder="Event Type"
                    onChange={(e) => setEventType(e.label)}
                  >
                    <Select.Option value="online">ONLINE</Select.Option>
                    <Select.Option value="inperson">OFFLINE</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name={"location"}
                  label={<p className="label-text">Event Location</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter event location",
                    },
                  ]}
                  initialValue={event?.location}
                >
                  <Input
                    // disabled
                    placeholder="Location of the Event"
                    onChange={(e) => setEventLoc(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>

                <Form.Item
                  name={"guest_list"}
                  label={<p className="label-text">Chief Guest</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name of the guest",
                    },
                  ]}
                  initialValue={event?.guest_list}
                >
                  <Input
                    maxLength={20}
                    placeholder="Name of the Guest"
                    onChange={(e) => setGuest(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>

                <Form.Item
                  name={"co_hosts"}
                  label={<p className="label-text">Co-Hosts</p>}
                >
                  <Input
                    maxLength={20}
                    placeholder="Name the Co-hosts of the event"
                    onChange={(e) => setCoHost(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>

                <Form.Item
                  name={"event_organizer"}
                  label={<p className="label-text">Organizer Name</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the organizer",
                    },
                  ]}
                  initialValue={event?.organizer_name}
                >
                  <Input
                    maxLength={20}
                    placeholder="Organizer of the Event"
                    onChange={(e) => setOrganizer(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>

                <Form.Item
                  name={"event_name"}
                  label={<p className="label-text">Event Name</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the event",
                    },
                  ]}
                  initialValue={event?.event_name}
                >
                  <Input
                    maxLength={30}
                    placeholder="Name of the Event"
                    onChange={(e) => setName(e.target.value)}
                    style={{ borderRadius: "10px" }}
                    // disabled
                  />
                </Form.Item>
                <Form.Item
                  name={"speakers"}
                  label={<p className="label-text">Event Speakers</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the speakers of the event",
                    },
                  ]}
                  initialValue={event?.event_speakers}
                >
                  <Input
                    placeholder="Name of the Speakers"
                    onChange={(e) => setSpeakers(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
                <Form.Item
                  name={"agenda"}
                  label={<p className="label-text">Agenda</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please specify the agenda for the event",
                    },
                  ]}
                  initialValue={event?.agenda}
                >
                  <Input
                    maxLength={30}
                    placeholder="Agenda for the Event"
                    onChange={(e) => setAgenda(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Row>
              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"start_date"}
                    label={<p className="label-text">Start Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the start date",
                      },
                    ]}
                    // initialValue={event?.}
                  >
                    <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the Start Date for the Event"
                      onChange={(date, dateString) =>
                        setStartDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                      // defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
                      // initialValues={moment('2015-01-01', 'YYYY-MM-DD')}
                      // defaultValue={
                      //   event?.start_date ? dayjs(`${event?.start_date}`) : undefined
                      // }
                      // defaultValue={
                      //   editData?.year ? dayjs(`${editData?.year}`) : undefined
                      // }
                    />
                  </Form.Item>
                </Col>
                <Col flex={9}>
                  <Form.Item
                    name={"start_time"}
                    label={<p className="label-text">Start Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the start time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the Start Time for the Event"
                      onChange={handleStartTimeChange}
                      // defaultValue={dayjs(event?.start_time, 'HH:mm')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"end_date"}
                    label={<p className="label-text">End Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Date for the Event"
                      onChange={(date, dateString) =>
                        setEndDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                      defaultValue={
                        event?.end_date
                          ? dayjs(`${event?.end_date}`)
                          : undefined
                      }
                    />
                  </Form.Item>
                </Col>
                <Col flex={9}>
                  <Form.Item
                    name={"end_time"}
                    label={<p className="label-text">End Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Time for the Event"
                      onChange={handleEndTimeChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  name={"event_link"}
                  label={<p className="label-text">Event Link</p>}
                  // rules={[
                  //   {
                  //     required: false,
                  //     message: "Please add event link",
                  //   },
                  // ]}
                  initialValue={event?.link}
                  style={{ width: "100%" }}
                >
                  <Input
                    // disabled
                    placeholder="Enter event link"
                    // onChange={(e) => setEventLoc(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  name={"event_description"}
                  label={<p className="label-text">Description</p>}
                  rules={[
                    {
                      required: true,
                      message: "Briefly describe your event",
                    },
                  ]}
                  style={{ width: "100%" }}
                  initialValue={event?.description}
                >
                  <TextArea
                    placeholder="Describe your Event briefly"
                    style={{
                      height: "75px",
                      resize: "none",
                      fontFamily: "Poppins, sans-serif",
                      borderRadius: "14px",
                    }}
                    maxLength={100}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
              </Row>

              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Cover Image</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e?.fileList}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        const file = value && value[0];

                        if (file && !acceptedFormats.includes(file.type)) {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, GIF)."
                          );
                        }

                        return Promise.resolve();
                      },
                      message: "Please upload a valid image file",
                    },
                  ]}
                >

                 

            <Button
              color="info"
              size="sm"
              onClick={handleUpload}
              className="mr-0"
            >
              {" "}
              <TbUpload></TbUpload>
            </Button>


                </Form.Item> */}

                <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Cover Inmage</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload a valid file",
                  //   },
                  // ]}
                  style={{ textAlign: "center" }}
                >
                  <Upload
                    name="file"
                    customRequest={customRequest}
                    onChange={onChange}
                    // maxCount={1}
                    multiple
                    listType="picture-card"
                    showUploadList={{
                      showDownloadIcon: false,
                    }}
                    accept="image/*" // Specify accepted file types
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ background: "transparent" }}
                      />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </Upload>
                </Form.Item>

                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: "12px",
                    },
                  }}
                >
                  <Form.Item>
                    <Button size="large" htmlType="submit">
                      Update Event
                    </Button>
                  </Form.Item>
                </ConfigProvider>
              </Row>
            </Form>
          ) : (
            <Form
              className="formSubmit"
              name="submit"
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row justify={"space-between"}>
                <Form.Item
                  name={"event_type"}
                  label={<p className="label-text">Event Type</p>}
                  rules={[
                    {
                      required: true,
                      message: "Event Type is required",
                    },
                  ]}
                  style={{ width: "47.5%" }}
                >
                  <Select
                    labelInValue
                    placeholder="Event Type"
                    onChange={(e) => setEventType(e.label)}
                  >
                    <Select.Option value="online">ONLINE</Select.Option>
                    <Select.Option value="inperson">OFFLINE</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"location"}
                  label={<p className="label-text">Event Location</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter event location",
                    },
                  ]}
                  style={{ width: "47.5%" }}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Location of the Event"
                    onChange={(e) => setEventLoc(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name={"guest_list"}
                  label={<p className="label-text">Chief Guest</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name of the guest",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Name of the Guest"
                    onChange={(e) => setGuest(e.target.value)}
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name={"co_hosts"}
                  label={<p className="label-text">Co-Hosts</p>}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Name the Co-hosts of the event"
                    onChange={(e) => setCoHost(e.target.value)}
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name={"event_organizer"}
                  label={<p className="label-text">Organizer Name</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the organizer",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Organizer of the Event"
                    onChange={(e) => setOrganizer(e.target.value)}
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name={"event_name"}
                  label={<p className="label-text">Event Name</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the event",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Name of the Event"
                    onChange={(e) => setName(e.target.value)}
                    maxLength={30}
                  />
                </Form.Item>
                <Form.Item
                  name={"speakers"}
                  label={<p className="label-text">Event Speakers</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please name the speakers of the event",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Name of the Speakers"
                    onChange={(e) => setSpeakers(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name={"agenda"}
                  label={<p className="label-text">Agenda</p>}
                  rules={[
                    {
                      required: true,
                      message: "Please specify the agenda for the event",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "10px" }}
                    placeholder="Agenda for the Event"
                    onChange={(e) => setAgenda(e.target.value)}
                    maxLength={30}
                  />
                </Form.Item>
              </Row>
              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"start_date"}
                    label={<p className="label-text">Start Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the start date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the Start Date for the Event"
                      onChange={(date, dateString) =>
                        setStartDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col flex={9}>
                  <Form.Item
                    name={"start_time"}
                    label={<p className="label-text">Start Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the start time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the Start Time for the Event"
                      onChange={handleStartTimeChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[32]}>
                <Col flex={8}>
                  <Form.Item
                    name={"end_date"}
                    label={<p className="label-text">End Date</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Date for the Event"
                      onChange={(date, dateString) =>
                        setEndDate(moment(dateString).format("YYYY-MM-DD"))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col flex={9}>
                  <Form.Item
                    name={"end_time"}
                    label={<p className="label-text">End Time</p>}
                    rules={[
                      {
                        required: true,
                        message: "Please set the end time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", borderRadius: "10px" }}
                      placeholder="Set the End Time for the Event"
                      onChange={handleEndTimeChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  name={"event_link"}
                  label={<p className="label-text">Event Link</p>}
                  // rules={[
                  //   {
                  //     required: false,
                  //     message: "Please add event link",
                  //   },
                  // ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    // disabled
                    placeholder="Enter event link"
                    // onChange={(e) => setEventLoc(e.target.value)}
                    style={{ borderRadius: "10px" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  name={"event_description"}
                  label={<p className="label-text">Description</p>}
                  rules={[
                    {
                      required: true,
                      message: "Briefly describe your event",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder="Describe your Event briefly"
                    style={{
                      height: "75px",
                      resize: "none",
                      fontFamily: "Poppins, sans-serif",
                      borderRadius: "14px",
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={100}
                  />
                </Form.Item>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Cover Image</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/gif",
                        ];
                        const file = value && value[0];

                        if (file && !acceptedFormats.includes(file.type)) {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, GIF)."
                          );
                        }

                        return Promise.resolve();
                      },
                      message: "Please upload a valid image file",
                    },
                  ]}
                >

<Button
              color="info"
              size="sm"
              onClick={handleUpload}
              className="mr-0"
            >
              {" "}
              <TbUpload></TbUpload>
            </Button>


                </Form.Item> */}
                <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Cover Image</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    {
                      required: true,
                      message: "Please upload Image",
                    },
                  ]}
                  style={{ textAlign: "center" }}
                >
                  <Upload
                    name="file"
                    customRequest={customRequest}
                    onChange={onChange}
                    multiple
                    // maxCount={1}
                    listType="picture-card"
                    showUploadList={{
                      showDownloadIcon: false,
                    }}
                    accept="image/*" // Specify accepted file types
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ background: "transparent" }}
                      />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: "12px",
                    },
                  }}
                >
                  <Form.Item>
                    <Button size="large" htmlType="submit">
                      Create Event
                    </Button>
                  </Form.Item>
                </ConfigProvider>
              </Row>
            </Form>
          )}
          <div style={{ color: "#1d3f9bbb", "font-weight": "bolder" }}>
            Note-Upload Image height and width should be {imageSize?.height} ×{" "}
            {imageSize?.width} respectively
          </div>
        </div>
        <Toaster />
      </div>
    </div>
  );
}

export default FormComponent;
