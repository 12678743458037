
import {
    Button,
    Col,
    ConfigProvider,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    TimePicker,
    Upload,
    message,
  } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
  

const VoterDataAdd=()=>{
    const [form] = Form.useForm();
    const [uploadedFile,setUploadedFile]=useState();

    const customRequest = async ({ file, onSuccess, onError }) => {
        setTimeout(() => {
          onSuccess(200);
        }, 1000);
      };
    
      const uploadDataFunc=()=>{

      }

console.log(uploadedFile);

    return (
        <>
        <div className="fill">
      <div className="form">
        <div className="form-container voter-Data">
            {/* <div>Upload Voter Data Excel File</div> */}
       <div className="download-sample-file">Download Sample file</div>
       <Form
            //   className="formSubmit"
              name="submit"
              form={form}
            //   onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                // event_type: {
                //   value: event.event_type,
                //   label: event.event_type,
                // },
              }}
            >
            <Row
             justify={"space-between"}
              > 
            <Form.Item
                  name="file"
                  label={<p className="label-text">Upload Excel File</p>}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const acceptedFormats = [
                        //   "image/jpeg",
                        //   "image/png",
                        //   "image/gif",
                          ".xls",".xlsx"
                        ];
                        const file = value && value[0];

                        if (file && !acceptedFormats.includes(file.type)) {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, GIF)."
                          );
                        }

                        return Promise.resolve();
                      },
                      message: "Please upload a valid image file",
                    },
                  ]}
                >
                  <Upload
                    name="file"
                    customRequest={customRequest}
                    // onChange={uploadDataFunc}
                     onChange={(e) =>
                        setUploadedFile(e)
                            }
                    maxCount={1}
                    listType="text"
                    showUploadList={{
                      showDownloadIcon: false,
                    }}
                    // accept="image/jpeg,image/png,image/gif,image/ico,image/jpg"
                    accept=".xls,.xlsx"
                  >
                    <ConfigProvider
                      theme={{
                        token: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Button icon={<FontAwesomeIcon icon={faUpload} />}>
                        Select File
                      </Button>
                    </ConfigProvider>
                  </Upload>
                </Form.Item>

                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: "12px",
                    },
                  }}
                >
                  <Form.Item>
                    <Button size="large" htmlType="submit">
                      Upload File
                    </Button>
                  </Form.Item>
                </ConfigProvider>
                </Row>
                </Form>
                {/* </div> */}
            </div>
            </div>
            </div> 
        </>
    );
}

export default VoterDataAdd;