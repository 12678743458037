import React, { useEffect } from "react";
import { Button, ConfigProvider, Drawer, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import GetAccessTable from "./GetAccessTable";
import axios from "axios";

const GetAccessComponent = ({ task }) => {
  return (
    <>
      <div className="event pl-5">
        <div className="head">
          <Typography.Text className="text">{task?"User List":"Authorization"}</Typography.Text>
          <ConfigProvider theme={{ token: { colorPrimary: "#1d3e9b" } }}>
            {/* <Tooltip title="New Event">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined />} 
            //   onClick={() => showForm(null)}
              type="primary"
              shape="circle"
            />
          </Tooltip> */}
          </ConfigProvider>
        </div>
        <div>
          <GetAccessTable task={task} />
        </div>
      </div>
    </>
  );
};

export default GetAccessComponent;
